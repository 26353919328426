import { combineReducers } from "redux";
import IAppState from "../interfaces/App.Interface";
import authReducer from "./auth";
import appReducer from "./app";
import parkingReducer from "./parking";
import { IAuthState } from "../interfaces/Auth.Interface";
import { IParkingState } from "../interfaces/Parking.Interface";

export interface IState {
  auth: IAuthState;
  parking: IParkingState;
  app: IAppState;
  // data:IDataState;
}

const rootReducer = combineReducers<IState>({
  auth: authReducer,
  app: appReducer,
  parking: parkingReducer,
});

export default rootReducer;
