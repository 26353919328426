import { call, put } from "redux-saga/effects";

import errorApiService from "../../../service/error.service";

import baseApiService from "../../../service/base-api.service";
import actions from "../../actions";
import {
  GetEntityGroupsResponse,
  GetParkingGroupsRequest,
  GetParkingRequest,
  GetParkingsFullStatusesRequest,
  GetParkingsFullStatusesResponse,
  GetParkingSpaceStatusRequest,
  GetParkingsRequest,
  GetParkingsResponse,
  GetParkingStatusRequest,
  Parking,
  ParkingSpaceStatus,
  ParkingStatus,
} from "../../dto/parking.dtos";
import { IAction } from "../../utils";

const parkingsCall = (opts: {
  customerId: string;
  filter: string;
}): Promise<GetParkingsResponse> => {
  const { customerId, filter } = opts;
  const request = new GetParkingsRequest();
  request.filter = filter;
  request.customerId = customerId;
  return baseApiService.get(request);
};
const parkingCall = (opts: {
  customerId: string;
  id: string;
}): Promise<Parking> => {
  const { customerId, id } = opts;
  const request = new GetParkingRequest();
  request.id = id;
  request.customerId = customerId;
  return baseApiService.get(request);
};
const parkingSpacesCall = (opts: {
  customerId: string;
  id: string;
}): Promise<ParkingStatus> => {
  const { customerId, id } = opts;
  const request = new GetParkingStatusRequest();
  request.id = id;
  request.customerId = customerId;
  return baseApiService.get(request);
};
const parkingStatusSpaceCall = (opts: {
  customerId: string;
  id: string;
}): Promise<ParkingSpaceStatus> => {
  const { customerId, id } = opts;
  const request = new GetParkingSpaceStatusRequest();
  request.id = id;
  request.customerId = customerId;
  return baseApiService.get(request);
};
const parkingsStatusesCall = (opts: {
  customerId: string;
  groupsId: string;
}): Promise<GetParkingsFullStatusesResponse> => {
  const { customerId, groupsId } = opts;
  const request = new GetParkingsFullStatusesRequest();
  request.entityGroups = [groupsId];
  request.customerId = customerId;
  request.page = 0;
  request.items = 0;
  request.sort = "name";
  request.filter = "";
  return baseApiService.get(request);
};
const parkingGroupsCall = (opts: {
  customerId: string;
}): Promise<GetEntityGroupsResponse> => {
  const { customerId } = opts;
  const request = new GetParkingGroupsRequest();
  request.page = 0;
  request.items = 0;
  request.sort = "name";
  request.filter = "";
  request.customerId = customerId;

  return baseApiService.get(request);
};

export function* getParkingsRequest(
  action: IAction<{ customerId: string; filter: string }>
) {
  try {
    yield put(actions.app.saga.asyncAddLoading("loadingParkings"));
    const {
      payload: { customerId, filter },
    } = action;
    const result: GetParkingsResponse = yield call(parkingsCall, {
      customerId,
      filter,
    });
    yield put(actions.parking.setParkings(result.items));
    yield put(actions.app.saga.asyncRemoveLoading("loadingParkings"));
    // yield call(history.push, 'admin/orders');
  } catch (error) {
    yield put(actions.app.saga.asyncRemoveLoading("loadingParkings"));
    yield put(
      actions.app.saga.asyncInitNotification({
        messages: errorApiService.catchRequestError(error),
        type: "warm",
      })
    );
  }
}
export function* getParkingRequest(
  action: IAction<{ customerId: string; id: string }>
) {
  try {
    yield put(actions.app.saga.asyncAddLoading("loadingParkingDetails"));
    const {
      payload: { customerId, id },
    } = action;
    const result: Parking = yield call(parkingCall, {
      customerId,
      id,
    });
    yield put(actions.parking.setActiveParkingDetails(result));
    yield put(actions.app.saga.asyncRemoveLoading("loadingParkingDetails"));
    // yield call(history.push, 'admin/orders');
  } catch (error) {
    yield put(actions.app.saga.asyncRemoveLoading("loadingParkingDetails"));
    yield put(
      actions.app.saga.asyncInitNotification({
        messages: errorApiService.catchRequestError(error),
        type: "warm",
      })
    );
  }
}
export function* getParkingStatusRequest(
  action: IAction<{ customerId: string; id: string }>
) {
  try {
    yield put(actions.app.saga.asyncAddLoading("loadingParkingSpaces"));
    const {
      payload: { customerId, id },
    } = action;
    const result: ParkingStatus = yield call(parkingSpacesCall, {
      customerId,
      id,
    });
    yield put(actions.parking.setParkingSpaces(result));
    yield put(actions.app.saga.asyncRemoveLoading("loadingParkingSpaces"));
  } catch (error) {
    yield put(actions.app.saga.asyncRemoveLoading("loadingParkingSpaces"));
    yield put(
      actions.app.saga.asyncInitNotification({
        messages: errorApiService.catchRequestError(error),
        type: "warm",
      })
    );
  }
}
export function* getParkingSpaceStatusRequest(
  action: IAction<{
    customerId: string;
    id: string;
    callback?: (item: ParkingSpaceStatus) => void;
  }>
) {
  try {
    yield put(actions.app.saga.asyncAddLoading("loadingParkingStatusSpace"));
    const {
      payload: { customerId, id, callback },
    } = action;
    const result: ParkingSpaceStatus = yield call(parkingStatusSpaceCall, {
      customerId,
      id,
    });
    yield callback?.(result);
    yield put(actions.app.saga.asyncRemoveLoading("loadingParkingStatusSpace"));
  } catch (error) {
    yield put(actions.app.saga.asyncRemoveLoading("loadingParkingStatusSpace"));
    yield put(
      actions.app.saga.asyncInitNotification({
        messages: errorApiService.catchRequestError(error),
        type: "warm",
      })
    );
  }
}
export function* getParkingGroupsRequest(
  action: IAction<{ customerId: string }>
) {
  try {
    yield put(actions.app.saga.asyncAddLoading("loadingParkingGroups"));
    const {
      payload: { customerId },
    } = action;
    const result: GetEntityGroupsResponse = yield call(parkingGroupsCall, {
      customerId,
    });
    yield put(actions.parking.setParkingGroups(result));
    yield put(actions.app.saga.asyncRemoveLoading("loadingParkingGroups"));
  } catch (error) {
    yield put(actions.app.saga.asyncRemoveLoading("loadingParkingGroups"));
    yield put(
      actions.app.saga.asyncInitNotification({
        messages: errorApiService.catchRequestError(error),
        type: "warm",
      })
    );
  }
}
export function* getParkingsFullStatusesRequest(
  action: IAction<{ customerId: string; groupsId: string }>
) {
  try {
    yield put(actions.app.saga.asyncAddLoading("loadingParkingsStatuses"));
    const {
      payload: { customerId, groupsId },
    } = action;
    const result: GetParkingsFullStatusesResponse = yield call(
      parkingsStatusesCall,
      {
        customerId,
        groupsId,
      }
    );
    yield put(actions.parking.setParkingsStatuses(result));
    yield put(actions.app.saga.asyncRemoveLoading("loadingParkingsStatuses"));
  } catch (error) {
    yield put(actions.app.saga.asyncRemoveLoading("loadingParkingsStatuses"));
    yield put(
      actions.app.saga.asyncInitNotification({
        messages: errorApiService.catchRequestError(error),
        type: "warm",
      })
    );
  }
}
