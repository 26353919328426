/* eslint-disable import/no-anonymous-default-export */
import { GetEntityGroupsResponse, GetParkingsFullStatusesResponse, Parking, ParkingInfo, ParkingSpaceModbusStatus, ParkingSpaceStatus, ParkingStatus } from "../../dto/parking.dtos";
import types from "../../types";
import { actionCreator } from "../../utils";

export default {
  setParkings: actionCreator<ParkingInfo[]>(types.parking.SET_PARKINGS),
  setActiveParking:actionCreator<ParkingStatus>(types.parking.SET_ACTIVE_PARKING),
  setActiveParkingDetails:actionCreator<Parking>(types.parking.SET_ACTIVE_PARKING_DETAILS),
  setParkingSpaces:actionCreator<ParkingStatus>(types.parking.SET_ACTIVE_PARKING_SPACES),
  setParkingGroups:actionCreator<GetEntityGroupsResponse>(types.parking.SET_PARKING_GROUPS),
  setParkingsStatuses:actionCreator<GetParkingsFullStatusesResponse>(types.parking.SET_PARKINGS_STATUSES),
  setActiveParkingSpace:actionCreator<ParkingSpaceStatus>(types.parking.SET_ACTIVE_PARKING_SPACE),
  saga: {
    asyncGetParkings: actionCreator<{ customerId: string; filter: string }>(types.parking.saga.ASYNC_GET_PARKINGS),
    asyncGetParkingDetails: actionCreator<{ customerId: string; id: string }>(types.parking.saga.ASYNC_GET_PARKIN_DETAILS),
    asyncGetParkingSpaces: actionCreator<{ customerId: string; id: string }>(types.parking.saga.ASYNC_GET_PARKING_SPACES),
    asyncGetParkingSpace: actionCreator<{ customerId: string; id: string, callback?:(item:ParkingSpaceStatus) => void  }>(types.parking.saga.ASYNC_GET_PARKING_SPACE),
    asyncGetParkingGroups: actionCreator<{ customerId: string; }>(types.parking.saga.ASYNC_GET_PARKING_GROUPS),
    asyncGetParkingsStatuses: actionCreator<{ customerId: string; groupsId:string }>(types.parking.saga.ASYNC_GET_PARKINGS_STATUSES),

    asyncSetParkingSpaceEngineerMode: actionCreator<{ customerId: string; id:string;callbackEngineer?:(item:ParkingSpaceStatus) => void }>(types.parking.saga.ASYNC_SET_PARKING_SPACE_ENGINEER_MODE),
    asyncSetParkingSpaceOffMode: actionCreator<{ customerId: string; id:string;callbackOff:(item:ParkingSpaceStatus) => void }>(types.parking.saga.ASYNC_SET_PARKING_SPACE_OFF_MODE),
    asyncSetParkingSpaceWorkMode: actionCreator<{ customerId: string; id:string;callbackWork?:(item:ParkingSpaceStatus) => void }>(types.parking.saga.ASYNC_SET_PARKING_SPACE_WORK_MODE),
    asyncTestParkingSpace: actionCreator<{ customerId: string; id:string;callback?:(item:ParkingSpaceStatus) => void}>(types.parking.saga.ASYNC_TEST_PARKING_SPACE),
    asyncOpenParkingSpace: actionCreator<{ customerId: string; id:string;callbackOpen?:(item:ParkingSpaceStatus) => void}>(types.parking.saga.ASYNC_OPEN_PARKING_SPACE),
    asyncCloseParkingSpace: actionCreator<{ customerId: string; id:string;callbackClose?:(item:ParkingSpaceStatus) => void}>(types.parking.saga.ASYNC_CLOSE_PARKING_SPACE),
    asyncMoveStepParkingSpace: actionCreator<{ customerId: string; id:string;open:boolean, description:string, callbackMoveStep?:(item:ParkingSpaceStatus) => void}>(types.parking.saga.ASYNC_MOVE_STEP_PARKING_SPACE),
    asyncGetParkingSpaceModbusStatus: actionCreator<{ customerId: string; id:string;callbackModbusStatus?:(item:ParkingSpaceModbusStatus) => void}>(types.parking.saga.ASYNC_GET_PARKING_SPACE_MODBUS_STATUS),
    asyncSetParkingSpaceModbusParameter: actionCreator<{ customerId: string; id:string; address: number; value: string; description: string;callbackEditModbusStatus?:(item:ParkingSpaceModbusStatus) => void}>(types.parking.saga.ASYNC_SET_PARKING_SPACE_MODBUS_PARAMETER),
  },
};
