/* eslint-disable import/no-anonymous-default-export */
import IState from "../../interfaces/Store.Interface";


const getRoot = (state: IState) => state.app;

export default {
	get: (state: IState) => getRoot(state),
	getGlobalLoading: (state: IState) => getRoot(state).globalLoading,
	getCurrentLoading: (state: IState) => getRoot(state).currentLoadings,
	getNotification: (state:IState) => getRoot(state).notification,
};
