/* eslint-disable import/no-anonymous-default-export */
import { AuthenticateResponse, Customer } from '../../dto/hub.dtos';
import types from '../../types';
import { actionCreator } from '../../utils';


export default {
	setAuth: actionCreator<AuthenticateResponse>(types.auth.SET_AUTH),
	setCustomer: actionCreator<Customer>(types.auth.SET_CUSTOMER),
	saga: {
		asyncLogIn: actionCreator<{login: string, password: string, callback?:() => void}>(types.auth.saga.ASYNC_LOG_IN),
		asyncGetCustomer: actionCreator<{id?:string}>(types.auth.saga.ASYNC_GET_CUSTOMER),
	},
};
