import React from "react";
import HeaderElement from "../../../../layout/Header";
import { Body, Content, Footer, Header } from "./styledComponent";

import { Routes, Route, useLocation, useParams } from "react-router-dom";

import InitialStepCalibrationsUltrasonicSensorPage from "./initial";

import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import selectors from "../../../../../store/selectors";
import { Loading } from "../../ParkingSpacesList/styledComponent";
import FirstStepCalibrationsCurrentConsumptionPage from "./step1";
import FinishStepCalibrationsCurrentConsumptionPage from "./finish";
import tokenService from "../../../../../service/token.service";
import actions from "../../../../../store/actions";
import { ParkingSpaceStatus } from "../../../../../store/dto/parking.dtos";
import { Customer } from "../../../../../store/dto/hub.dtos";

const CalibrationsCurrentConsumptionPage: React.FC = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { spacesId, spaceId } = useParams();
  const loading: string[] = useSelector(selectors.app.getCurrentLoading) || [];
  const [isPercentResult, setIsPercentResult] = React.useState<number>(0);
  const [isStep, setIsStep] = React.useState<number>(0);
  const [space, setSpace] = React.useState<ParkingSpaceStatus>(
    {} as ParkingSpaceStatus
  );
  const customer: Customer = useSelector(selectors.auth.getCustomer) || [];

  React.useEffect(() => {
    switch (pathname.split("/")[5]) {
      case "initial":
        setIsPercentResult(5);
        setIsStep(1);
        break;
      case "step-1":
        setIsPercentResult(50);
        setIsStep(2);
        break;
      case "finish":
        setIsPercentResult(100);
        setIsStep(3);
        break;
      default:
        setIsPercentResult(0);
    }
  }, [pathname]);
  React.useEffect(() => {
    const callback = (value: ParkingSpaceStatus) => {
      setSpace(value);
    };
    dispatch(
      actions.parking.saga.asyncGetParkingSpace({
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
        id: spaceId as string,
        callback,
      })
    );
  }, []);

  return (
    <>
      <Content>
        <Header>
          <span>Калибровка показаний тока</span>
          <span>{space?.fullName?.toLocaleLowerCase()}</span>
        </Header>

        <Body>
          <Routes>
            <Route
              path="initial"
              element={<InitialStepCalibrationsUltrasonicSensorPage />}
            />
            <Route
              path="step-1"
              element={<FirstStepCalibrationsCurrentConsumptionPage />}
            />
            {/*  <Route
              path="step-2"
              element={<SecondStepCalibrationsUltrasonicSensorPage />}
            />
            <Route
              path="step-3"
              element={<ThirdStepCalibrationsUltrasonicSensorPage />}
            />*/}
            <Route
              path="finish"
              element={<FinishStepCalibrationsCurrentConsumptionPage />}
            />
          </Routes>
        </Body>
        <Footer>
          {/* <Progress percent={isPercentResult} showInfo={false} /> */}
          <span> Шаг {isStep} из 3 </span>
        </Footer>
      </Content>
      {(loading.find((item) => item === "setParkingSpaceEngineerMode") ||
        loading.find((item) => item === "setParkingSpaceOffModeRequest") ||
        loading.find((item) => item === "setParkingSpaceWorkModeRequest") ||
        loading.find((item) => item === "loadingParkingStatusSpace") ||
        loading.find((item) => item === "closeParkingSpaceRequest") ||
        loading.find((item) => item === "openParkingSpaceRequest") ||
        loading.find((item) => item === "loadingParametersStep1") ||
        loading.find((item) => item === "loadingParkingDetails") ||
        loading.find(
          (item) => item === "setParkingSpaceModbusParameterRequest"
        ) ||
        loading.find((item) => item === "getGeolocation") ||
        loading.find((item) => item === "writeToModbus") ||
        loading.find((item) => item === "testParkingSpaceRequest")) && (
        <Loading>
          <Spin></Spin>
        </Loading>
      )}
    </>
  );
};

export default CalibrationsCurrentConsumptionPage;
