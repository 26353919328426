/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import {
  Body,
  BodyButtonContent,
  BodyResultItem,
  ButtonContent,
  Content,
  Description,
  DescriptionsContent,
  Footer,
  Header,
  IndexResultItem,
  LoadingStep1,
  ResultContent,
  ResultItem,
  StartButton,
  TitleContent,
  TitleResult,
} from "./styledComponent";
import { Progress, Modal, Button, message, Collapse } from "antd";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../../../../store/actions";
import { useNavigate, useParams } from "react-router-dom";
import selectors from "../../../../../../store/selectors";
import tokenService from "../../../../../../service/token.service";
import {
  ModbusParameter,
  ParkingSpaceModbusStatus,
  ParkingSpaceStatus,
} from "../../../../../../store/dto/parking.dtos";
import { Customer } from "../../../../../../store/dto/hub.dtos";
import { ModalFooter } from "../initial/styledComponent";

const { Panel } = Collapse;

const ThirdStepCalibrationsUltrasonicSensorPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isModalWarningOpen, setIsModalWarningOpen] = React.useState(false);
  const [isModalOpenNext, setIsModalNextOpen] = React.useState(false);
  const [percent, setPercent] = React.useState<number>(0);
  const [counterErrors, setCounterErrors] = React.useState<number>(0);
  const [averageValue, setAverageValue] = React.useState<{
    averageValue1: number;
    averageValue2: number;
  }>({ averageValue1: 0, averageValue2: 0 });
  const [isArrayParameters, setIsArrayParameters] = React.useState<
    ParkingSpaceModbusStatus[]
  >([]);
  const { spacesId, spaceId } = useParams();
  const customer: Customer = useSelector(selectors.auth.getCustomer) || [];
  const loading: string[] = useSelector(selectors.app.getCurrentLoading) || [];
  const [messageApi, contextHolder] = message.useMessage();

  let counter: number = 0;
  let arrayParameters: ParkingSpaceModbusStatus[] = [];
  let counterError: number = 0;

  React.useEffect(() => {}, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(actions.app.saga.asyncAddLoading("writeToModbus"));

    const callbackEditModbusStatus = (value: ParkingSpaceModbusStatus) => {
      const callbackEditModbusStatus = (value: ParkingSpaceModbusStatus) => {
        navigate(
          `/parkings/${spacesId}/${spaceId}/calibrations-ultrasonic-sensor/finish`
        );
        setIsModalOpen(false);
      };
      dispatch(
        actions.parking.saga.asyncSetParkingSpaceModbusParameter({
          customerId: customer.id
            ? customer.id
            : (tokenService.customerId as string),
          id: spaceId as string,
          address: 10,
          description: "",
          value: averageValue.averageValue2.toString(),
          callbackEditModbusStatus,
        })
      );
    };

    dispatch(
      actions.parking.saga.asyncSetParkingSpaceModbusParameter({
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
        id: spaceId as string,
        address: 9,
        description: "",
        value: averageValue.averageValue1.toString(),
        callbackEditModbusStatus,
      })
    );
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOkWarning = () => {
    setIsModalWarningOpen(false);
  };

  const handleCancelWarning = () => {
    setIsModalWarningOpen(false);
  };

  const handleOkNext = () => {
    setIsModalNextOpen(false);
  };

  const handleCancelNext = () => {
    setIsModalNextOpen(false);
  };

  const preStart = () => {
    counter = 0;
    counterError = 0;
    arrayParameters = [];
    setIsArrayParameters([]);
    start();
    setPercent(0);
    setAverageValue({ averageValue1: 0, averageValue2: 0 });
    setCounterErrors(0);
  };

  const start = () => {
    dispatch(actions.app.saga.asyncAddLoading("loadingParametersStep1"));

    const callbackModbusStatus = (value: ParkingSpaceModbusStatus) => {
      if (counter < 4) {
        const timer = setTimeout(() => {
          // setIsArrayParameters([...isArrayParameters, value]);
          if (counter === 0) setPercent(30);
          if (counter === 1) setPercent(60);
          if (counter === 2) setPercent(80);
          if (counter === 3) setPercent(100);
          arrayParameters.push(value);
          counter++;
          start();
        }, 3000);
        return () => clearTimeout(timer);
      } else {
        let tempAvverage1 = 0;
        let tempAvverage2 = 0;
        setPercent(100);
        dispatch(actions.app.saga.asyncRemoveLoading("loadingParametersStep1"));
        setIsArrayParameters(arrayParameters);
        arrayParameters.map((item) => {
          const temp1: ModbusParameter = item.parameters.find(
            (m) => m.address === 69
          ) as ModbusParameter;
          const temp2: ModbusParameter = item.parameters.find(
            (m) => m.address === 70
          ) as ModbusParameter;
          // const temp1: ModbusParameter = item.parameters.find(
          //   (m) => m.address === 69
          // ) as ModbusParameter;
          // const temp2: ModbusParameter = item.parameters.find(
          //   (m) => m.address === 70
          // ) as ModbusParameter;

          if (temp1?.value === 255) {
            counterError++;
            tempAvverage1 += temp1?.value;
          } else {
            tempAvverage1 += temp1?.value;
          }
          if (temp2?.value === 255) {
            counterError++;
            tempAvverage2 += temp1?.value;
          } else {
            tempAvverage2 += temp2?.value;
          }
 
        });
        setAverageValue({
          averageValue1: Math.round(tempAvverage1 / 4),
          averageValue2: Math.round(tempAvverage2 / 4),
        });
        setCounterErrors(counterError);

        counterError !== 0 && setIsModalWarningOpen(true);
      }
    };

    dispatch(
      actions.parking.saga.asyncGetParkingSpaceModbusStatus({
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
        id: spaceId as string,
        callbackModbusStatus,
      })
    );
  };

  const backButton = () => {
    navigate(
      `/parkings/${spacesId}/${spaceId}/calibrations-ultrasonic-sensor/step-2`
    );
  };
  const nextButton = () => {
    if (counterErrors === 0 && isArrayParameters.length !== 0) {
      showModal();
    } else {
      setIsModalNextOpen(true);
    }
  };
  return (
    <Body>
      {contextHolder}
      <Header>
        <span>Расчет новых показаний</span>
      </Header>
      <Description>
        <Collapse bordered={false} ghost>
          <Panel header="Описание" key="1">
            <TitleContent>
              <span>
                На данном этапе программа несколько раз запросит данные с
                датчиков, что бы рассчитать среднее значение.
              </span>
              <span>
                Если датчики исправны и система видит машину, то программа
                отобразит для каждого датчика значение, не равное 255, что
                значит, что датчики{" "}
                <span style={{ color: "green" }}>работают</span> и можно
                продолжать калибровку.
              </span>
              <span>
                Если датчики показывают 255, при этом над замком стоит
                транспортное средство, то вероятно, присутствует какая-то{" "}
                <span style={{ color: "red" }}>проблема</span>, попробуйте{" "}
                <span style={{ color: "rgb(255,138,0)" }}>еще раз</span>, либо
                замените датчики.
              </span>
              <span>
                Убедитесь, что над парковочным местом стоит автомобиль и нажмите{" "}
                <span style={{ color: "rgb(255,138,0)" }}>
                  Получение новых данных
                </span>
                .
              </span>

              <span>
                После того, как все замеры прошли успешно, и результаты обведены{" "}
                <span style={{ color: "green" }}>зеленой</span> рамкой нажмите{" "}
                <span style={{ color: "rgb(255,138,0)" }}>Дальше</span> внизу
                экрана
              </span>
            </TitleContent>
          </Panel>
        </Collapse>

        {/* <DescriptionsContent>
          <span>1. Убедитесь, что на парковочном месте стоит машина.</span>
        </DescriptionsContent> */}
      </Description>
      <Content>
        <StartButton>
          <BodyButtonContent>
            <Button
              type="primary"
              style={{ height: "45px", width: "100%" }}
              onClick={() => preStart()}
            >
              Получение новых данных
            </Button>
          </BodyButtonContent>
        </StartButton>

        {isArrayParameters.length !== 0 && (
          <TitleResult>
            <div
              style={{
                color: averageValue.averageValue1 === 255 ? "red" : "",
              }}
            >
              <span> Среднее значение датчика 1 </span>
              <span> {averageValue.averageValue1}</span>
            </div>
            <div
              style={{
                color: averageValue.averageValue2 === 255 ? "red" : "",
              }}
            >
              <span> Среднее значение датчика 2 </span>
              <span> {averageValue.averageValue2}</span>
            </div>
          </TitleResult>
        )}

        <ResultContent>
          {isArrayParameters &&
            isArrayParameters?.map((item, index) => (
              <ResultItem
                color={
                  item.parameters.find((m) => m.address === 69)?.value !==
                    255 &&
                  item.parameters.find((m) => m.address === 70)?.value !== 255
                    ? "green"
                    : "red"
                }
              >
                <IndexResultItem>
                  Результат измерений {index + 1}
                </IndexResultItem>
                <BodyResultItem>
                  <div
                    style={{
                      color:
                        item.parameters.find((m) => m.address === 69)?.value ===
                        255
                          ? "red"
                          : "",
                    }}
                  >
                    <span>Значение датчика 1</span>
                    <span>
                      {
                        item.parameters.find((m) => m.address === 69)
                          ?.stringValue
                      }
                    </span>
                  </div>
                  <div
                    style={{
                      color:
                        item.parameters.find((m) => m.address === 70)?.value ===
                        255
                          ? "red"
                          : "",
                    }}
                  >
                    <span>Значение датчика 2</span>
                    <span>
                      {item.parameters.find((m) => m.address === 70)?.value}
                    </span>
                  </div>
                </BodyResultItem>
              </ResultItem>
            ))}
        </ResultContent>
      </Content>
      <Footer>
        <Button style={{ height: "40px" }} key="back" onClick={backButton}>
          Назад
        </Button>
        <Button
          style={{ height: "40px" }}
          onClick={() =>
            navigate(`/parkings/${spacesId}/${spaceId}/modbus-parameters`)
          }
        >
          Домой
        </Button>
        <Button
          // disabled={!isArrayParameters.length}
          style={{ height: "40px" }}
          type="primary"
          onClick={nextButton}
        >
          Дальше
        </Button>
      </Footer>

      <Modal
        title="Подтверждение!"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={[
          <ModalFooter>
            <Button key="back" onClick={handleCancel}>
              Отмена
            </Button>
            <Button key="submit" type="primary" onClick={handleOk}>
              ОК
            </Button>
          </ModalFooter>,
        ]}
      >
        <p>После нажатия "ОК" система запишет новые данные в систему</p>
      </Modal>

      <Modal
        title="Внимание!"
        open={isModalWarningOpen}
        onOk={handleOkWarning}
        onCancel={handleCancelWarning}
        centered
        footer={[
          <ModalFooter>
            <Button key="submit" type="primary" onClick={handleOkWarning}>
              ОК
            </Button>
          </ModalFooter>,
        ]}
      >
        <p>В результате одного из замеров датчики не обнаружили препятствие!</p>
        <p>Попробуйте еще раз</p>
      </Modal>

      <Modal
        title="Внимание!"
        open={isModalOpenNext}
        onOk={handleOkNext}
        onCancel={handleCancelNext}
        centered
        footer={[
          <ModalFooter>
            <Button key="submit" type="primary" onClick={handleOkNext}>
              ОК
            </Button>
          </ModalFooter>,
        ]}
      >
        {isArrayParameters.length === 0 ? (
          <>
            <p>Вы не произвели проверку датчиков!</p>
          </>
        ) : (
          <>
            <p>Вы не выполнили все условия для перехода на следующий этап.</p>
            <p>
              Убедитесь, что все замеры прошли успешно и датчики видят машину.
            </p>
            <p>Пожалуйста, следуйте инструкции из описания.</p>
          </>
        )}
      </Modal>

      {loading.find((item) => item === "loadingParametersStep1") && (
        <LoadingStep1>
          <Progress showInfo={false} type="circle" percent={percent} />
        </LoadingStep1>
      )}
    </Body>
  );
};

export default ThirdStepCalibrationsUltrasonicSensorPage;
