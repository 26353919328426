import styled from "styled-components";

export const Loading = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1001;
  background-color: #00000085;
  box-sizing: margin-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Body = styled.div`
  color: #fff;
  box-sizing: border-box;
  padding: 0px 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 40px);
`;
export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 0;
  width: 100%;
  height:60px;
  margin-top: auto;
  div:nth-child(1) {
    gap: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* max-height: calc(100vh - 100px); */
  overflow: auto;
`;
export const TitleContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
export const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
export const NotificationModal = styled.div`
  position: fixed;
  /* position:relative; */
  min-width: calc(100vw - 40px);
  width: calc(100vw - 40px);
  max-width: calc(100vw - 40px);
  background-color: #171725;
  z-index: 2000;
  bottom: 3%;
  left: 20px;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 25px;
  box-sizing: border-box;
  border: 2px solid #ff8a00;
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    /* or 26px */
    color: #ffffff;
  }
  .close-icon-notification-modal {
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
  }
`;
