/* eslint-disable import/no-anonymous-default-export */
import authAction from "./auth";
import appAction from "./app";
import parkingAction from "./parking";

export default {
  app: appAction,
  auth: authAction,
  parking: parkingAction,
  // data: dataAction,
  // parkingManipulation: parkingManipulationAction,
};
