import styled from "styled-components";

export const Loading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1001;
  background-color: #00000085;
  box-sizing: margin-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Body = styled.div`
  color: #fff;
  box-sizing: border-box;
  padding: 0px 8px 0px 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 40px);
`;
export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  /* top: 0;  */
  width: 100%;
  min-height: 60px;
  /* padding:12px 0px; */
  margin-top: auto;
  div:nth-child(1) {
    gap: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 16px; */
  max-height: calc(100vh - 100px);
  overflow: auto;
`;
export const TitleContent = styled.div`
  display: flex;
  flex-direction: column;
  /* position:fixed; */
  width:100%;
  padding:12px 0px;
  gap: 6px;
`;
export const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const BodyModBusParameters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const TitleModBusParameters = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
`;
export const ContentModBusParameters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  gap: 18px;
`;
export const ManipulationMode = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const TitleManipulationMode = styled.div`
  color: #fff;
`;
export const ContentManipulationMode = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  gap: 12px;
`;

export const BodyEditParameter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const FormEditParameter = styled.div``;
export const FooterEditParameter = styled.div``;
export const ParametersModbusBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  padding: 8px 5px;
  box-sizing: border-box;
  background-color: #8080803b;
  border-radius: 4px;
`;
export const TitleParametersModbusBlock = styled.div`
  padding: 6px 0px;
  font-size: 17px;
`;
