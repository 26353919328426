/* eslint-disable import/no-anonymous-default-export */

export default {
  GLOBAL_LOADING: "SET_LOADING",
  SET_CURRENT_LOADINGS: "SET_CURRENT_LOADINGS",
  SET_NOTIFICATION: "SET_NOTIFICATION",
  saga: {
    ASYNC_ADD_LOADING: "ASYNC_ADD_LOADING",
    ASYNC_REMOVE_LOADING: "ASYNC_REMOVE_LOADING",
    ASYNC_ADD_NOTIFICATION: "ASYNC_ADD_NOTIFICATION",
    ASYNC_REMOVE_NOTIFICATION: "ASYNC_REMOVE_NOTIFICATION",
    ASYNC_INIT_NOTIFICATION: "ASYNC_INIT_NOTIFICATION",
  },
};
