/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from "react";
import {
  Body,
  Title,
  Content,
  TitleContent,
  Loading,
  ManipulationMode,
  TitleManipulationMode,
  ContentManipulationMode,
  ContentModal,
  WorkMode,
  TitleWorkMode,
  ContentWorkMode,
} from "./styledComponent";
import {
  Parking,
  ParkingSpaceMode,
  ParkingSpaceStatus,
} from "../../../../store/dto/parking.dtos";
import { useDispatch, useSelector } from "react-redux";
import selectors from "../../../../store/selectors";
import { Button, Spin, message, Modal, Result, notification } from "antd";
import { LeftOutlined, SmileOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import actions from "../../../../store/actions";
import ListParkingSpaceItem from "../../../elements/ListParkingSpaceItem";
import { Customer } from "../../../../store/dto/hub.dtos";
import tokenService from "../../../../service/token.service";

const ParkingSpaceSettings: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { spacesId, spaceId } = useParams();
  const [space, setSpace] = React.useState<ParkingSpaceStatus>(
    {} as ParkingSpaceStatus
  );
  const [isModalEngineerModeOpen, setIsModalEngineerModelOpen] = React.useState(
    false
  );

  const loading: string[] = useSelector(selectors.app.getCurrentLoading) || [];
  const customer: Customer = useSelector(selectors.auth.getCustomer) || [];
  const [parkingDetails, setParkingDetails] = React.useState<Parking>();
  const parkingDetailsActive: Parking = useSelector(
    selectors.parking.getActiveParkingDetails
  );
  const [messageApi, contextHolder] = message.useMessage();

  const moveStepParking = (open: boolean) => {
    const callbackMoveStep = (value: ParkingSpaceStatus) => {
      setSpace(value);
    };
    dispatch(
      actions.parking.saga.asyncMoveStepParkingSpace({
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
        id: space.id,
        open,
        description: "",
        callbackMoveStep,
      })
    );
  };
  const openParking = () => {
    const callbackOpen = (value: ParkingSpaceStatus) => {
      setSpace(value);
      // api["success"]({
      //   message: "Команда выполнена успешно",
      //   // description:
      //   //   "This is the content of the notification. This is the content of the notification. This is the content of the notification.",
      //   placement: "bottom",
      // });
    };
    dispatch(
      actions.parking.saga.asyncOpenParkingSpace({
        customerId: "",
        id: space.id,
        callbackOpen,
      })
    );
  };
  const closeParking = () => {
    const callbackClose = (value: ParkingSpaceStatus) => {
      setSpace(value);
      // api["success"]({
      //   message: "Команда выполнена успешно",
      //   // description:
      //   //   "This is the content of the notification. This is the content of the notification. This is the content of the notification.",
      //   placement: "bottom",
      // });
    };
    dispatch(
      actions.parking.saga.asyncCloseParkingSpace({
        customerId: "",
        id: space.id,
        callbackClose,
      })
    );
  };
  const testParking = () => {
    const callback = (value: ParkingSpaceStatus) => setSpace(value);
    dispatch(
      actions.parking.saga.asyncTestParkingSpace({
        customerId: "",
        id: space.id,
        callback,
      })
    );
  };

  const setWorkMode = () => {
    const callbackWork = (value: ParkingSpaceStatus) => setSpace(value);
    dispatch(
      actions.parking.saga.asyncSetParkingSpaceWorkMode({
        customerId: "",
        id: space.id,
        callbackWork,
      })
    );
  };

  const handleOkModalForEngineering = () => {
    setEngineerMode();
  };

  const handleCancelModalForEngineering = () => {
    setIsModalEngineerModelOpen(false);
  };

  const setEngineerMode = () => {
    const callbackEngineer = (value: ParkingSpaceStatus) => {
      setSpace(value);
      isModalEngineerModeOpen && setIsModalEngineerModelOpen(false);
    };
    dispatch(
      actions.parking.saga.asyncSetParkingSpaceEngineerMode({
        customerId: "",
        id: space.id,
        callbackEngineer,
      })
    );
  };
  const setOffMode = () => {
    const callbackOff = (value: ParkingSpaceStatus) => setSpace(value);
    dispatch(
      actions.parking.saga.asyncSetParkingSpaceOffMode({
        customerId: "",
        id: space.id,
        callbackOff,
      })
    );
  };

  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "Замок уже в этом режиме!",
    });
  };

  const refresh = () => {
    const callback = (value: ParkingSpaceStatus) => setSpace(value);
    dispatch(
      actions.parking.saga.asyncGetParkingSpace({
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
        id: spaceId as string,
        callback,
      })
    );
    dispatch(
      actions.parking.saga.asyncGetParkingDetails({
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
        id: spacesId as string,
      })
    );
    console.log(navigator?.geolocation);
  };

  React.useEffect(() => {
    refresh();
  }, []);

  React.useEffect(() => {
    setParkingDetails(parkingDetailsActive);
  }, [parkingDetailsActive]);

  const getDistance = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ): number => {
    const R = 6371e3; // metres
    const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return Math.round(R * c);
  };

  const initialCheckModeButton = (mode: ParkingSpaceMode) => {
    dispatch(actions.app.saga.asyncAddLoading("getGeolocation"));
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (location) => {
          if (location)
            if (
              getDistance(
                parkingDetails?.lat as number,
                parkingDetails?.lon as number,
                location.coords.latitude,
                location.coords.longitude
              ) <= 300
            ) {
              // setLatLng({
              //   lat: location.coords.latitude,
              //   lon: location.coords.longitude,
              // });
              switch (mode) {
                case ParkingSpaceMode.Work:
                  if (space.mode === ParkingSpaceMode.Work) {
                    warning();
                  } else {
                    setWorkMode();
                  }
                  break;
                case ParkingSpaceMode.Engineer:
                  if (space.mode === ParkingSpaceMode.Engineer) {
                    warning();
                  } else {
                    if (
                      space?.mode === ParkingSpaceMode.Work &&
                      !space.available
                    ) {
                      setIsModalEngineerModelOpen(true);
                    } else setEngineerMode();
                  }
                  break;
                case ParkingSpaceMode.Off:
                  if (space.mode === ParkingSpaceMode.Off) {
                    warning();
                  } else {
                    setOffMode();
                  }
                  break;
                default:
                  alert("Нет таких значений");
                  break;
              }
            } else {
              messageApi.open({
                type: "error",
                content: "Вы находитесь далеко",
              });
            }
          dispatch(actions.app.saga.asyncRemoveLoading("getGeolocation"));
        },
        (error) => {
          console.log(error.PERMISSION_DENIED);
          switch (error.code) {
            case error.PERMISSION_DENIED:
              messageApi.open({
                type: "error",
                content: "Разрешите определение вашего местоположения!",
              });
              break;
            case error.POSITION_UNAVAILABLE:
              messageApi.open({
                type: "error",
                content: "Информация о местоположении недоступна.",
              });
              break;
            case error.TIMEOUT:
              messageApi.open({
                type: "error",
                content:
                  "Время ожидания запроса на получение местоположения пользователя истекло.",
              });
              break;
            default:
              messageApi.open({
                type: "error",
                content: "Произошла неизвестная ошибка.",
              });
              break;
          }
          dispatch(actions.app.saga.asyncRemoveLoading("getGeolocation"));
        }
      );
    }
  };
  const initialCheckManipulationButton = (type: string) => {
    dispatch(actions.app.saga.asyncAddLoading("getGeolocation"));
    if (navigator?.geolocation) {
      console.log();
      navigator.geolocation.getCurrentPosition(
        (location) => {
          console.log(location.coords.latitude);
          if (location)
            if (
              getDistance(
                parkingDetails?.lat as number,
                parkingDetails?.lon as number,
                location.coords.latitude,
                location.coords.longitude
              ) <= 300
            ) {
              // setLatLng({
              //   lat: location.coords.latitude,
              //   lon: location.coords.longitude,
              // });
              switch (type) {
                case "testParking":
                  testParking();
                  break;
                case "openParking":
                  openParking();
                  break;
                case "closeParking":
                  closeParking();
                  break;
                case "moveStepTrue":
                  moveStepParking(true);
                  break;
                case "moveStepFalse":
                  moveStepParking(false);
                  break;
                default:
                  alert("Нет таких значений");
                  break;
              }
            } else {
              messageApi.open({
                type: "error",
                content: "Вы находитесь далеко",
              });
            }
          dispatch(actions.app.saga.asyncRemoveLoading("getGeolocation"));
        },
        (error) => {
          console.log(error.PERMISSION_DENIED);
          switch (error.code) {
            case error.PERMISSION_DENIED:
              messageApi.open({
                type: "error",
                content: "Разрешите определение вашего местоположения!",
              });
              break;
            case error.POSITION_UNAVAILABLE:
              messageApi.open({
                type: "error",
                content: "Информация о местоположении недоступна.",
              });
              break;
            case error.TIMEOUT:
              messageApi.open({
                type: "error",
                content:
                  "Время ожидания запроса на получение местоположения пользователя истекло.",
              });
              break;
            default:
              messageApi.open({
                type: "error",
                content: "Произошла неизвестная ошибка.",
              });
              break;
          }
          dispatch(actions.app.saga.asyncRemoveLoading("getGeolocation"));
        }
      );
    }
  };

  return (
    <>
      {contextHolder}
      {/* {contextNotificationHolder} */}
      <Body>
        <Content>
          <TitleContent></TitleContent>

          <ContentModal>
            <ListParkingSpaceItem space={space}></ListParkingSpaceItem>
            <WorkMode>
              <TitleWorkMode>
                <span> Управление режимом</span>
                {/* <span> Текущий режим : {getNameMode()}</span> */}
                {/* <span> Текущий lat-lon : {`${latLng.lat} ${latLng.lon}`}</span>
                <span>
                  {" "}
                  Парковка lat-lon :{" "}
                  {`${parkingDetails?.lat} ${parkingDetails?.lon}`}
                </span>
                <span>
                  {" "}
                  Расстояния :{" "}
                  {getDistance(
                    parkingDetails?.lat as number,
                    parkingDetails?.lon as number,
                    latLng.lat,
                    latLng.lon
                  )}
                </span> */}
              </TitleWorkMode>

              <ContentWorkMode>
                <Button
                  style={{
                    color:
                      space.mode === ParkingSpaceMode.Work ? "#ffa229" : "#fff",
                    borderColor:
                      space.mode === ParkingSpaceMode.Work ? "#ffa229" : "#fff",
                  }}
                  onClick={() => initialCheckModeButton(ParkingSpaceMode.Work)}
                  // onClick={
                  //   space.mode === ParkingSpaceMode.Work ? warning : setWorkMode
                  // }
                >
                  Рабочий
                </Button>
                <Button
                  style={{
                    color:
                      space.mode === ParkingSpaceMode.Engineer
                        ? "#ffa229"
                        : "#fff",
                    borderColor:
                      space.mode === ParkingSpaceMode.Engineer
                        ? "#ffa229"
                        : "#fff",
                  }}
                  onClick={() =>
                    initialCheckModeButton(ParkingSpaceMode.Engineer)
                  }
                  // onClick={
                  //   space.mode === ParkingSpaceMode.Engineer
                  //     ? warning
                  //     : checkOnAvailableEngineeredMode
                  // }
                >
                  Инженерный
                </Button>
                <Button
                  style={{
                    color:
                      space.mode === ParkingSpaceMode.Off ? "#ffa229" : "#fff",
                    borderColor:
                      space.mode === ParkingSpaceMode.Off ? "#ffa229" : "#fff",
                  }}
                  onClick={() => initialCheckModeButton(ParkingSpaceMode.Off)}
                  // onClick={
                  //   space.mode === ParkingSpaceMode.Off ? warning : setOffMode
                  // }
                >
                  Выключен
                </Button>
              </ContentWorkMode>
            </WorkMode>
            <ManipulationMode>
              <TitleManipulationMode>
                <span> Управление</span>
              </TitleManipulationMode>
              <ContentManipulationMode>
                <Button
                  style={{ width: "100%", height: "50px" }}
                  type="primary"
                  onClick={() =>
                    navigate(
                      `/parkings/${spacesId}/${space.id}/modbus-parameters`
                    )
                  }
                >
                  Параметры и калибровка
                </Button>
                <Button
                  style={{ width: "100%", height: "50px" }}
                  type="primary"
                  onClick={() => initialCheckManipulationButton("testParking")}
                >
                  Тест парковок
                </Button>
                <Button
                  style={{ width: "100%", height: "50px" }}
                  type="primary"
                  onClick={() => initialCheckManipulationButton("openParking")}
                >
                  Открыть с удержанием
                </Button>
                <Button
                  style={{ width: "100%", height: "50px" }}
                  type="primary"
                  onClick={() => initialCheckManipulationButton("closeParking")}
                >
                  Закрыть принудительно
                </Button>
                <Button
                  style={{ width: "100%", height: "50px" }}
                  type="primary"
                  onClick={() => initialCheckManipulationButton("moveStepTrue")}
                >
                  Шаг в сторону открытия
                </Button>
                <Button
                  style={{ width: "100%", height: "50px" }}
                  type="primary"
                  onClick={() =>
                    initialCheckManipulationButton("moveStepFalse")
                  }
                >
                  Шаг в сторону закрытия
                </Button>
              </ContentManipulationMode>
            </ManipulationMode>
          </ContentModal>
        </Content>

        <Title>
          <div onClick={() => navigate(`/parkings/${spacesId}`)}>
            <LeftOutlined />
            <span>Назад</span>
          </div>
          <div onClick={() => refresh()}>Обновить</div>
        </Title>
        {(loading.find((item) => item === "setParkingSpaceEngineerMode") ||
          loading.find((item) => item === "setParkingSpaceOffModeRequest") ||
          loading.find((item) => item === "setParkingSpaceWorkModeRequest") ||
          loading.find((item) => item === "loadingParkingStatusSpace") ||
          loading.find((item) => item === "closeParkingSpaceRequest") ||
          loading.find((item) => item === "openParkingSpaceRequest") ||
          loading.find((item) => item === "loadingParkingDetails") ||
          loading.find((item) => item === "getGeolocation") ||
          loading.find((item) => item === "moveStepParkingSpaceRequest") ||
          loading.find((item) => item === "testParkingSpaceRequest")) && (
          <Loading>
            <Spin></Spin>
          </Loading>
        )}

        <Modal
          title="Внимание"
          open={isModalEngineerModeOpen}
          onOk={handleOkModalForEngineering}
          onCancel={handleCancelModalForEngineering}
        >
          <p>
            На данном парковочном месте идёт сессия. После перевода в инженерный
            режим сессия будет закрыта и клиенту выставлен счет. Подтверждаете
            действие?
          </p>
        </Modal>
      </Body>
    </>
  );
};

export default ParkingSpaceSettings;
