import React from "react";
import {
  Body,
  Title,
  Content,
  ContentBody,
  TitleContent,
  Loading,
} from "./styledComponent";
import ListParkingSpaceItem from "../../../elements/ListParkingSpaceItem";
import {
  Parking,
  ParkingSpaceStatus,
  ParkingStatus,
} from "../../../../store/dto/parking.dtos";
import { useDispatch, useSelector } from "react-redux";
import selectors from "../../../../store/selectors";
import actions from "../../../../store/actions";
import { Skeleton, Spin } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { INotification } from "../../../../store/interfaces/App.Interface";
import { Customer } from "../../../../store/dto/hub.dtos";
import tokenService from "../../../../service/token.service";
import { keyboard } from "@testing-library/user-event/dist/keyboard";
import { v4 as uuid } from "uuid";

const ParkingSpacesList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { spacesId } = useParams();
  const [parkingDetails, setParkingDetails] = React.useState<Parking>();
  const [parkingSpaces, setParkingSpaces] = React.useState<ParkingStatus>();
  const loading: string[] = useSelector(selectors.app.getCurrentLoading) || [];
  const customer: Customer = useSelector(selectors.auth.getCustomer) || [];

  const parkingActive: ParkingStatus = useSelector(
    selectors.parking.getActiveParking
  );
  const parkingDetailsActive: Parking = useSelector(
    selectors.parking.getActiveParkingDetails
  );
  const parkingSpacesData: ParkingStatus = useSelector(
    selectors.parking.getParkingSpaces
  );

  React.useEffect(() => {
    dispatch(
      actions.parking.saga.asyncGetParkingDetails({
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
        id: spacesId as string,
      })
    );
    dispatch(
      actions.parking.saga.asyncGetParkingSpaces({
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
        id: spacesId as string,
      })
    );
  }, []);

  React.useEffect(() => {
    dispatch(actions.parking.setParkingSpaces(new ParkingStatus()));
  }, []);
  React.useEffect(() => {
    setParkingDetails(parkingDetailsActive);
  }, [parkingDetailsActive]);

  React.useEffect(() => {
    setParkingSpaces(parkingSpacesData);
  }, [parkingSpacesData]);

  const refresh = () => {
    dispatch(
      actions.parking.saga.asyncGetParkingSpaces({
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
        id: spacesId as string,
      })
    );
  };

  return (
    <>
      <Body>
        <Content>
          <TitleContent></TitleContent>

          <ContentBody>
            {loading.find((item) => item === "loadingParkingSpaces") ? (
              // <Skeleton active loading={true}></Skeleton>
              <>
                <Skeleton.Node active style={{ width: "100%" }}></Skeleton.Node>
                <Skeleton.Node active style={{ width: "100%" }}></Skeleton.Node>
                <Skeleton.Node active style={{ width: "100%" }}></Skeleton.Node>
              </>
            ) : (
              parkingSpaces?.spaces?.map((item: ParkingSpaceStatus) => (
                <ListParkingSpaceItem
                  key={uuid()}
                  space={item}
                ></ListParkingSpaceItem>
              ))
            )}
          </ContentBody>
        </Content>

        <Title>
          <div onClick={() => navigate("/parkings")}>
            <LeftOutlined />
            <h3>{parkingDetails?.name}</h3>
          </div>
          <div onClick={() => refresh()}>Обновить</div>
        </Title>
        {(loading.find((item) => item === "setParkingSpaceEngineerMode") ||
          loading.find((item) => item === "setParkingSpaceOffModeRequest") ||
          loading.find((item) => item === "setParkingSpaceWorkModeRequest") ||
          loading.find((item) => item === "testParkingSpaceRequest")) && (
          <Loading>
            <Spin></Spin>
          </Loading>
        )}
      </Body>
    </>
  );
};

export default ParkingSpacesList;
