import styled from "styled-components";

export const LoadingStep1 = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: red; */
  z-index: 10002;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Body = styled.div`
  position: relative;
  color: #fff;
  box-sizing: margin-box;
  padding: 0px 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
`;

export const Header = styled.div`
  font-size: 18px;
  color: #fff;
`;
export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TitleContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;
  color: #fff;
`;
export const DescriptionsContent = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  color: #fff;
`;

export const BodyButtonContent = styled.div`
  flex: 1;
  display: flex;
  color: black;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  color: #fff;
  overflow: auto;
`;

export const StartButton = styled.div`
  color: #fff;
`;
export const ResultContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  overflow: auto;
  /* max-height: calc(100vh - 560px); */
  /* margin-bottom:80px; */
`;
export const TitleResult = styled.div`
  display: flex;
  flex-direction: column;
    padding :12px 0px;
    background-color: #80808082;
    border-radius: 8px;
    gap:12px;

  div {
    gap:10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:0px 5px;
  }
`;
export const ResultItem = styled.div.attrs((props: { color: string }) => props)`
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;

  gap: 14px;
  background-color: #80808026;
  border: 2px solid ${(props) => (props.color ? props.color : "#fff")};
  border-radius: 8px;
  padding: 16px 8px;
`;
export const IndexResultItem = styled.div`
  font-size: 14px;
`;
export const BodyResultItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6px;
  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span{
      font-size: 14px;
    }
    span:nth-child(1) {
      /* font-size: 10px; */
      /* color: #b5b5b5; */
    }
    span:nth-child(2) {
      /* color: #fff; */
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: auto;
`;

export const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  color: #fff;
`;
