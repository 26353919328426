import styled from "styled-components";

export const Header = styled.div`
  height: 40px;
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  color: #fff;
  background-color: rgb(255 138 0 / 40%);
`;
export const MenuItem = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  right: 0;
  display: flex;
`;
export const MenuContent = styled.div`
  display: flex;
`;
export const MenuContentItem = styled.div`
  width: 100%;
`;
