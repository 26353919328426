const tokenService = {

	get bearerToken() {
		const value = sessionStorage.getItem('bearer_token');
		return value;
	},
	get refreshToken() {
		const value = sessionStorage.getItem('refresh_token');
		return value;
	},
	get customerId() {
		const value = sessionStorage.getItem('customerId');
		return value;
	},

	get isLoggedIn() {
		console.log('isLoggedIn', sessionStorage.getItem('bearer_token') !== null);
		return sessionStorage.getItem('bearer_token') !== null;
	},

	setToken(bearerToken:string, refreshToken:string) {
		localStorage.removeItem('bearer_token');
		localStorage.removeItem('refresh_token');

		sessionStorage.removeItem('bearer_token');
		sessionStorage.removeItem('refresh_token');

		sessionStorage.setItem('bearer_token', bearerToken);
		sessionStorage.setItem('refresh_token', refreshToken);
	},

	setCustomer(id:string){
		sessionStorage.removeItem('customerId');
		sessionStorage.setItem('customerId', id);
	},

	logout() {
		localStorage.removeItem('bearer_token');
		localStorage.removeItem('refresh_token');
		localStorage.removeItem('customerId');

		sessionStorage.removeItem('bearer_token');
		sessionStorage.removeItem('refresh_token');
		sessionStorage.removeItem('customerId');
	},
};
export default tokenService;
