/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import {
  Body,
  Content,
  Footer,
  FooterContent,
  ResultContent,
} from "./styledComponent";
import { Button, message, Result } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import selectors from "../../../../../../store/selectors";
import { Customer } from "../../../../../../store/dto/hub.dtos";
import {
  ModbusParameter,
  ParkingSpaceModbusStatus,
} from "../../../../../../store/dto/parking.dtos";
import actions from "../../../../../../store/actions";
import tokenService from "../../../../../../service/token.service";
import ParameterModbusItem from "../../../../../elements/ParameterModBus";

const FinishStepCalibrationsUltrasonicSensorPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { spacesId, spaceId } = useParams();
  const customer: Customer = useSelector(selectors.auth.getCustomer) || [];

  const [modbusStatus, setModbusStatus] = React.useState<
    ParkingSpaceModbusStatus
  >({} as ParkingSpaceModbusStatus);

  const [messageApi, contextHolder] = message.useMessage();

  React.useEffect(() => {
    // refresh();
    dispatch(actions.app.saga.asyncRemoveLoading("writeToModbus"));
  }, []);

  const refresh = () => {
    const callbackModbusStatus = (value: ParkingSpaceModbusStatus) =>
      setModbusStatus(value);
    dispatch(
      actions.parking.saga.asyncGetParkingSpaceModbusStatus({
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
        id: spaceId as string,
        callbackModbusStatus,
      })
    );
  };

  const backButton = () => {
    navigate(
      `/parkings/${spacesId}/${spaceId}/calibrations-ultrasonic-sensor/step-3`
    );
  };

  return (
    <Body>
      {contextHolder}
      <Content>
        <ResultContent>
          <Result
            status="success"
            title="Калибровка прошла успешно"
            subTitle="Минимальные значения для датчиков изменены"
            style={{ color: "#fff" }}
            // extra={[
            //   <Button type="primary" key="console">
            //     Go Console
            //   </Button>,
            //   <Button key="buy">Buy Again</Button>,
            // ]}
          />
        </ResultContent>
        <FooterContent>
          {/* {modbusStatus?.parameters?.map((item: ModbusParameter) => {
            return item.address === 9 || item.address === 10 ? (
              <ParameterModbusItem
                key={item.address}
                modbusParameter={item}
              ></ParameterModbusItem>
            ) : (
              <></>
            );
          })} */}
        </FooterContent>
      </Content>
      <Footer>
        <Button
          // disabled
          style={{ height: "40px" }}
          key="back"
          onClick={backButton}
        >
          Назад
        </Button>
        <Button
          type="primary"
          style={{ height: "40px" }}
          onClick={() =>
            navigate(`/parkings/${spacesId}/${spaceId}/modbus-parameters`)
          }
        >
          Домой
        </Button>
        {/* <Button
          disabled
          style={{ height: "40px" }}
          type="primary"
          // onClick={nextButton}
        >
          Дальше
        </Button> */}
      </Footer>
    </Body>
  );
};

export default FinishStepCalibrationsUltrasonicSensorPage;
