/* eslint-disable import/no-anonymous-default-export */
export default {
  SET_PARKINGS: "SET_PARKINGS",
  SET_ACTIVE_PARKING: "SET_ACTIVE_PARKING",
  SET_ACTIVE_PARKING_DETAILS: "SET_ACTIVE_PARKING_DETAILS",
  SET_ACTIVE_PARKING_SPACES: "SET_ACTIVE_PARKING_SPACES",
  SET_PARKING_GROUPS: "SET_PARKING_GROUPS",
  SET_PARKINGS_STATUSES:'SET_PARKINGS_STATUSES',
  SET_ACTIVE_PARKING_SPACE:'SET_ACTIVE_PARKING_SPACE',
  saga: {
    ASYNC_GET_PARKINGS: "ASYNC_GET_PARKINGS",
    ASYNC_GET_PARKIN_DETAILS: "ASYNC_GET_PARKIN_DETAILS",
    ASYNC_GET_PARKING_SPACES: "ASYNC_GET_PARKING_SPACES",
    ASYNC_GET_PARKING_GROUPS: "ASYNC_GET_PARKING_GROUPS",
    ASYNC_GET_PARKINGS_STATUSES: "ASYNC_GET_PARKINGS_STATUSES",
    ASYNC_GET_PARKING_SPACE: "ASYNC_GET_PARKING_SPACE",

    ASYNC_SET_PARKING_SPACE_ENGINEER_MODE: "ASYNC_SET_PARKING_SPACE_ENGINEER_MODE",
    ASYNC_SET_PARKING_SPACE_OFF_MODE: "ASYNC_SET_PARKING_SPACE_OFF_MODE",
    ASYNC_SET_PARKING_SPACE_WORK_MODE: "ASYNC_SET_PARKING_SPACE_WORK_MODE",
    ASYNC_TEST_PARKING_SPACE: "ASYNC_TEST_PARKING_SPACE",
    ASYNC_CLOSE_PARKING_SPACE: "ASYNC_CLOSE_PARKING_SPACE",
    ASYNC_MOVE_STEP_PARKING_SPACE: "ASYNC_MOVE_STEP_PARKING_SPACE",
    ASYNC_OPEN_PARKING_SPACE: "ASYNC_OPEN_PARKING_SPACE",

    ASYNC_GET_PARKING_SPACE_MODBUS_STATUS:"ASYNC_GET_PARKING_SPACE_MODBUS_STATUS",
    ASYNC_SET_PARKING_SPACE_MODBUS_PARAMETER:"ASYNC_SET_PARKING_SPACE_MODBUS_PARAMETER",
  },
};
