import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
@import "assets/Fonts/stylesheet.css";
html{
    height:100%;
    width:100%;
    min-height:100%;

}
body{
    width:100%;
    height:100%;
    min-height:100%;
    background:#0A0A15;
    margin: 0;
        /* display:flex; */
    font-family: "Nunito", Arial, serif;
    position: absolute;
}
.ant-list .ant-list-item .ant-list-item-meta {
background-color:#6363634f;
}

#root{
    height: 100%;
    width:100%;
    min-height:100%;
    /* display:flex; */
}
`;
