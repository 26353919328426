import styled from "styled-components";

export const Body = styled.div`
  position: relative;
  color: #fff;
  box-sizing: margin-box;
  padding: 0px 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  color: #fff;
`;

export const ResultContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 8px;
  /* overflow: auto;
  max-height: calc(100vh - 490px); */
  /* margin-bottom:80px; */
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap:12px;
  color: #fff;
`;
