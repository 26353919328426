/* eslint-disable import/no-anonymous-default-export */
import IState from "../interfaces/Store.Interface";
import appSelector from "./app";
import authSelector from "./auth";
import parkingSelector from "./parking";

export default {
  getRoot: (state: IState) => state,
  app: appSelector,
  auth: authSelector,
  parking: parkingSelector
};
