import React from "react";
import {
  AddressParameter,
  ContentParameter,
  DescriptionContentParameter,
  ParameterBox,
  TitleContentParameter,
  ValueParameter,
} from "./styledComponent";
import { ModbusParameter } from "../../../store/dto/parking.dtos";
import moment from "moment";

interface IParameterModbusItem {
  modbusParameter: ModbusParameter;
  version?: string;
}

const ParameterModbusItem: React.FC<IParameterModbusItem> = (props) => {
  const { modbusParameter, version } = props;

  const getDetails = (address: number): string => {
    if (address === 20) {
      return "Время постоянного присутствия автомобиля для фиксации события, в с";
    } else if (address === 21) {
      return "Время постоянного отсутствия автомобиля для фиксации события, в с";
    } else {
      return modbusParameter.details;
    }
  };
  const getValue = (address: number): string => {
    if (address === 20) {
      if (version) {
        if (parseFloat(version) < 12.0) {
          return (modbusParameter.value / 1000).toString();
        } else {
          return modbusParameter.value.toString();
        }
      } else {
        return modbusParameter.value.toString();
      }
    } else if (address === 21) {
      return (modbusParameter.value / 1000).toString();
    } else if (address === 79) {
      return modbusParameter.stringValue;
    } else {
      return modbusParameter.stringValue;
    }
  };

  return (
    <>
      <ParameterBox>
        <AddressParameter>
          <span>Адрес</span>
          <span>{modbusParameter.address}</span>
        </AddressParameter>

        <ContentParameter>
          <TitleContentParameter>
            {getDetails(modbusParameter.address)}
          </TitleContentParameter>
          <DescriptionContentParameter>
            {moment(modbusParameter?.time).format("DD.MM.YYYY HH:mm")},{" "}
            {modbusParameter.type}
          </DescriptionContentParameter>
        </ContentParameter>

        <ValueParameter>
          <span>Значение</span>
          <span>
            {/* {modbusParameter.stringValue} */}
            {getValue(modbusParameter.address)}
          </span>
        </ValueParameter>
      </ParameterBox>
    </>
  );
};

export default ParameterModbusItem;
