/* Options:
Date: 2023-02-09 04:34:06
Version: 6.00
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://parking.dev.its.msu24.ru

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

// @DataContract
export class GetEntityGroupsRequestBase implements ICustomerItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetEntityGroupsRequestBase>) { (Object as any).assign(this, init); }
}

export interface ICustomerItemRequest extends ICustomerEntity
{
}

export interface ICustomerEntity
{
    // @DataMember
    customerId: string;
}

// @DataContract
export class GetEntityGroupRequestBase implements ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetEntityGroupRequestBase>) { (Object as any).assign(this, init); }
}

export interface IBaseEntityItemRequest
{
    id: string;
}

// @DataContract
export class EntityGroup implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public items: string[];

    // @DataMember
    public customerId: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public name: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<EntityGroup>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CreateEntityGroupRequestBase implements ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public entity: EntityGroup;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateEntityGroupRequestBase>) { (Object as any).assign(this, init); }
}

export interface IDescription
{
    // @DataMember
    description: string;
}

export interface IRequestWithEntity
{
}

// @DataContract
export class UpdateEntityGroupRequestBase implements ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: EntityGroup;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateEntityGroupRequestBase>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DeleteEntityGroupsRequestBase implements ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteEntityGroupsRequestBase>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetEntityGroupSharesRequestBase implements IBaseEntityItemRequest, ICustomerItemRequest
{
    // @DataMember
    public id: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetEntityGroupSharesRequestBase>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EntityGroupPermissions implements IEntityTyped, ICustomerEntityViewModel
{
    // @DataMember
    public customerId: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public entityGroupId: string;

    // @DataMember
    public permissions: string[];

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<EntityGroupPermissions>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UpgradeEntityGroupShareRequestBase implements ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: EntityGroupPermissions;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpgradeEntityGroupShareRequestBase>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PageRequest
{
    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<PageRequest>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EntityEventLogDetails
{
    // @DataMember
    public id: number;

    // @DataMember
    public eventTime: string;

    // @DataMember
    public eventId: number;

    // @DataMember
    public message: string;

    // @DataMember
    public data: string;

    public constructor(init?: Partial<EntityEventLogDetails>) { (Object as any).assign(this, init); }
}

export enum ParkingKioskType
{
    None = 'None',
    PirsKiosk = 'PirsKiosk',
}

export class ParkingKioskInfo implements INamed, ICustomerEntity, ICustomerName, IDeviceItem, IOwnedDeviceItem, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public kioskType: ParkingKioskType;

    // @DataMember
    public name: string;

    // @DataMember
    public customerId: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public ownerObjectName: string;

    // @DataMember
    public ownerObjectId: string;

    // @DataMember
    public prefix: string;

    // @DataMember
    public number: string;

    // @DataMember
    public phone1: string;

    // @DataMember
    public phone2: string;

    // @DataMember
    public ip1: string;

    // @DataMember
    public ip2: string;

    // @DataMember
    public imei: string;

    // @DataMember
    public lat: number;

    // @DataMember
    public lon: number;

    // @DataMember
    public direction: number;

    // @DataMember
    public fullNumber: string;

    // @DataMember
    public softwareVersion: string;

    // @DataMember
    public firmwareVersion: string;

    // @DataMember
    public hardwareVersion: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<ParkingKioskInfo>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ParkingIconStatus
{
    // @DataMember
    public color: string;

    // @DataMember
    public borderColor: string;

    // @DataMember
    public processing: boolean;

    public constructor(init?: Partial<ParkingIconStatus>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ParkingStatusInfo
{
    // @DataMember
    public id: string;

    // @DataMember
    public name: string;

    // @DataMember
    public label: string;

    // @DataMember
    public iconStatus: ParkingIconStatus;

    // @DataMember
    public online: boolean;

    // @DataMember
    public available: boolean;

    public constructor(init?: Partial<ParkingStatusInfo>) { (Object as any).assign(this, init); }
}

export class DeleteCustomerItemsRequest implements ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteCustomerItemsRequest>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ChangeCustomerRequestBase implements IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember
    public description: string;

    // @DataMember
    public newCustomerId: string;

    // @DataMember
    public customerId: string;

    // @DataMember
    public id: string;

    public constructor(init?: Partial<ChangeCustomerRequestBase>) { (Object as any).assign(this, init); }
}

export enum ParkingControllerType
{
    None = 'None',
    MsuPirs = 'MsuPirs',
}

export class ParkingControllerInfo implements ICustomerEntity, ICustomerName, IDeviceItem, IOwnedDeviceItem, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public controllerType: ParkingControllerType;

    // @DataMember
    public customerId: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public ownerObjectName: string;

    // @DataMember
    public ownerObjectId: string;

    // @DataMember
    public prefix: string;

    // @DataMember
    public number: string;

    // @DataMember
    public phone1: string;

    // @DataMember
    public phone2: string;

    // @DataMember
    public ip1: string;

    // @DataMember
    public ip2: string;

    // @DataMember
    public imei: string;

    // @DataMember
    public lat: number;

    // @DataMember
    public lon: number;

    // @DataMember
    public direction: number;

    // @DataMember
    public fullNumber: string;

    // @DataMember
    public softwareVersion: string;

    // @DataMember
    public firmwareVersion: string;

    // @DataMember
    public hardwareVersion: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<ParkingControllerInfo>) { (Object as any).assign(this, init); }
}

export enum ParkingType
{
    Open = 'Open',
    Barrier = 'Barrier',
    ParkingLock = 'ParkingLock',
}

// @DataContract
export class ParkingInfo implements ICoordinate, IDescription, ICustomerEntityViewModel, INamedEntity, IEntity
{
    // @DataMember
    public lat: number;

    // @DataMember
    public lon: number;

    // @DataMember
    public underground: boolean;

    // @DataMember
    public paid: boolean;

    // @DataMember
    public secured: boolean;

    // @DataMember
    public automatic: boolean;

    // @DataMember
    public spaces: number;

    // @DataMember
    public parkingType: ParkingType;

    // @DataMember
    public controllerId?: string;

    // @DataMember
    public controllerNumber: string;

    // @DataMember
    public controllerType: ParkingControllerType;

    // @DataMember
    public description: string;

    // @DataMember
    public customerId: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public name: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<ParkingInfo>) { (Object as any).assign(this, init); }
}

export interface ICustomerIntegrationIdRequest
{
    customerIntegrationId: string;
}

// @DataContract
export class EntityGroupInfo implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public itemsCount: number;

    // @DataMember
    public customerId: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public name: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<EntityGroupInfo>) { (Object as any).assign(this, init); }
}

export interface IChangeKey extends IReadonlyChangeKey
{
    // @DataMember
    changeKey: string;
}

export interface IReadonlyChangeKey
{
    // @DataMember
    changeKey: string;
}

export class CustomerNamedBaseEntityViewModelBase implements ICustomerEntityViewModel, INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public customerId: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public name: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<CustomerNamedBaseEntityViewModelBase>) { (Object as any).assign(this, init); }
}

export interface IEntity
{
}

export interface IEntityTyped
{
    // @DataMember
    entityType: string;
}

export interface IModified
{
    // @DataMember
    modified: string;

    // @DataMember
    modifiedBy: string;
}

export interface INamedEntity extends IEntity, INamed
{
}

export interface INamed
{
    // @DataMember
    name: string;
}

export interface ICustomerEntityViewModel extends ICustomerName, ICustomerEntity
{
}

export interface ICustomerName
{
    // @DataMember
    customerName: string;
}

// @DataContract
export enum EntityEventLogLevel
{
    Trace = 'Trace',
    Debug = 'Debug',
    Info = 'Info',
    Warn = 'Warn',
    Error = 'Error',
    Fatal = 'Fatal',
}

// @DataContract
export class EntityEventLogType
{
    // @DataMember
    public id: number;

    // @DataMember
    public name: string;

    // @DataMember
    public level: EntityEventLogLevel;

    public constructor(init?: Partial<EntityEventLogType>) { (Object as any).assign(this, init); }
}

export class CustomerDeviceViewModelBase implements ICustomerEntity, ICustomerName, IDeviceItem, IOwnedDeviceItem, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public customerId: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public ownerObjectName: string;

    // @DataMember
    public ownerObjectId: string;

    // @DataMember
    public prefix: string;

    // @DataMember
    public number: string;

    // @DataMember
    public phone1: string;

    // @DataMember
    public phone2: string;

    // @DataMember
    public ip1: string;

    // @DataMember
    public ip2: string;

    // @DataMember
    public imei: string;

    // @DataMember
    public deviceSettings: string;

    // @DataMember
    public lat: number;

    // @DataMember
    public lon: number;

    // @DataMember
    public direction: number;

    // @DataMember
    public fullNumber: string;

    // @DataMember
    public softwareVersion: string;

    // @DataMember
    public firmwareVersion: string;

    // @DataMember
    public hardwareVersion: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<CustomerDeviceViewModelBase>) { (Object as any).assign(this, init); }
}

export interface IDeviceItem extends IDeviceVersion
{
    // @DataMember
    prefix: string;

    // @DataMember
    number: string;

    // @DataMember
    phone1: string;

    // @DataMember
    phone2: string;

    // @DataMember
    ip1: string;

    // @DataMember
    ip2: string;

    // @DataMember
    imei: string;

    // @DataMember
    lat: number;

    // @DataMember
    lon: number;

    // @DataMember
    direction: number;
}

export interface IDeviceVersion
{
    // @DataMember
    softwareVersion: string;

    // @DataMember
    firmwareVersion: string;

    // @DataMember
    hardwareVersion: string;
}

export interface IOwnedDeviceItem
{
    // @DataMember
    ownerObjectName: string;

    // @DataMember
    ownerObjectId: string;
}

// @DataContract
export class ParkingKioskIconStatus
{
    // @DataMember
    public color: string;

    // @DataMember
    public borderColor: string;

    // @DataMember
    public processing: boolean;

    public constructor(init?: Partial<ParkingKioskIconStatus>) { (Object as any).assign(this, init); }
}

// @DataContract
export enum PirsKioskError
{
    NoPaper = 'NoPaper',
    NoTerminalConnection = 'NoTerminalConnection',
    NoScreenConnection = 'NoScreenConnection',
}

// @DataContract
export class PirsKioskStatus
{
    // @DataMember
    public errors: PirsKioskError[];

    public constructor(init?: Partial<PirsKioskStatus>) { (Object as any).assign(this, init); }
}

export enum UploadSettingsToParkingControllerResult
{
    Ok = 'Ok',
    Failed = 'Failed',
    OfflineQueued = 'OfflineQueued',
}

export class CustomerNamedEntityViewModelBase implements ICustomerEntityViewModel, INamedEntity, IEntity
{
    // @DataMember
    public customerId: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public name: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<CustomerNamedEntityViewModelBase>) { (Object as any).assign(this, init); }
}

export interface ICoordinate
{
    lat: number;
    lon: number;
}

// @DataContract
export enum ParkingSpaceMode
{
    Off = 'Off',
    Engineer = 'Engineer',
    Work = 'Work',
}

// @DataContract
export class ParkingSpaceIconStatus
{
    // @DataMember
    public color: string;

    // @DataMember
    public borderColor: string;

    // @DataMember
    public processing: boolean;

    public constructor(init?: Partial<ParkingSpaceIconStatus>) { (Object as any).assign(this, init); }
}

// @DataContract
export enum ParkingSpaceState
{
    Opened = 'Opened',
    Closed = 'Closed',
    Unknown = 'Unknown',
}

// @DataContract
export enum ModbusParameterFormat
{
    Bit = 'Bit',
    UInt16 = 'UInt16',
    Int16 = 'Int16',
}

// @DataContract
export class ModbusParameter
{
    // @DataMember(Order=0)
    public time: string;

    // @DataMember(Order=1)
    public type: string;

    // @DataMember(Order=2)
    public address: number;

    // @DataMember(Order=3)
    public value: number;

    // @DataMember(Order=4)
    public details: string;

    // @DataMember(Order=5)
    public stringValue: string;

    // @DataMember(Order=6)
    public valueFormat: ModbusParameterFormat;

    public constructor(init?: Partial<ModbusParameter>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BaseEntityViewModelBase implements IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<BaseEntityViewModelBase>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class CustomerDeviceInfoViewModelBase implements ICustomerEntity, ICustomerName, IDeviceItem, IOwnedDeviceItem, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public customerId: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public ownerObjectName: string;

    // @DataMember
    public ownerObjectId: string;

    // @DataMember
    public prefix: string;

    // @DataMember
    public number: string;

    // @DataMember
    public phone1: string;

    // @DataMember
    public phone2: string;

    // @DataMember
    public ip1: string;

    // @DataMember
    public ip2: string;

    // @DataMember
    public imei: string;

    // @DataMember
    public lat: number;

    // @DataMember
    public lon: number;

    // @DataMember
    public direction: number;

    // @DataMember
    public fullNumber: string;

    // @DataMember
    public softwareVersion: string;

    // @DataMember
    public firmwareVersion: string;

    // @DataMember
    public hardwareVersion: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<CustomerDeviceInfoViewModelBase>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DeleteItemsRequest implements IDescription
{
    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteItemsRequest>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NamedBaseEntityViewModelBase implements INamedEntity, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public name: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<NamedBaseEntityViewModelBase>) { (Object as any).assign(this, init); }
}

export class DeviceViewModelBase implements IDeviceItem, IOwnedDeviceItem, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public ownerObjectName: string;

    // @DataMember
    public ownerObjectId: string;

    // @DataMember
    public prefix: string;

    // @DataMember
    public number: string;

    // @DataMember
    public phone1: string;

    // @DataMember
    public phone2: string;

    // @DataMember
    public ip1: string;

    // @DataMember
    public ip2: string;

    // @DataMember
    public imei: string;

    // @DataMember
    public deviceSettings: string;

    // @DataMember
    public lat: number;

    // @DataMember
    public lon: number;

    // @DataMember
    public direction: number;

    // @DataMember
    public fullNumber: string;

    // @DataMember
    public softwareVersion: string;

    // @DataMember
    public firmwareVersion: string;

    // @DataMember
    public hardwareVersion: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<DeviceViewModelBase>) { (Object as any).assign(this, init); }
}

// @DataContract
export class NamedEntityViewModelBase implements INamedEntity, IEntity
{
    // @DataMember
    public name: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<NamedEntityViewModelBase>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EntityViewModelBase implements IEntity
{
    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<EntityViewModelBase>) { (Object as any).assign(this, init); }
}

export class DeviceInfoViewModelBase implements IDeviceItem, IOwnedDeviceItem, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public ownerObjectName: string;

    // @DataMember
    public ownerObjectId: string;

    // @DataMember
    public prefix: string;

    // @DataMember
    public number: string;

    // @DataMember
    public phone1: string;

    // @DataMember
    public phone2: string;

    // @DataMember
    public ip1: string;

    // @DataMember
    public ip2: string;

    // @DataMember
    public imei: string;

    // @DataMember
    public lat: number;

    // @DataMember
    public lon: number;

    // @DataMember
    public direction: number;

    // @DataMember
    public fullNumber: string;

    // @DataMember
    public softwareVersion: string;

    // @DataMember
    public firmwareVersion: string;

    // @DataMember
    public hardwareVersion: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<DeviceInfoViewModelBase>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PageRequestBase
{
    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<PageRequestBase>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetEntityGroupsResponse implements IChangeKey
{
    // @DataMember
    public items: EntityGroupInfo[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetEntityGroupsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetEntityGroupSharesResponse implements IChangeKey
{
    // @DataMember
    public items: EntityGroupPermissions[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetEntityGroupSharesResponse>) { (Object as any).assign(this, init); }
}

export class GetDeviceEventLogResponse implements IChangeKey
{
    // @DataMember
    public items: EntityEventLogDetails[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetDeviceEventLogResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetParkingKiosksResponse implements IChangeKey
{
    // @DataMember
    public items: ParkingKioskInfo[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetParkingKiosksResponse>) { (Object as any).assign(this, init); }
}

export class ParkingKiosk implements INamed, ICustomerEntity, ICustomerName, IDeviceItem, IOwnedDeviceItem, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public kioskType: ParkingKioskType;

    // @DataMember
    public name: string;

    // @DataMember
    public customerId: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public ownerObjectName: string;

    // @DataMember
    public ownerObjectId: string;

    // @DataMember
    public prefix: string;

    // @DataMember
    public number: string;

    // @DataMember
    public phone1: string;

    // @DataMember
    public phone2: string;

    // @DataMember
    public ip1: string;

    // @DataMember
    public ip2: string;

    // @DataMember
    public imei: string;

    // @DataMember
    public deviceSettings: string;

    // @DataMember
    public lat: number;

    // @DataMember
    public lon: number;

    // @DataMember
    public direction: number;

    // @DataMember
    public fullNumber: string;

    // @DataMember
    public softwareVersion: string;

    // @DataMember
    public firmwareVersion: string;

    // @DataMember
    public hardwareVersion: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<ParkingKiosk>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ParkingKioskStatus
{
    // @DataMember
    public id: string;

    // @DataMember
    public name: string;

    // @DataMember
    public number: string;

    // @DataMember
    public iconStatus: ParkingKioskIconStatus;

    // @DataMember
    public error: boolean;

    // @DataMember
    public online: boolean;

    // @DataMember
    public connectionTime?: string;

    // @DataMember
    public status: PirsKioskStatus;

    public constructor(init?: Partial<ParkingKioskStatus>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetParkingsStatusesResponse implements IChangeKey
{
    // @DataMember
    public items: ParkingStatusInfo[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetParkingsStatusesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UploadSettingsToParkingControllerResponse
{
    // @DataMember
    public result: UploadSettingsToParkingControllerResult;

    // @DataMember
    public error: string;

    public constructor(init?: Partial<UploadSettingsToParkingControllerResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetParkingControllersResponse implements IChangeKey
{
    // @DataMember
    public items: ParkingControllerInfo[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetParkingControllersResponse>) { (Object as any).assign(this, init); }
}

export class ParkingController implements ICustomerEntity, ICustomerName, IDeviceItem, IOwnedDeviceItem, IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public controllerType: ParkingControllerType;

    // @DataMember
    public customerId: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public ownerObjectName: string;

    // @DataMember
    public ownerObjectId: string;

    // @DataMember
    public prefix: string;

    // @DataMember
    public number: string;

    // @DataMember
    public phone1: string;

    // @DataMember
    public phone2: string;

    // @DataMember
    public ip1: string;

    // @DataMember
    public ip2: string;

    // @DataMember
    public imei: string;

    // @DataMember
    public deviceSettings: string;

    // @DataMember
    public lat: number;

    // @DataMember
    public lon: number;

    // @DataMember
    public direction: number;

    // @DataMember
    public fullNumber: string;

    // @DataMember
    public softwareVersion: string;

    // @DataMember
    public firmwareVersion: string;

    // @DataMember
    public hardwareVersion: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<ParkingController>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SessionInfo
{
    // @DataMember
    public id: string;

    // @DataMember
    public lastReceived: string;

    // @DataMember
    public lastSent: string;

    // @DataMember
    public deviceNumber: string;

    // @DataMember
    public sessionId: string;

    // @DataMember
    public connectionTime: string;

    // @DataMember
    public receiverHost: string;

    // @DataMember
    public remoteAddress: string;

    // @DataMember
    public connected: boolean;

    // @DataMember
    public lastUpdated: string;

    // @DataMember
    public entityType: string;

    // @DataMember
    public nextCheckSession: string;

    public constructor(init?: Partial<SessionInfo>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetParkingsResponse implements IChangeKey
{
    // @DataMember
    public items: ParkingInfo[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetParkingsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class Parking implements ICoordinate, IDescription, ICustomerEntityViewModel, INamedEntity, IEntity
{
    // @DataMember
    public lat: number;

    // @DataMember
    public lon: number;

    // @DataMember
    public underground: boolean;

    // @DataMember
    public paid: boolean;

    // @DataMember
    public secured: boolean;

    // @DataMember
    public automatic: boolean;

    // @DataMember
    public spaces: number;

    // @DataMember
    public parkingType: ParkingType;

    // @DataMember
    public geoJson: string;

    // @DataMember
    public controllerId?: string;

    // @DataMember
    public controllerType: ParkingControllerType;

    // @DataMember
    public controller: ParkingController;

    // @DataMember
    public description: string;

    // @DataMember
    public customerId: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public name: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<Parking>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ParkingSpaceStatus
{
    // @DataMember
    public id: string;

    // @DataMember
    public parkingId: string;

    // @DataMember
    public parkingName: string;

    // @DataMember
    public name: string;

    // @DataMember
    public fullName: string;

    // @DataMember
    public online: boolean;

    // @DataMember
    public available: boolean;

    // @DataMember
    public mode: ParkingSpaceMode;

    // @DataMember
    public iconStatus: ParkingSpaceIconStatus;

    // @DataMember
    public controllerId?: string;

    // @DataMember
    public state: ParkingSpaceState;

    // @DataMember
    public positionError: boolean;

    // @DataMember
    public positionDescription: string;

    // @DataMember
    public error: boolean;

    // @DataMember
    public statusDescription: string;

    // @DataMember
    public carDetected: boolean;

    // @DataMember
    public onlineTime?: string;

    // @DataMember
    public statusTime: string;

    public constructor(init?: Partial<ParkingSpaceStatus>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ParkingStatus
{
    // @DataMember
    public id: string;

    // @DataMember
    public name: string;

    // @DataMember
    public label: string;

    // @DataMember
    public iconStatus: ParkingIconStatus;

    // @DataMember
    public spaces: ParkingSpaceStatus[];

    // @DataMember
    public controllerId?: string;

    // @DataMember
    public error: boolean;

    // @DataMember
    public online: boolean;

    // @DataMember
    public connectionTime?: string;

    // @DataMember
    public available: boolean;

    public constructor(init?: Partial<ParkingStatus>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetParkingsFullStatusesResponse implements IChangeKey
{
    // @DataMember
    public items: ParkingStatus[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetParkingsFullStatusesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ParkingSpaceStatistic
{
    // @DataMember
    public total: number;

    // @DataMember
    public available: number;

    // @DataMember
    public maintenance: number;

    public constructor(init?: Partial<ParkingSpaceStatistic>) { (Object as any).assign(this, init); }
}

export class ParkingSpace implements IDescription, ICustomerEntityViewModel, INamedEntity, IEntity
{
    // @DataMember
    public parkingName: string;

    // @DataMember
    public parkingId: string;

    // @DataMember
    public lon?: number;

    // @DataMember
    public lat?: number;

    // @DataMember
    public fullName: string;

    // @DataMember
    public controllerNumber: string;

    // @DataMember
    public controllerId?: string;

    // @DataMember
    public description: string;

    // @DataMember
    public customerId: string;

    // @DataMember
    public customerName: string;

    // @DataMember
    public name: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<ParkingSpace>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetParkingSpacesResponse implements IChangeKey
{
    // @DataMember
    public items: ParkingSpace[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetParkingSpacesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ParkingSpaceModbusStatus
{
    // @DataMember
    public parameters: ModbusParameter[];

    public constructor(init?: Partial<ParkingSpaceModbusStatus>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EntityIntegration implements IChangeKey, IDescription, IModified, IEntity
{
    // @DataMember
    public entityId: string;

    // @DataMember
    public customerIntegrationId: string;

    // @DataMember
    public externalId: string;

    // @DataMember
    public settings: string;

    // @DataMember
    public integrationId: string;

    // @DataMember
    public integrationName: string;

    // @DataMember
    public integrationType: string;

    // @DataMember
    public description: string;

    // @DataMember
    public changeKey: string;

    // @DataMember
    public created: string;

    // @DataMember
    public createdBy: string;

    // @DataMember
    public modified: string;

    // @DataMember
    public modifiedBy: string;

    // @DataMember
    public id: string;

    // @DataMember
    public entityType: string;

    public constructor(init?: Partial<EntityIntegration>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetEntityIntegrationResponse implements IChangeKey
{
    // @DataMember
    public items: EntityIntegration[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetEntityIntegrationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetEntitiesResponse implements IChangeKey
{
    // @DataMember
    public items: Object[];

    // @DataMember
    public total: number;

    // @DataMember
    public page: number;

    // @DataMember
    public itemsPerPage: number;

    // @DataMember
    public changeKey: string;

    public constructor(init?: Partial<GetEntitiesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @Route("/customer/{customerId}/parkingKiosks/groups", "GET")
// @DataContract
export class GetParkingKioskGroupsRequest implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetParkingKioskGroupsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEntityGroupsResponse(); }
    public getTypeName() { return 'GetParkingKioskGroupsRequest'; }
}

// @Route("/customer/{customerId}/parkingKiosks/groups/{id}", "GET")
// @DataContract
export class GetParkingKioskGroupRequest implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetParkingKioskGroupRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new EntityGroup(); }
    public getTypeName() { return 'GetParkingKioskGroupRequest'; }
}

// @Route("/customer/{customerId}/parkingKiosks/groups", "POST")
// @DataContract
export class CreateParkingKioskGroupRequest implements IReturn<EntityGroup>, ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public entity: EntityGroup;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateParkingKioskGroupRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new EntityGroup(); }
    public getTypeName() { return 'CreateParkingKioskGroupRequest'; }
}

// @Route("/customer/{customerId}/parkingKiosks/groups/{id}", "PUT,POST")
// @DataContract
export class UpdateParkingKioskGroupRequest implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: EntityGroup;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateParkingKioskGroupRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new EntityGroup(); }
    public getTypeName() { return 'UpdateParkingKioskGroupRequest'; }
}

// @Route("/customer/{customerId}/parkingKiosks/groups", "DELETE")
// @DataContract
export class DeleteParkingKioskGroupsRequest implements IReturn<number>, ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteParkingKioskGroupsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return 0; }
    public getTypeName() { return 'DeleteParkingKioskGroupsRequest'; }
}

// @Route("/customer/{customerId}/parkingKiosks/groups/{id}", "GET")
// @DataContract
export class GetParkingKioskGroupSharesRequest implements IReturn<GetEntityGroupSharesResponse>, IBaseEntityItemRequest, ICustomerItemRequest
{
    // @DataMember
    public id: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetParkingKioskGroupSharesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEntityGroupSharesResponse(); }
    public getTypeName() { return 'GetParkingKioskGroupSharesRequest'; }
}

// @Route("/customer/{customerId}/parkingKiosks/groups/{id}", "PUT")
// @DataContract
export class UpdateParkingKioskGroupShareRequest implements IReturn<EntityGroupPermissions>, ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: EntityGroupPermissions;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateParkingKioskGroupShareRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new EntityGroupPermissions(); }
    public getTypeName() { return 'UpdateParkingKioskGroupShareRequest'; }
}

// @Route("/customer/{customerId}/parkingKiosk/{id}/eventTypes", "GET")
// @DataContract
export class GetParkingKioskEventTypesRequest implements IReturn<EntityEventLogType[]>, IBaseEntityItemRequest
{
    // @DataMember
    public id: string;

    public constructor(init?: Partial<GetParkingKioskEventTypesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<EntityEventLogType>(); }
    public getTypeName() { return 'GetParkingKioskEventTypesRequest'; }
}

// @Route("/customer/{customerId}/parkingKiosk/{id}/eventLog", "GET")
// @DataContract
export class GetParkingKioskEventLogRequest implements IReturn<GetDeviceEventLogResponse>, IBaseEntityItemRequest, ICustomerItemRequest
{
    // @DataMember
    public id: string;

    // @DataMember
    public start?: string;

    // @DataMember
    public end?: string;

    // @DataMember
    public filterEvents: number[];

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetParkingKioskEventLogRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetDeviceEventLogResponse(); }
    public getTypeName() { return 'GetParkingKioskEventLogRequest'; }
}

// @Route("/parkingKiosk/exist", "GET")
// @DataContract
export class GetParkingKioskExistRequest implements IReturn<boolean>
{
    // @DataMember(IsRequired=true)
    public type: ParkingKioskType;

    // @DataMember(IsRequired=true)
    public number: string;

    public constructor(init?: Partial<GetParkingKioskExistRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'GetParkingKioskExistRequest'; }
}

// @Route("/customer/{customerId}/parkingKiosks", "GET")
// @DataContract
export class GetParkingKiosksRequest implements IReturn<GetParkingKiosksResponse>, ICustomerItemRequest
{
    // @DataMember
    public onlyUsed: boolean;

    // @DataMember
    public onlyFree: boolean;

    // @DataMember
    public kioskType: ParkingKioskType;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetParkingKiosksRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetParkingKiosksResponse(); }
    public getTypeName() { return 'GetParkingKiosksRequest'; }
}

// @Route("/customer/{customerId}/parkingKiosk/{id}", "GET")
// @DataContract
export class GetParkingKioskRequest implements IReturn<ParkingKiosk>, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetParkingKioskRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingKiosk(); }
    public getTypeName() { return 'GetParkingKioskRequest'; }
}

// @Route("/customer/{customerId}/parkingKiosk/{id}", "PUT")
// @DataContract
export class UpdateParkingKioskRequest implements IReturn<ParkingKiosk>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: ParkingKiosk;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateParkingKioskRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingKiosk(); }
    public getTypeName() { return 'UpdateParkingKioskRequest'; }
}

// @Route("/customer/{customerId}/parkingKiosk/{id}/status", "GET")
// @DataContract
export class GetParkingKioskStatusRequest implements IReturn<ParkingKioskStatus>, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember
    public id: string;

    // @DataMember
    public customerId: string;

    public constructor(init?: Partial<GetParkingKioskStatusRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingKioskStatus(); }
    public getTypeName() { return 'GetParkingKioskStatusRequest'; }
}

// @Route("/customer/{customerId}/parkingKiosks/statuses", "GET")
// @DataContract
export class GetParkingKiosksStatusesRequest implements IReturn<GetParkingsStatusesResponse>, ICustomerItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetParkingKiosksStatusesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetParkingsStatusesResponse(); }
    public getTypeName() { return 'GetParkingKiosksStatusesRequest'; }
}

// @Route("/customer/{customerId}/parkingKiosk", "POST")
// @DataContract
export class CreateParkingKioskRequest implements IReturn<ParkingKiosk>, ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public entity: ParkingKiosk;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateParkingKioskRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingKiosk(); }
    public getTypeName() { return 'CreateParkingKioskRequest'; }
}

// @Route("/customer/{customerId}/parkingKiosks", "DELETE")
// @DataContract
export class DeleteParkingKiosksRequest implements IReturn<number>, ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteParkingKiosksRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return 0; }
    public getTypeName() { return 'DeleteParkingKiosksRequest'; }
}

// @Route("/customer/{customerId}/parkingKiosk/{id}/changeCustomer", "POST")
// @DataContract
export class ParkingKioskChangeCustomerRequest implements IReturn<boolean>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember
    public description: string;

    // @DataMember
    public newCustomerId: string;

    // @DataMember
    public customerId: string;

    // @DataMember
    public id: string;

    public constructor(init?: Partial<ParkingKioskChangeCustomerRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'ParkingKioskChangeCustomerRequest'; }
}

// @Route("/customer/{customerId}/parkings/groups", "GET")
// @DataContract
export class GetParkingGroupsRequest implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetParkingGroupsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEntityGroupsResponse(); }
    public getTypeName() { return 'GetParkingGroupsRequest'; }
}

// @Route("/customer/{customerId}/parkings/groups/{id}", "GET")
// @DataContract
export class GetParkingGroupRequest implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetParkingGroupRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new EntityGroup(); }
    public getTypeName() { return 'GetParkingGroupRequest'; }
}

// @Route("/customer/{customerId}/parkings/groups", "POST")
// @DataContract
export class CreateParkingGroupRequest implements IReturn<EntityGroup>, ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public entity: EntityGroup;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateParkingGroupRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new EntityGroup(); }
    public getTypeName() { return 'CreateParkingGroupRequest'; }
}

// @Route("/customer/{customerId}/parkings/groups/{id}", "PUT,POST")
// @DataContract
export class UpdateParkingGroupRequest implements IReturn<EntityGroup>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: EntityGroup;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateParkingGroupRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new EntityGroup(); }
    public getTypeName() { return 'UpdateParkingGroupRequest'; }
}

// @Route("/customer/{customerId}/parkings/groups", "DELETE")
// @DataContract
export class DeleteParkingGroupsRequest implements IReturn<number>, ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteParkingGroupsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return 0; }
    public getTypeName() { return 'DeleteParkingGroupsRequest'; }
}

// @Route("/customer/{customerId}/parkings/groups/{id}", "GET")
// @DataContract
export class GetParkingGroupSharesRequest implements IReturn<GetEntityGroupSharesResponse>, IBaseEntityItemRequest, ICustomerItemRequest
{
    // @DataMember
    public id: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetParkingGroupSharesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEntityGroupSharesResponse(); }
    public getTypeName() { return 'GetParkingGroupSharesRequest'; }
}

// @Route("/customer/{customerId}/parkings/groups/{id}", "PUT")
// @DataContract
export class UpdateParkingGroupShareRequest implements IReturn<EntityGroupPermissions>, ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: EntityGroupPermissions;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateParkingGroupShareRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new EntityGroupPermissions(); }
    public getTypeName() { return 'UpdateParkingGroupShareRequest'; }
}

// @Route("/customer/{customerId}/parkingController/{id}/uploadSettings", "POST")
// @DataContract
export class UploadSettingsToParkingControllerRequest implements IReturn<UploadSettingsToParkingControllerResponse>, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<UploadSettingsToParkingControllerRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new UploadSettingsToParkingControllerResponse(); }
    public getTypeName() { return 'UploadSettingsToParkingControllerRequest'; }
}

// @Route("/customer/{customerId}/parkingController/{id}/eventTypes", "GET")
// @DataContract
export class GetParkingControllerEventTypesRequest implements IReturn<EntityEventLogType[]>, IBaseEntityItemRequest
{
    // @DataMember
    public id: string;

    public constructor(init?: Partial<GetParkingControllerEventTypesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Array<EntityEventLogType>(); }
    public getTypeName() { return 'GetParkingControllerEventTypesRequest'; }
}

// @Route("/customer/{customerId}/parkingController/{id}/eventLog", "GET")
// @DataContract
export class GetParkingControllerEventLogRequest implements IReturn<GetDeviceEventLogResponse>, IBaseEntityItemRequest, ICustomerItemRequest
{
    // @DataMember
    public id: string;

    // @DataMember
    public start?: string;

    // @DataMember
    public end?: string;

    // @DataMember
    public filterEvents: number[];

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetParkingControllerEventLogRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetDeviceEventLogResponse(); }
    public getTypeName() { return 'GetParkingControllerEventLogRequest'; }
}

// @Route("/parkingController/exist", "GET")
// @DataContract
export class GetParkingControllerExistRequest implements IReturn<boolean>
{
    // @DataMember(IsRequired=true)
    public type: ParkingControllerType;

    // @DataMember(IsRequired=true)
    public number: string;

    public constructor(init?: Partial<GetParkingControllerExistRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'GetParkingControllerExistRequest'; }
}

// @Route("/customer/{customerId}/parkingControllers", "GET")
// @DataContract
export class GetParkingControllersRequest implements IReturn<GetParkingControllersResponse>, ICustomerItemRequest
{
    // @DataMember
    public onlyUsed: boolean;

    // @DataMember
    public onlyFree: boolean;

    // @DataMember
    public controllerType: ParkingControllerType;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetParkingControllersRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetParkingControllersResponse(); }
    public getTypeName() { return 'GetParkingControllersRequest'; }
}

// @Route("/customer/{customerId}/parkingController/{id}", "GET")
// @DataContract
export class GetParkingControllerRequest implements IReturn<ParkingController>, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetParkingControllerRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingController(); }
    public getTypeName() { return 'GetParkingControllerRequest'; }
}

// @Route("/customer/{customerId}/parkingController/{id}", "PUT")
// @DataContract
export class UpdateParkingControllerRequest implements IReturn<ParkingController>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: ParkingController;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateParkingControllerRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingController(); }
    public getTypeName() { return 'UpdateParkingControllerRequest'; }
}

// @Route("/customer/{customerId}/parkingController", "POST")
// @DataContract
export class CreateParkingControllerRequest implements IReturn<ParkingController>, ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public entity: ParkingController;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateParkingControllerRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingController(); }
    public getTypeName() { return 'CreateParkingControllerRequest'; }
}

// @Route("/customer/{customerId}/parkingControllers", "DELETE")
// @DataContract
export class DeleteParkingControllersRequest implements IReturn<number>, ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteParkingControllersRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return 0; }
    public getTypeName() { return 'DeleteParkingControllersRequest'; }
}

// @Route("/customer/{customerId}/parkingController/{id}/disconnect", "POST")
// @DataContract
export class DisconnectParkingControllerRequest implements IReturn<SessionInfo>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<DisconnectParkingControllerRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new SessionInfo(); }
    public getTypeName() { return 'DisconnectParkingControllerRequest'; }
}

// @Route("/customer/{customerId}/parkingController/{id}/changeCustomer", "POST")
// @DataContract
export class ParkingControllerChangeCustomerRequest implements IReturn<boolean>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember
    public description: string;

    // @DataMember
    public newCustomerId: string;

    // @DataMember
    public customerId: string;

    // @DataMember
    public id: string;

    public constructor(init?: Partial<ParkingControllerChangeCustomerRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'ParkingControllerChangeCustomerRequest'; }
}

// @Route("/customer/{customerId}/parkings", "GET")
// @DataContract
export class GetParkingsRequest implements IReturn<GetParkingsResponse>, ICustomerItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetParkingsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetParkingsResponse(); }
    public getTypeName() { return 'GetParkingsRequest'; }
}

// @Route("/customer/{customerId}/parking/exist", "GET")
// @DataContract
export class GetParkingExistRequest implements IReturn<boolean>, ICustomerItemRequest
{
    // @DataMember(IsRequired=true)
    public name: string;

    // @DataMember
    public customerId: string;

    public constructor(init?: Partial<GetParkingExistRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'GetParkingExistRequest'; }
}

// @Route("/customer/{customerId}/parking/{id}", "GET")
// @DataContract
export class GetParkingRequest implements IReturn<Parking>, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetParkingRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Parking(); }
    public getTypeName() { return 'GetParkingRequest'; }
}

// @Route("/customer/{customerId}/parking/{id}/detachController", "POST")
// @DataContract
export class DetachParkingControllerRequest implements IReturn<Parking>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<DetachParkingControllerRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Parking(); }
    public getTypeName() { return 'DetachParkingControllerRequest'; }
}

// @Route("/customer/{customerId}/parking/{id}/setController", "PUT")
// @DataContract
export class SetParkingControllerRequest implements IReturn<ParkingStatus>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
    // @DataMember
    public id: string;

    // @DataMember
    public customerId: string;

    // @DataMember
    public newControllerId: string;

    // @DataMember
    public uploadSettingsToController: boolean;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<SetParkingControllerRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingStatus(); }
    public getTypeName() { return 'SetParkingControllerRequest'; }
}

// @Route("/customer/{customerId}/parking/{id}/status", "GET")
// @DataContract
export class GetParkingStatusRequest implements IReturn<ParkingStatus>, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember
    public id: string;

    // @DataMember
    public customerId: string;

    public constructor(init?: Partial<GetParkingStatusRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingStatus(); }
    public getTypeName() { return 'GetParkingStatusRequest'; }
}

// @Route("/customer/{customerId}/parkings/statuses", "GET")
// @DataContract
export class GetParkingsStatusesRequest implements IReturn<GetParkingsStatusesResponse>, ICustomerItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetParkingsStatusesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetParkingsStatusesResponse(); }
    public getTypeName() { return 'GetParkingsStatusesRequest'; }
}

// @Route("/customer/{customerId}/parkings/fullStatuses", "GET")
// @DataContract
export class GetParkingsFullStatusesRequest implements IReturn<GetParkingsFullStatusesResponse>, ICustomerItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetParkingsFullStatusesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetParkingsFullStatusesResponse(); }
    public getTypeName() { return 'GetParkingsFullStatusesRequest'; }
}

// @Route("/customer/{customerId}/parking/{id}", "PUT")
// @DataContract
export class UpdateParkingRequest implements IReturn<Parking>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: Parking;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateParkingRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Parking(); }
    public getTypeName() { return 'UpdateParkingRequest'; }
}

// @Route("/customer/{customerId}/parking", "POST")
// @DataContract
export class CreateParkingRequest implements IReturn<Parking>, ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public entity: Parking;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateParkingRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new Parking(); }
    public getTypeName() { return 'CreateParkingRequest'; }
}

// @Route("/customer/{customerId}/parkings", "DELETE")
// @DataContract
export class DeleteParkingsRequest implements IReturn<number>, ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteParkingsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return 0; }
    public getTypeName() { return 'DeleteParkingsRequest'; }
}

// @Route("/customer/{customerId}/parkingSpaces/exist", "GET")
// @DataContract
export class GetParkingSpacesExistRequest implements IReturn<boolean>, ICustomerItemRequest
{
    // @DataMember(IsRequired=true)
    public parkingId: string;

    // @DataMember(IsRequired=true)
    public name: string;

    // @DataMember
    public customerId: string;

    public constructor(init?: Partial<GetParkingSpacesExistRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return false; }
    public getTypeName() { return 'GetParkingSpacesExistRequest'; }
}

// @Route("/customer/{customerId}/parkingSpaces/stat", "GET")
// @DataContract
export class GetParkingSpacesStatRequest implements IReturn<ParkingSpaceStatistic>, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetParkingSpacesStatRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingSpaceStatistic(); }
    public getTypeName() { return 'GetParkingSpacesStatRequest'; }
}

// @Route("/customer/{customerId}/parkingSpaces", "GET")
// @DataContract
export class GetParkingSpacesRequest implements IReturn<GetParkingSpacesResponse>, ICustomerItemRequest
{
    // @DataMember
    public parkingId?: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetParkingSpacesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetParkingSpacesResponse(); }
    public getTypeName() { return 'GetParkingSpacesRequest'; }
}

// @Route("/customer/{customerId}/parkingSpace/{id}/setEngineerMode", "POST")
// @DataContract
export class SetParkingSpaceEngineerModeRequest implements IReturn<ParkingSpaceStatus>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<SetParkingSpaceEngineerModeRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingSpaceStatus(); }
    public getTypeName() { return 'SetParkingSpaceEngineerModeRequest'; }
}

// @Route("/customer/{customerId}/parkingSpace/{id}/setOffMode", "POST")
// @DataContract
export class SetParkingSpaceOffModeRequest implements IReturn<ParkingSpaceStatus>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<SetParkingSpaceOffModeRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingSpaceStatus(); }
    public getTypeName() { return 'SetParkingSpaceOffModeRequest'; }
}

// @Route("/customer/{customerId}/parkingSpace/{id}/test", "POST")
// @DataContract
export class TestParkingSpaceRequest implements IReturn<ParkingSpaceStatus>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<TestParkingSpaceRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingSpaceStatus(); }
    public getTypeName() { return 'TestParkingSpaceRequest'; }
}

// @Route("/customer/{customerId}/parkingSpace/{id}/setParameter", "POST")
// @DataContract
export class SetParameterParkingSpaceRequest implements IReturn<ParkingSpaceStatus>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember
    public address: number;

    // @DataMember
    public value: number;

    // @DataMember
    public stringValue: string;

    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<SetParameterParkingSpaceRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingSpaceStatus(); }
    public getTypeName() { return 'SetParameterParkingSpaceRequest'; }
}

// @Route("/customer/{customerId}/parkingSpace/{id}/setWorkMode", "POST")
// @DataContract
export class SetParkingSpaceWorkModeRequest implements IReturn<ParkingSpaceStatus>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<SetParkingSpaceWorkModeRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingSpaceStatus(); }
    public getTypeName() { return 'SetParkingSpaceWorkModeRequest'; }
}

// @Route("/customer/{customerId}/parkingSpace/{id}/reboot", "POST")
// @DataContract
export class RebootParkingSpaceRequest implements IReturn<ParkingSpaceStatus>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<RebootParkingSpaceRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingSpaceStatus(); }
    public getTypeName() { return 'RebootParkingSpaceRequest'; }
}

// @Route("/customer/{customerId}/parkingSpace/{id}/moveStep", "POST")
// @DataContract
export class MoveStepParkingSpaceRequest implements IReturn<ParkingSpaceStatus>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember
    public description: string;

    // @DataMember
    public open: boolean;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<MoveStepParkingSpaceRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingSpaceStatus(); }
    public getTypeName() { return 'MoveStepParkingSpaceRequest'; }
}

// @Route("/customer/{customerId}/parkingSpace/{id}/open", "POST")
// @DataContract
export class OpenParkingSpaceRequest implements IReturn<ParkingSpaceStatus>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember
    public description: string;

    // @DataMember
    public force: boolean;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<OpenParkingSpaceRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingSpaceStatus(); }
    public getTypeName() { return 'OpenParkingSpaceRequest'; }
}

// @Route("/customer/{customerId}/parkingSpace/{id}/close", "POST")
// @DataContract
export class CloseParkingSpaceRequest implements IReturn<ParkingSpaceStatus>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember
    public description: string;

    // @DataMember
    public force: boolean;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<CloseParkingSpaceRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingSpaceStatus(); }
    public getTypeName() { return 'CloseParkingSpaceRequest'; }
}

// @Route("/customer/{customerId}/parkingSpace/{id}/setModbusParameter", "POST")
// @DataContract
export class SetParkingSpaceModbusParameterRequest implements IReturn<ParkingSpaceModbusStatus>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember
    public address: number;

    // @DataMember
    public value: string;

    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<SetParkingSpaceModbusParameterRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingSpaceModbusStatus(); }
    public getTypeName() { return 'SetParkingSpaceModbusParameterRequest'; }
}

// @Route("/customer/{customerId}/parkingSpace/{id}/query", "POST")
// @DataContract
export class QueryParkingSpaceRequest implements IReturn<ParkingSpaceStatus>, IDescription, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<QueryParkingSpaceRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingSpaceStatus(); }
    public getTypeName() { return 'QueryParkingSpaceRequest'; }
}

// @Route("/customer/{customerId}/parkingSpace/{id}/status", "GET")
// @DataContract
export class GetParkingSpaceStatusRequest implements IReturn<ParkingSpaceStatus>, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetParkingSpaceStatusRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingSpaceStatus(); }
    public getTypeName() { return 'GetParkingSpaceStatusRequest'; }
}

// @Route("/customer/{customerId}/parkingSpace/{id}/status/modbus", "GET")
// @DataContract
export class GetParkingSpaceModbusStatusRequest implements IReturn<ParkingSpaceModbusStatus>, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetParkingSpaceModbusStatusRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingSpaceModbusStatus(); }
    public getTypeName() { return 'GetParkingSpaceModbusStatusRequest'; }
}

// @Route("/customer/{customerId}/parkingSpace/{id}", "GET")
// @DataContract
export class GetParkingSpaceRequest implements IReturn<ParkingSpace>, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetParkingSpaceRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingSpace(); }
    public getTypeName() { return 'GetParkingSpaceRequest'; }
}

// @Route("/customer/{customerId}/parkingSpace", "POST")
// @DataContract
export class CreateParkingSpaceRequest implements IReturn<ParkingSpace>, ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public entity: ParkingSpace;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateParkingSpaceRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingSpace(); }
    public getTypeName() { return 'CreateParkingSpaceRequest'; }
}

// @Route("/customer/{customerId}/parkingSpace/{id}", "PUT")
// @DataContract
export class UpdateParkingSpaceRequest implements IReturn<ParkingSpace>, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: ParkingSpace;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateParkingSpaceRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new ParkingSpace(); }
    public getTypeName() { return 'UpdateParkingSpaceRequest'; }
}

// @Route("/customer/{customerId}/parkingSpaces", "DELETE")
// @DataContract
export class DeleteParkingSpacesRequest implements IReturn<number>, ICustomerItemRequest, IDescription
{
    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public description: string;

    // @DataMember(IsRequired=true)
    public ids: string[];

    public constructor(init?: Partial<DeleteParkingSpacesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return 0; }
    public getTypeName() { return 'DeleteParkingSpacesRequest'; }
}

// @Route("/healthStatus", "GET")
// @DataContract
export class GetHealthStatusRequest implements IReturn<string>
{

    public constructor(init?: Partial<GetHealthStatusRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return ''; }
    public getTypeName() { return 'GetHealthStatusRequest'; }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "GET")
// @DataContract
export class GetEntityIntegrationsRequest implements IReturn<GetEntityIntegrationResponse>, ICustomerIntegrationIdRequest, ICustomerItemRequest
{
    // @DataMember
    public customerIntegrationId: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetEntityIntegrationsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEntityIntegrationResponse(); }
    public getTypeName() { return 'GetEntityIntegrationsRequest'; }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "GET")
// @DataContract
export class GetEntityIntegrationRequest implements IReturn<EntityIntegration>, ICustomerIntegrationIdRequest, ICustomerItemRequest, IBaseEntityItemRequest
{
    // @DataMember
    public customerIntegrationId: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetEntityIntegrationRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new EntityIntegration(); }
    public getTypeName() { return 'GetEntityIntegrationRequest'; }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entities", "POST")
// @DataContract
export class CreateEntityIntegrationRequest implements IReturn<EntityIntegration>, ICustomerIntegrationIdRequest, ICustomerItemRequest, IDescription
{
    // @DataMember
    public customerIntegrationId: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public entity: EntityIntegration;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<CreateEntityIntegrationRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new EntityIntegration(); }
    public getTypeName() { return 'CreateEntityIntegrationRequest'; }
}

// @Route("/customer/{customerId}/integration/{customerIntegrationId}/entity/{id}", "PUT")
// @DataContract
export class UpdateEntityIntegrationRequest implements IReturn<EntityIntegration>, ICustomerIntegrationIdRequest, ICustomerItemRequest, IBaseEntityItemRequest, IDescription
{
    // @DataMember
    public customerIntegrationId: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public updateEntityGroups: boolean;

    // @DataMember
    public entityGroups: string[];

    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember(IsRequired=true)
    public entity: EntityIntegration;

    // @DataMember
    public description: string;

    public constructor(init?: Partial<UpdateEntityIntegrationRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new EntityIntegration(); }
    public getTypeName() { return 'UpdateEntityIntegrationRequest'; }
}

// @Route("/entity/{id}/properties", "GET")
// @DataContract
export class GetEntityPropertiesRequest implements IReturn<any>, IBaseEntityItemRequest
{
    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetEntityPropertiesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return {}; }
    public getTypeName() { return 'GetEntityPropertiesRequest'; }
}

// @Route("/entity/{id}/properties", "PUT")
// @DataContract
export class UpdateEntityPropertiesRequest implements IReturn<any>
{
    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember
    public description: string;

    // @DataMember
    public entity: { [index: string]: string; };

    public constructor(init?: Partial<UpdateEntityPropertiesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return {}; }
    public getTypeName() { return 'UpdateEntityPropertiesRequest'; }
}

// @Route("/entity/{id}/property/{property}", "GET")
// @DataContract
export class GetEntityPropertyRequest implements IReturn<string>
{
    // @DataMember
    public property: string;

    // @DataMember(IsRequired=true)
    public id: string;

    public constructor(init?: Partial<GetEntityPropertyRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return ''; }
    public getTypeName() { return 'GetEntityPropertyRequest'; }
}

// @Route("/entity/{id}/property/{property}", "PUT")
// @DataContract
export class UpdateEntityPropertyRequest implements IReturn<string>
{
    // @DataMember(IsRequired=true)
    public id: string;

    // @DataMember
    public description: string;

    // @DataMember
    public property: string;

    // @DataMember
    public value: string;

    public constructor(init?: Partial<UpdateEntityPropertyRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return ''; }
    public getTypeName() { return 'UpdateEntityPropertyRequest'; }
}

// @Route("/entities/property/{property}", "GET")
// @DataContract
export class GetEntitiesPropertyRequest implements IReturn<any>
{
    // @DataMember
    public property: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetEntitiesPropertyRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return {}; }
    public getTypeName() { return 'GetEntitiesPropertyRequest'; }
}

// @Route("/customer/{customerId}/entity/{Id}/groups", "GET")
// @DataContract
export class GetItemEntityGroupsRequest implements IReturn<GetEntityGroupsResponse>, ICustomerItemRequest
{
    // @DataMember
    public id: string;

    // @DataMember(IsRequired=true)
    public customerId: string;

    // @DataMember
    public entityGroups: string[];

    // @DataMember
    public itemsIds: string[];

    // @DataMember
    public onlyOwnedItems: boolean;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetItemEntityGroupsRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEntityGroupsResponse(); }
    public getTypeName() { return 'GetItemEntityGroupsRequest'; }
}

// @Route("/entities/remoteRepository/{repositoryId}/data", "GET")
// @DataContract
export class GetEntitiesRequest implements IReturn<GetEntitiesResponse>
{
    // @DataMember
    public repositoryId: string;

    // @DataMember
    public modified?: string;

    // @DataMember
    public page: number;

    // @DataMember
    public items: number;

    // @DataMember
    public sort: string;

    // @DataMember
    public filter: string;

    public constructor(init?: Partial<GetEntitiesRequest>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetEntitiesResponse(); }
    public getTypeName() { return 'GetEntitiesRequest'; }
}

/**
* Sign In
*/
// @Route("/auth")
// @Route("/auth/{provider}")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /**
    * AuthProvider, e.g. credentials
    */
    // @DataMember(Order=1)
    // @ApiMember(Description="AuthProvider, e.g. credentials")
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AuthenticateResponse(); }
    public getTypeName() { return 'Authenticate'; }
}

