/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import {
  Body,
  BodyButtonContent,
  BodyResultItem,
  ButtonContent,
  Content,
  Description,
  DescriptionsContent,
  Footer,
  Header,
  IndexResultItem,
  LoadingStep1,
  ResultContent,
  ResultItem,
  StartButton,
  TitleContent,
} from "./styledComponent";
import { Progress, Modal, Button, message, Collapse } from "antd";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../../../../store/actions";
import { useNavigate, useParams } from "react-router-dom";
import selectors from "../../../../../../store/selectors";
import tokenService from "../../../../../../service/token.service";
import {
  ModbusParameter,
  ParkingSpaceModbusStatus,
  ParkingSpaceState,
  ParkingSpaceStatus,
} from "../../../../../../store/dto/parking.dtos";
import { Customer } from "../../../../../../store/dto/hub.dtos";
import { TitleResult } from "../../UltrasonicSensor/step3/styledComponent";
import { ModalFooter } from "../../UltrasonicSensor/initial/styledComponent";

const { Panel } = Collapse;

const FirstStepCalibrationsCurrentConsumptionPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [percent, setPercent] = React.useState<number>(0);
  const [isModalWarningOpen, setIsModalWarningOpen] = React.useState(false);
  const [counterErrors, setCounterErrors] = React.useState<number>(0);
  const [isArrayParameters, setIsArrayParameters] = React.useState<
    ParkingSpaceModbusStatus[]
  >([]);
  const [maxCurrentOpen, setMaxCurrentOpen] = React.useState<number[]>([]);
  const [maxCurrentClosed, setMaxCurrentClosed] = React.useState<number[]>([]);
  // const [percent, setPercent] = React.useState<number>(0);
  const { spacesId, spaceId } = useParams();
  const customer: Customer = useSelector(selectors.auth.getCustomer) || [];
  const loading: string[] = useSelector(selectors.app.getCurrentLoading) || [];
  const [messageApi, contextHolder] = message.useMessage();
  const [space, setSpace] = React.useState<ParkingSpaceStatus>(
    {} as ParkingSpaceStatus
  );
  const [maximumCurrent, setMaximumCurrent] = React.useState<ModbusParameter>(
    {} as ModbusParameter
  );

  const [modbusStatus, setModbusStatus] = React.useState<
    ParkingSpaceModbusStatus
  >({} as ParkingSpaceModbusStatus);

  let count = 0;
  let valueOpened: number[] = [];
  let valueClosed: number[] = [];

  let counter: number = 0;
  let arrayParameters: ParkingSpaceModbusStatus[] = [];
  let counterError: number = 0;

  React.useEffect(() => {
    const callback = (value: ParkingSpaceStatus) => setSpace(value);
    dispatch(
      actions.parking.saga.asyncGetParkingSpace({
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
        id: spaceId as string,
        callback,
      })
    );

    const callbackModbusStatus = (value: ParkingSpaceModbusStatus) =>
      setMaximumCurrent(
        value.parameters.find((m) => m.address === 24) as ModbusParameter
      );
    dispatch(
      actions.parking.saga.asyncGetParkingSpaceModbusStatus({
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
        id: spaceId as string,
        callbackModbusStatus,
      })
    );
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const RoundMaxValue = (value: number): number => {
    const temp = value * 1.2;
    return Math.round(temp / 100) * 100;
  };

  const handleOk = () => {
    // navigate(
    //   `/parkings/${spacesId}/${spaceId}/calibrations-current-consumption/finish`
    // );
    // setIsModalOpen(false);

    dispatch(actions.app.saga.asyncAddLoading("writeToModbus"));

    const callbackEditModbusStatus = (value: ParkingSpaceModbusStatus) => {
      const callbackEditModbusStatus = (value: ParkingSpaceModbusStatus) => {
        navigate(
          `/parkings/${spacesId}/${spaceId}/calibrations-current-consumption/finish`
        );
        setIsModalOpen(false);
      };

      dispatch(
        actions.parking.saga.asyncSetParkingSpaceModbusParameter({
          customerId: customer.id
            ? customer.id
            : (tokenService.customerId as string),
          id: spaceId as string,
          address: 16,
          description: "",
          value: "0",
          callbackEditModbusStatus,
        })
      );
    };

    dispatch(
      actions.parking.saga.asyncSetParkingSpaceModbusParameter({
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
        id: spaceId as string,
        address: 15,
        description: "",
        value: RoundMaxValue(
          Math.max.apply(null, maxCurrentOpen.concat(maxCurrentClosed))
        ).toString(),
        callbackEditModbusStatus,
      })
    );
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // React.useEffect(() => {
  //   setParkings(parkingData);
  // }, [parkingData]);

  const handleOkWarning = () => {
    setIsModalWarningOpen(false);
  };

  const handleCancelWarning = () => {
    setIsModalWarningOpen(false);
  };

  const preStart = () => {
    counter = 0;
    arrayParameters = [];
    setIsArrayParameters([]);
    start();
    setPercent(0);
  };

  const loadModbusDataAfterClose = () => {
    const callbackModbusStatus = (value: ParkingSpaceModbusStatus) => {
      valueClosed.push(
        value.parameters.find((item) => item.address === 76)?.value as number
      );
      if (count < 10) {
        setPercent(count * 10);
        openParking();
        setModbusStatus(value);
      } else {
        dispatch(actions.app.saga.asyncRemoveLoading("loadingParametersStep1"));
        setMaxCurrentOpen(valueOpened);
        setMaxCurrentClosed(valueClosed);
      }
    };

    dispatch(
      actions.parking.saga.asyncGetParkingSpaceModbusStatus({
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
        id: spaceId as string,
        callbackModbusStatus,
      })
    );
  };
  const loadModbusDataAfterOpen = () => {
    const callbackModbusStatus = (value: ParkingSpaceModbusStatus) => {
      console.log("опускание ", count);
      valueOpened.push(
        value.parameters.find((item) => item.address === 77)?.value as number
      );
      if (count < 10) {
        setPercent(count * 10);
        closeParking();
        setModbusStatus(value);
      } else {
        dispatch(actions.app.saga.asyncRemoveLoading("loadingParametersStep1"));
        setMaxCurrentOpen(valueOpened);
        setMaxCurrentClosed(valueClosed);
      }
    };
    dispatch(
      actions.parking.saga.asyncGetParkingSpaceModbusStatus({
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
        id: spaceId as string,
        callbackModbusStatus,
      })
    );
  };

  const openParking = () => {
    const callbackOpen = (value: ParkingSpaceStatus) => {
      loadModbusDataAfterOpen();
      count++;
    };
    dispatch(
      actions.parking.saga.asyncOpenParkingSpace({
        customerId: "",
        id: space.id,
        callbackOpen,
      })
    );
  };
  const closeParking = () => {
    const callbackClose = (value: ParkingSpaceStatus) => {
      loadModbusDataAfterClose();
      count++;
      // setSpace(value)
    };
    dispatch(
      actions.parking.saga.asyncCloseParkingSpace({
        customerId: "",
        id: space.id,
        callbackClose,
      })
    );
  };

  const start = () => {
    dispatch(actions.app.saga.asyncAddLoading("loadingParametersStep1"));
    if (space.state === ParkingSpaceState.Closed) {
      openParking();
    } else if (space.state === ParkingSpaceState.Opened) {
      closeParking();
    }

    // dispatch(actions.app.saga.asyncAddLoading("loadingParametersStep1"));

    // const callbackModbusStatus = (value: ParkingSpaceModbusStatus) => {
    //   if (counter < 3) {
    //     const timer = setTimeout(() => {
    //       // setIsArrayParameters([...isArrayParameters, value]);
    //       if (counter === 0) setPercent(30);
    //       if (counter === 1) setPercent(60);
    //       if (counter === 2) setPercent(80);
    //       arrayParameters.push(value);
    //       counter++;
    //       start();
    //     }, 3000);
    //     return () => clearTimeout(timer);
    //   } else {
    //     setPercent(100);
    //     dispatch(actions.app.saga.asyncRemoveLoading("loadingParametersStep1"));
    //     setIsArrayParameters(arrayParameters);
    //     arrayParameters.map((item) => {
    //       item.parameters.find((m) => m.address === 69)?.value === 255
    //         ? null
    //         : counterError++;
    //       item.parameters.find((m) => m.address === 70)?.value === 255
    //         ? null
    //         : counterError++;
    //     });
    //     setCounterErrors(counterError);
    //     counterError !== 0 &&
    //       messageApi.open({
    //         type: "error",
    //         content: "Уберите препятствие и повторите попытку",
    //       });
    //   }
    // };

    // dispatch(
    //   actions.parking.saga.asyncGetParkingSpaceModbusStatus({
    //     customerId: customer.id
    //       ? customer.id
    //       : (tokenService.customerId as string),
    //     id: spaceId as string,
    //     callbackModbusStatus,
    //   })
    // );
  };

  const backButton = () => {
    navigate(
      `/parkings/${spacesId}/${spaceId}/calibrations-current-consumption/initial`
    );
  };
  const nextButton = () => {
    if (
      counterErrors === 0 &&
      maxCurrentOpen.length !== 0 &&
      maxCurrentClosed.length !== 0
    ) {
      showModal();
      // navigate(
      //   `/parkings/${spacesId}/${spaceId}/calibrations-current-consumption/step-2`
      // );
    } else {
      setIsModalWarningOpen(true);
    }
  };
  return (
    <Body>
      {contextHolder}
      <Header>
        <span>Поиск максимума тока </span>
      </Header>
      <Description>
        <Collapse bordered={false} ghost>
          <Panel header="Описание" key="1">
            <TitleContent>
              <span>
                Убедитесь, что замку ничего не мешает подняться и опустится!
              </span>
              <span>
                После нажатия кнопки{" "}
                <span style={{ color: "rgb(255,138,0)" }}>"Старт"</span>{" "}
                программа 5 раза опустит и подымет замок.
              </span>
              <span>
                Проверьте полученные результаты и нажмите
                <span style={{ color: "rgb(255,138,0)" }}>"Дальше"</span>.
              </span>
            </TitleContent>
          </Panel>
        </Collapse>
      </Description>
      <StartButton>
        <BodyButtonContent>
          <Button
            type="primary"
            style={{ height: "45px", width: "100%" }}
            onClick={() => preStart()}
          >
            Старт
          </Button>
        </BodyButtonContent>
      </StartButton>
      {maxCurrentOpen?.length !== 0 && maxCurrentClosed?.length !== 0 && (
        <TitleResult>
          <>
            <div>
              <span> Предельное значение тока </span>
              <span>{maximumCurrent?.stringValue}</span>
            </div>
            <div>
              <span> Максимальное полученное значения тока </span>
              <span>
                {maxCurrentOpen?.length &&
                  RoundMaxValue(
                    Math.max.apply(
                      null,
                      maxCurrentOpen.concat(maxCurrentClosed)
                    )
                  )}
              </span>
            </div>
            {/* <div>
              <span> Максимальное значение поднятия</span>
              <span>
                {maxCurrentClosed.length &&
                  Math.max.apply(null, maxCurrentClosed)}
              </span>
            </div> */}
          </>
        </TitleResult>
      )}
      <Content>
        <ResultContent>
          {maxCurrentOpen &&
            maxCurrentClosed &&
            maxCurrentOpen?.map((item, index) => (
              <ResultItem>
                <IndexResultItem>Цикл {index + 1}</IndexResultItem>
                <BodyResultItem>
                  <div>
                    <span>Последний ток опускания mA </span>
                    <span>{maxCurrentOpen[index] || "—"}</span>
                  </div>
                  <div>
                    <span>Последний ток поднятия mA </span>
                    <span>{maxCurrentClosed[index] || "—"}</span>
                  </div>
                </BodyResultItem>
              </ResultItem>
            ))}
        </ResultContent>
      </Content>
      <Footer>
        <Button style={{ height: "40px" }} key="back" onClick={backButton}>
          Назад
        </Button>
        <Button
          style={{ height: "40px" }}
          onClick={() =>
            navigate(`/parkings/${spacesId}/${spaceId}/modbus-parameters`)
          }
        >
          Домой
        </Button>
        <Button
          // disabled={!maxCurrentClosed.length}
          style={{ height: "40px" }}
          type="primary"
          onClick={nextButton}
        >
          Дальше
        </Button>
      </Footer>

      <Modal
        title="Подтвердите!"
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <ModalFooter>
            <Button key="back" onClick={handleCancel}>
              Отмена
            </Button>

            <Button key="submit" type="primary" onClick={handleOk}>
              Записать
            </Button>
          </ModalFooter>,
        ]}
      >
        {}
        <span>Записать значения: </span>
        <p>Адрес 16, минимальный ток mA - 0</p>
        <p>
          Адрес 15, максимальный ток mA -{" "}
          {RoundMaxValue(
            Math.max.apply(null, maxCurrentOpen?.concat(maxCurrentClosed))
          )}
        </p>
        {/* <p>
          Адрес 66, ток опускания mA - {Math.max.apply(null, maxCurrentOpen)}
        </p> */}
      </Modal>

      <Modal
        title="Внимание!"
        open={isModalWarningOpen}
        onOk={handleOkWarning}
        onCancel={handleCancelWarning}
        centered
        footer={[
          <ModalFooter>
            <Button key="submit" type="primary" onClick={handleOkWarning}>
              ОК
            </Button>
          </ModalFooter>,
        ]}
      >
        <p>
          Чтобы продолжить, запустите калибровку нажатием кнопки{" "}
          <span style={{ color: "rgb(255,138,0)" }}>Старт</span>{" "}
        </p>
      </Modal>

      {loading.find((item) => item === "loadingParametersStep1") && (
        <LoadingStep1>
          <Progress showInfo={false} type="circle" percent={percent} />
        </LoadingStep1>
      )}
    </Body>
  );
};

export default FirstStepCalibrationsCurrentConsumptionPage;
