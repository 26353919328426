import { Content, Main } from "./styledComponent";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../store/actions";
import { useNavigate } from "react-router-dom";
import selectors from "../../../store/selectors";
import { NotificationModal } from "../Parkings/styledComponent";
import { CloseOutlined } from "@ant-design/icons";
import { INotification } from "../../../store/interfaces/App.Interface";

const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading: string[] = useSelector(selectors.app.getCurrentLoading) || [];

  const currentNotifications: INotification[] = useSelector(
    selectors.app.getNotification
  );

  const onFinish = (values: any) => {
    const callback = () => {
      navigate("/parkings");
    };
    dispatch(
      actions.auth.saga.asyncLogIn({
        login: values.username,
        password: values.password,
        callback,
      })
    );
  };

  const onFinishFailed = (errorInfo: any) => {};

  return (
    <Main>
      <Content>
        <Form
          name="basic"
          layout="vertical"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          requiredMark={false}
        >
          <Form.Item
            label="Имя пользователя ИТС"
            name="username"
            rules={[{ required: true, message: "Укажите имя" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Пароль"
            name="password"
            rules={[
              { required: true, message: "Укажите пароль" },
              {
                // type: "regexp",
                pattern: new RegExp(/^[a-zA-Z0-9а-яёА-ЯЁ?=.*?]*$/),
                message: "Неправильный формат!",
              },
            ]}
          >
            <Input.Password
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 0 }}>
            <Button
              style={{ width: "100%" }}
              loading={
                loading.find((item) => item === "loadingAdminLogin")
                  ? true
                  : false
              }
              type="primary"
              htmlType="submit"
            >
              Войти
            </Button>
          </Form.Item>
        </Form>
      </Content>
      {currentNotifications?.map((item) => (
        <NotificationModal key={item?.id}>
          <div
            className="close-icon-notification-modal"
            onClick={() =>
              dispatch(actions.app.saga.asyncRemoveNotification(item?.id))
            }
          >
            <CloseOutlined />
          </div>
          <span>{item?.messages}</span>
        </NotificationModal>
      ))}
    </Main>
  );
};

export default LoginPage;
