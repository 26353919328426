/* eslint-disable import/no-anonymous-default-export */
import AppTypes from "./app";
import AuthTypes from "./auth";
import ParkingTypes from "./parking";

export default {
  auth: AuthTypes,
  app: AppTypes,
  parking: ParkingTypes,
};
