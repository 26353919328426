/* eslint-disable import/no-anonymous-default-export */

import IState from "../../interfaces/Store.Interface";

const getRoot = (state: IState) => state.auth;

export default {
  get: (state: IState) => getRoot(state),
  getAuth: (state: IState) => getRoot(state).auth,
  getCustomer: (state: IState) => getRoot(state).customer,
};
