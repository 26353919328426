import styled from "styled-components";

export const Main = styled.div`
 position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
  height: 100%;
  min-height:100%;
  width:100%;
  box-sizing: border-box;
`;
export const Content = styled.div`
 position: fixed;

  box-sizing: border-box;
  padding: 48px 32px 16px;
  border-radius: 8px;
  background-color: rgb(23 23 42);

`;
