// import { Route, Routes } from "react-router-dom";
import ParkingPage from "./components/Pages/Parkings";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import LoginPage from "./components/Pages/Login";
import tokenService from "./service/token.service";
import ProtectedRoutes from "./common/ProtectedRoutes";

const App: React.FC = () => {
  console.log("login", tokenService.isLoggedIn);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route
          path="parkings/*"
          element={
            <ProtectedRoutes>
              <ParkingPage />
            </ProtectedRoutes>
          }
        >
          {/* <Route path=":id" element={<ParkingPage />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
