/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import {
  Body,
  BodyButtonContent,
  BodyResultItem,
  ButtonContent,
  Content,
  Description,
  DescriptionsContent,
  Footer,
  Header,
  IndexResultItem,
  LoadingStep1,
  ResultContent,
  ResultItem,
  StartButton,
  TitleContent,
} from "./styledComponent";
import { Progress, Modal, Button, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../../../../store/actions";
import { useNavigate, useParams } from "react-router-dom";
import selectors from "../../../../../../store/selectors";
import tokenService from "../../../../../../service/token.service";
import {
  ParkingSpaceModbusStatus,
  ParkingSpaceStatus,
} from "../../../../../../store/dto/parking.dtos";
import { Customer } from "../../../../../../store/dto/hub.dtos";

const SecondStepCalibrationsUltrasonicSensorPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [percent, setPercent] = React.useState<number>(0);
  const [counterErrors, setCounterErrors] = React.useState<number>(0);
  const [isArrayParameters, setIsArrayParameters] = React.useState<
    ParkingSpaceModbusStatus[]
  >([]);
  const { spacesId, spaceId } = useParams();
  const customer: Customer = useSelector(selectors.auth.getCustomer) || [];
  const loading: string[] = useSelector(selectors.app.getCurrentLoading) || [];
  const [messageApi, contextHolder] = message.useMessage();

  let counter: number = 0;
  let arrayParameters: ParkingSpaceModbusStatus[] = [];
  let counterError: number = 0;

  React.useEffect(() => {}, []);


  const preStart = () => {
    counter = 0;
    arrayParameters = [];
    setIsArrayParameters([]);
    start();
    setPercent(0);
  };

  const start = () => {
    dispatch(actions.app.saga.asyncAddLoading("loadingParametersStep1"));

    const callbackModbusStatus = (value: ParkingSpaceModbusStatus) => {
      if (counter < 3) {
        const timer = setTimeout(() => {
          // setIsArrayParameters([...isArrayParameters, value]);
          if (counter === 0) setPercent(30);
          if (counter === 1) setPercent(60);
          if (counter === 2) setPercent(80);
          arrayParameters.push(value);
          counter++;
          start();
        }, 3000);
        return () => clearTimeout(timer);
      } else {
        setPercent(100);
        dispatch(actions.app.saga.asyncRemoveLoading("loadingParametersStep1"));
        setIsArrayParameters(arrayParameters);
        arrayParameters.map((item) => {
          item.parameters.find((m) => m.address === 69)?.value === 255
            ? null
            : counterError++;
          item.parameters.find((m) => m.address === 70)?.value === 255
            ? null
            : counterError++;
        });
        setCounterErrors(counterError);
        counterError !== 0 &&
          messageApi.open({
            type: "error",
            content: "Уберите препятствие и повторите попытку",
          });
      }
    };

    dispatch(
      actions.parking.saga.asyncGetParkingSpaceModbusStatus({
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
        id: spaceId as string,
        callbackModbusStatus,
      })
    );
  };

  const backButton = () => {
    navigate(
      `/parkings/${spacesId}/${spaceId}/calibrations-ultrasonic-sensor/step-1`
    );
  };
  const nextButton = () => {
    if (counterErrors === 0) {
      navigate(
        `/parkings/${spacesId}/${spaceId}/calibrations-ultrasonic-sensor/step-3`
      );
    } else {
      messageApi.open({
        type: "error",
        content: "Уберите препятствие и повторите попытку",
      });
    }
  };
  return (
    <Body>
      {contextHolder}
      <Header>
        <span>Заедьте на парковочное место.</span>
      </Header>
      <Description>
        <TitleContent>
          <span>
            Далее необходимо заехать на парковочное место, что бы программа
            могла с датчиков считать актуальную информацию на реально примере и
            высчитать необходимое значение.
          </span>
          <span>
            После того, как Вы заехали на парковочное место вам необходимо
            нажать <span style={{ color: "rgb(255,138,0)" }}>Дальше</span>, что
            бы продолжить калибровку.
          </span>
        </TitleContent>
      </Description>
      <Content>
        <StartButton>
          {/* <BodyButtonContent>
            <Button
              type="primary"
              style={{ height: "45px", width: "100%" }}
              onClick={() => preStart()}
            >
              Проверка датчиков
            </Button>
          </BodyButtonContent> */}
        </StartButton>

        <ResultContent>
          {/* {isArrayParameters &&
            isArrayParameters?.map((item, index) => (
              <ResultItem
                color={
                  item.parameters.find((m) => m.address === 69)?.value ===
                    255 &&
                  item.parameters.find((m) => m.address === 70)?.value === 255
                    ? "green"
                    : "red"
                }
              >
                <IndexResultItem>Результат {index + 1}</IndexResultItem>
                <BodyResultItem>
                  <div>
                    <span>Значение датчика 1</span>
                    <span>
                      {
                        item.parameters.find((m) => m.address === 69)
                          ?.stringValue
                      }
                    </span>
                  </div>
                  <div>
                    <span>Значение датчика 2</span>
                    <span>
                      {item.parameters.find((m) => m.address === 70)?.value}
                    </span>
                  </div>
                </BodyResultItem>
              </ResultItem>
            ))} */}
        </ResultContent>
      </Content>
      <Footer>
        <Button style={{ height: "40px" }} key="back" onClick={backButton}>
          Назад
        </Button>
        <Button
          style={{ height: "40px" }}
       
          onClick={() =>
            navigate(`/parkings/${spacesId}/${spaceId}/modbus-parameters`)
          }
        >
          Домой
        </Button>
        <Button
          // disabled={!isArrayParameters.length}
          style={{ height: "40px" }}
          type="primary"
          onClick={nextButton}
        >
          Дальше
        </Button>
      </Footer>



      {loading.find((item) => item === "loadingParametersStep1") && (
        <LoadingStep1>
          <Progress type="circle" percent={percent} />
        </LoadingStep1>
      )}
    </Body>
  );
};

export default SecondStepCalibrationsUltrasonicSensorPage;
