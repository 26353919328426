import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import rootReducer from "./reducers";
import rootSaga from "./saga";

const sagaMiddleware = createSagaMiddleware();
const loggerMiddleWare = createLogger({ collapsed: true });

const rootStore = createStore(
  rootReducer,
  applyMiddleware(sagaMiddleware, 
    // loggerMiddleWare
    )
);

sagaMiddleware.run(rootSaga);

export default rootStore;
