import { call, put } from "redux-saga/effects";

import tokenService from "../../../service/token.service";
import errorApiService from "../../../service/error.service";

import adminApiService from "../../../service/admin-api.services";
import actions from "../../actions";
import {
  Authenticate,
  AuthenticateResponse,
  Customer,
  GetCurrentCustomerRequest,
} from "../../dto/hub.dtos";
import { IAction } from "../../utils";

export interface IAsyncCall {
  login: string;
  password: string;
}

const loginCall = (opts: IAsyncCall): Promise<AuthenticateResponse> => {
  const { login, password } = opts;
  const request = new Authenticate();
  request.provider = "credentials";
  request.userName = login;
  request.password = password;
  return adminApiService.post(request);
};
const getCustomerCall = (opts: { id?: string }): Promise<Customer> => {
  const { id } = opts;
  const request = new GetCurrentCustomerRequest();
  request.customerId = id ? id : "";
  return adminApiService.get(request);
};

export function* login(
  action: IAction<{ login: string; password: string; callback?: () => void }>
) {
  try {
    yield put(actions.app.saga.asyncAddLoading("loadingAdminLogin"));
    const {
      payload: { login, password, callback },
    } = action;
    const result: AuthenticateResponse = yield call(loginCall, {
      login,
      password,
    });
    yield put(actions.auth.setAuth(result));
    yield tokenService.setToken(result.bearerToken, result.refreshToken);
    const resultCustomer: Customer = yield call(getCustomerCall, {});
    yield put(actions.auth.setCustomer(resultCustomer));
    yield tokenService.setCustomer(resultCustomer.id);
    yield put(actions.app.saga.asyncRemoveLoading("loadingAdminLogin"));
    yield callback?.();
    // yield call(history.push, 'admin/orders');
  } catch (error) {
    yield put(actions.app.saga.asyncRemoveLoading("loadingAdminLogin"));
    yield put(
      actions.app.saga.asyncInitNotification({
        messages: errorApiService.catchRequestError(error),
        type: "warm",
      })
    );
  }
}
export function* getCurrentCustomerRequest(action: IAction<{ id: string }>) {
  try {
    yield put(actions.app.saga.asyncAddLoading("loadingCustomer"));
    const {
      payload: { id },
    } = action;
    const result: Customer = yield call(getCustomerCall, {
      id,
    });
    yield put(actions.auth.setCustomer(result));
    yield put(actions.app.saga.asyncRemoveLoading("loadingCustomer"));
  } catch (error) {
    yield put(actions.app.saga.asyncRemoveLoading("loadingCustomer"));
    yield put(
      actions.app.saga.asyncInitNotification({
        messages: errorApiService.catchRequestError(error),
        type: "warm",
      })
    );
  }
}
