/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable max-len */

import { INotification } from "../../interfaces/App.Interface";
import types from "../../types";
import { actionCreator } from "../../utils";


export default {
	setGlobalLoading: actionCreator<{isLoading: boolean} | undefined>(types.app.GLOBAL_LOADING),
	setCurrentLoadings: actionCreator<string[]>(types.app.SET_CURRENT_LOADINGS),
	setNotification: actionCreator<INotification[]>(types.app.SET_NOTIFICATION),
	saga: {
		asyncAddLoading: actionCreator<string>(types.app.saga.ASYNC_ADD_LOADING),
		asyncRemoveLoading: actionCreator<string>(types.app.saga.ASYNC_REMOVE_LOADING),
		asyncAddNotification: actionCreator<INotification>(types.app.saga.ASYNC_ADD_NOTIFICATION),
		asyncRemoveNotification: actionCreator<string>(types.app.saga.ASYNC_REMOVE_NOTIFICATION),
		asyncInitNotification: actionCreator<{ messages: string, type: string}>(types.app.saga.ASYNC_INIT_NOTIFICATION),
	},
};
