import styled from "styled-components";

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 40px);
`;
export const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  gap: 4px;

  span:nth-child(1) {
    font-size: 20px;
    color: rgb(255, 138, 0);
  }
  span:nth-child(2) {
    font-size: 16px;
    color: rgb(255 138 0 / 90%);
  }
`;
export const Body = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  max-height: calc(100vh - 120px);
  box-sizing: border-box;
  overflow: hidden;
`;
export const Footer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 0px;
  color: #fff;
`;
