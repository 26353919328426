import React from "react";

import { Navigate } from "react-router-dom";

const useAuth = () => {
  const user = sessionStorage.getItem('bearer_token')
  if (user) {
    return true;
  } else {
    return false;
  }
};

const ProtectedRoutes = ({ children }: { children: JSX.Element }) => {
    const  user  = useAuth();
    if (!user) {
      return <Navigate to="/" />;
    }
    return children;
};

export default ProtectedRoutes;
