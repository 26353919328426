import React from "react";
import { Body, Content } from "./styledComponent";
import {
  ParkingSpaceMode,
  ParkingSpaceState,
  ParkingSpaceStatus,
} from "../../../store/dto/parking.dtos";
import moment from "moment";
import { useDispatch } from "react-redux";
import actions from "../../../store/actions";
import { useNavigate, useParams } from "react-router-dom";
import { Skeleton } from "antd";

interface IListParkingSpaceItem {
  space: ParkingSpaceStatus;
}

const ListParkingSpaceItem: React.FC<IListParkingSpaceItem> = (props) => {
  const { space } = props;
  const { spacesId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateToSettings = () => {
    dispatch(actions.parking.setActiveParkingSpace(space));
    navigate(`/parkings/${spacesId}/${space.id}`);
  };

  return (
    <>
      <Body onClick={navigateToSettings} color={space?.iconStatus?.color}>
        <Content>
          <div>
            {space.id ? (
              <>
                <span>{space.parkingName + "-" + space?.name} </span>
                <span>
                  {space?.statusDescription === "Готов"
                    ? "Ошибок нет"
                    : space?.statusDescription}
                </span>
              </>
            ) : (
              <>
                <Skeleton.Button
                  active
                  size={"small"}
                  // shape={buttonShape}
                  block={true}
                />
                <Skeleton.Button
                  active
                  size={"small"}
                  // shape={buttonShape}
                  block={true}
                />
              </>
            )}
          </div>
          <div>
            <span>
              {space?.mode === ParkingSpaceMode.Engineer
                ? "Инженерный"
                : space?.mode === ParkingSpaceMode.Work
                ? "В работе"
                : "Выключен"}
            </span>
            <span>{moment(space?.statusTime).format("DD.MM.YYYY HH:mm")}</span>
          </div>
          {space?.online &&
            (space?.mode === ParkingSpaceMode.Work ||
              space?.mode === ParkingSpaceMode.Engineer) && (
              <div>
                <span>
                  {space?.state === ParkingSpaceState.Closed
                    ? "Закрыт"
                    : space?.state === ParkingSpaceState.Opened
                    ? "Открыт"
                    : "Полож. неизвестно"}
                </span>
                {!space?.available && (
                  <span>
                    {space?.carDetected ? "Машина есть" : "Машины нет"}
                  </span>
                )}
              </div>
            )}
        </Content>
      </Body>
    </>
  );
};

export default ListParkingSpaceItem;
