import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import actions from "../../../store/actions";
import { ParkingStatus } from "../../../store/dto/parking.dtos";
import moment from "moment";

import { Body, Title, Content, Description } from "./styledComponent";

interface IListParkingItems {
  parking: ParkingStatus;
}

const ListParkingItems: React.FC<IListParkingItems> = (props) => {
  const { parking } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const setActiveParking = () => {
    dispatch(actions.parking.setActiveParking(parking));
    navigate(`${parking.id}`);
  };

  return (
    <Body onClick={() => setActiveParking()}>
      <div style={{ width: "60px", height: "60px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 128 128"
          width="60"
          height="60"
        >
          <rect
            fill={parking.iconStatus.color}
            stroke={parking.iconStatus.borderColor}
            strokeWidth="10"
            width="118"
            height="118"
            x="5"
            y="5"
            rx="15"
            ry="15"
          />
          <g transform="scale(0.6) translate(42 42)" fill="#ffffff">
            <path
              d="M 23.520836,11.511719 L 68.450523,11.511719 C 
81.809827,11.511824 92.052004,14.488383 99.177086,20.441406 C 106.34886,26.347746 109.9348,34.785238 109.9349,45.753906 
C 109.9348,56.769591 106.34886,65.253957 99.177086,71.207031 C 92.052004,77.113321 81.809827,80.066443 68.450523,80.066406 
L 50.591148,80.066406 L 50.591148,116.48828 L 23.520836,116.48828 L 23.520836,11.511719 M 50.591148,31.128906 L 
50.591148,60.449219 L 65.567711,60.449219 C 70.81765,60.449275 74.872334,59.183651 77.731773,56.652344 C 80.591078,54.074281 
82.020764,50.441472 82.020836,45.753906 C 82.020764,41.066482 80.591078,37.45711 77.731773,34.925781 C 74.872334,32.394615 
70.81765,31.128992 65.567711,31.128906 L 50.591148,31.128906"
            />
          </g>
        </svg>
      </div>

      <Content>
        <Title>
          <span>{parking.name}</span>
        </Title>
        <Description>
          <span>
            {parking.connectionTime && moment(parking.connectionTime).format("DD.MM.YYYY HH:mm")}
          </span>
          {/* <span>26.12.2022 12:18:55</span> */}
        </Description>
      </Content>
    </Body>
  );
};

export default ListParkingItems;
