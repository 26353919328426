import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { INotification } from "../../../store/interfaces/App.Interface";
import selectors from "../../../store/selectors";
import HeaderElement from "../../layout/Header";
import ParkingsList from "./ParkingsList";
import ParkingSpacesList from "./ParkingSpacesList";
import { Content, NotificationModal } from "./styledComponent";
import { CloseOutlined } from "@ant-design/icons";
import actions from "../../../store/actions";
import ParkingSpaceSettings from "./ParkingSpaceSettings";
import {
  BrowserRouter,
  Link,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import ModBusParameters from "./ParkingSpaceSettings/ModbusStatus";
import CalibrationsUltrasonicSensorPage from "./Calibrations/UltrasonicSensor";
import CalibrationsCurrentConsumptionPage from "./Calibrations/СurrentСonsumption";

const ParkingPage: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const currentNotifications: INotification[] = useSelector(
    selectors.app.getNotification
  );
  console.log(currentNotifications);
  return (
    <Content>
      <HeaderElement></HeaderElement>
      {/* {pathname.split("/")[2] === "main" && <ParkingsList></ParkingsList>}
      {pathname.split("/")[2] === "parking-spaces" && (
        <ParkingSpacesList></ParkingSpacesList>
      )}
      {pathname.split("/")[2] === "parking-space" && (
        <ParkingSpaceSettings></ParkingSpaceSettings>
      )} */}

      <Routes>
        {/* <Route path=":id" element={<Collection />}>
        <Route index element={<Collection />} />
        <Route path="all" element={<CollectionAll />} />
        <Route path=":groupId" element={<CollectionGroup />} />
      </Route> */}
        <Route path="*" element={<ParkingsList />} />
        <Route path="/:spacesId" element={<ParkingSpacesList />} />
       <Route path="/:spacesId/:spaceId" element={<ParkingSpaceSettings />} />
       <Route path="/:spacesId/:spaceId/modbus-parameters" element={<ModBusParameters />} />
       {/* <Route path="/:spacesId/:spaceId/calibrations-ultrasonic-sensor" element={<CalibrationsUltrasonicSensorPage />} /> */}
       <Route path="/:spacesId/:spaceId/calibrations-ultrasonic-sensor">
          <Route path="*" element={<CalibrationsUltrasonicSensorPage />} />
          {/* <Route path="parking-spaces" element={<ParkingSpacesList />} /> */}
        </Route>
       <Route path="/:spacesId/:spaceId/calibrations-current-consumption">
          <Route path="*" element={<CalibrationsCurrentConsumptionPage />} />
          {/* <Route path="parking-spaces" element={<ParkingSpacesList />} /> */}
        </Route>

         {/* <Route path=":id/:groupId" element={<CollectionGroup />} /> */}
      </Routes>
      {currentNotifications?.map((item) => (
        <NotificationModal color={item.type === 'warm' ? '2px solid #ff8a00' : ' 2px solid #00ff04'} key={item?.id} >
          <div
            className="close-icon-notification-modal"
            onClick={() =>
              dispatch(actions.app.saga.asyncRemoveNotification(item?.id))
            }
          >
            <CloseOutlined />
          </div>
          <span>{item?.messages}</span>
        </NotificationModal>
      ))}
    </Content>
  );
};

export default ParkingPage;
