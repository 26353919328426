/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import {
  Body,
  BodyButtonContent,
  ButtonContent,
  Content,
  DescriptionsContent,
  FooterButton,
  Header,
  ModalFooter,
  TitleButtonContent,
  TitleContent,
} from "./styledComponent";
import { Select, Modal, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../../../../store/actions";
import { useNavigate, useParams } from "react-router-dom";
import selectors from "../../../../../../store/selectors";
import tokenService from "../../../../../../service/token.service";
import { ParkingSpaceStatus } from "../../../../../../store/dto/parking.dtos";
import { Customer } from "../../../../../../store/dto/hub.dtos";

const InitialStepCalibrationsUltrasonicSensorPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { spacesId, spaceId } = useParams();
  const customer: Customer = useSelector(selectors.auth.getCustomer) || [];
  React.useEffect(() => {}, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    navigate(
      `/parkings/${spacesId}/${spaceId}/calibrations-ultrasonic-sensor/step-1`
    );
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // React.useEffect(() => {
  //   setParkings(parkingData);
  // }, [parkingData]);
  const start = () => {
    const callbackOpen = (value: ParkingSpaceStatus) => showModal();
    dispatch(
      actions.parking.saga.asyncOpenParkingSpace({
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
        id: spaceId as string,
        callbackOpen,
      })
    );
  };
  return (
    <Body>
      <Header>
        <span>Подготовка к калибровке</span>
      </Header>
      <Content>
        <TitleContent>
          <span>
            Добро пожаловать в программу калибровки ультразвуковых датчиков.
          </span>

          <span>
            Программа включает в себя последовательное прохождение этапов
            калибровки.
          </span>
          <span style={{ color: "rgb(255,138,0)" }}>
            Пожалуйста, следуйте инструкциям на экране!
          </span>

          <span>
            {" "}
            После нажатия кнопки "Опустить замок" начнется программа и замок
            опустится.
          </span>
        </TitleContent>
        {/* <DescriptionsContent>
          <span>
            1. Убедитесь, что парковочное место свободно и на нем никто не
            стоит.
          </span>
        </DescriptionsContent> */}

        <ButtonContent>
          {/* <TitleButtonContent>
            <span>После нажатия кнопки "Начать" замок опустится.</span>
          </TitleButtonContent> */}
          <BodyButtonContent>
            <div onClick={() => start()}>
              <span>Опустить</span> <span>замок</span>
            </div>
          </BodyButtonContent>
        </ButtonContent>

        <FooterButton>
          <Button
            key="back"
            onClick={() =>
              navigate(`/parkings/${spacesId}/${spaceId}/modbus-parameters`)
            }
          >
            Назад
          </Button>
        </FooterButton>
      </Content>
      <Modal
        title="Проверка!"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={[
          <ModalFooter>
            <Button key="back" onClick={handleCancel}>
              Отмена
            </Button>
            <Button type="primary" onClick={start}>
              Еще раз
            </Button>
            <Button key="submit" type="primary" onClick={handleOk}>
              ОК
            </Button>
          </ModalFooter>,
        ]}
      >
        <p>Нажмите "OK" если замок открыт.</p>
        <p>Нажмите "Еще раз" чтобы попробовать еще раз. </p>
        <p>Нажмите "Отмена" что бы выйти.</p>
      </Modal>
    </Body>
  );
};

export default InitialStepCalibrationsUltrasonicSensorPage;
