import styled from "styled-components";

export const ParameterBox = styled.div`
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing:border-box;

  gap: 14px;
  background-color: #00000085;
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 16px 8px;
`;

export const AddressParameter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap:4px;

  span:nth-child(1) {
    font-size: 10px;
    color: #b5b5b5;
  }
  span:nth-child(2) {
    color: #fff;
  }
`;

export const ContentParameter = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap:6px;
`;
export const TitleContentParameter = styled.div`
  font-size: 16px;
  color: #fff;
`;
export const DescriptionContentParameter = styled.div`
  font-size: 12px;
  color: #b5b5b5;
`;

export const ValueParameter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap:4px;

  span:nth-child(1) {
    font-size: 10px;
    color: #b5b5b5;
  }
  span:nth-child(2) {
    color: #fff;
  }
`;
