/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import {
  Body,
  BodyButtonContent,
  BodyResultItem,
  ButtonContent,
  Content,
  Description,
  DescriptionsContent,
  Footer,
  Header,
  IndexResultItem,
  LoadingStep1,
  ResultContent,
  ResultItem,
  StartButton,
  TitleContent,
} from "./styledComponent";
import { Progress, Modal, Button, message, Collapse } from "antd";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../../../../store/actions";
import { useNavigate, useParams } from "react-router-dom";
import selectors from "../../../../../../store/selectors";
import tokenService from "../../../../../../service/token.service";
import {
  ParkingSpaceModbusStatus,
  ParkingSpaceStatus,
} from "../../../../../../store/dto/parking.dtos";
import { Customer } from "../../../../../../store/dto/hub.dtos";
import { ModalFooter } from "../initial/styledComponent";

const { Panel } = Collapse;

const FirstStepCalibrationsUltrasonicSensorPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isModalOpenNext, setIsModalNextOpen] = React.useState(false);
  const [percent, setPercent] = React.useState<number>(0);
  const [counterErrors, setCounterErrors] = React.useState<number>(0);
  const [isArrayParameters, setIsArrayParameters] = React.useState<
    ParkingSpaceModbusStatus[]
  >([]);
  const { spacesId, spaceId } = useParams();
  const customer: Customer = useSelector(selectors.auth.getCustomer) || [];
  const loading: string[] = useSelector(selectors.app.getCurrentLoading) || [];
  const [messageApi, contextHolder] = message.useMessage();

  let counter: number = 0;
  let arrayParameters: ParkingSpaceModbusStatus[] = [];
  let counterError: number = 0;

  React.useEffect(() => {}, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    // navigate(
    //   `/parkings/${spacesId}/${spaceId}/calibrations-ultrasonic-sensor/step-2`
    // );
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOkNext = () => {
    setIsModalNextOpen(false);
  };

  const handleCancelNext = () => {
    setIsModalNextOpen(false);
  };

  const preStart = () => {
    counter = 0;
    arrayParameters = [];
    setIsArrayParameters([]);
    start();
    setPercent(0);
  };

  const start = () => {
    dispatch(actions.app.saga.asyncAddLoading("loadingParametersStep1"));

    const callbackModbusStatus = (value: ParkingSpaceModbusStatus) => {
      if (counter < 3) {
        const timer = setTimeout(() => {
          // setIsArrayParameters([...isArrayParameters, value]);
          if (counter === 0) setPercent(30);
          if (counter === 1) setPercent(60);
          if (counter === 2) setPercent(80);
          arrayParameters.push(value);
          counter++;
          start();
        }, 3000);
        return () => clearTimeout(timer);
      } else {
        setPercent(100);
        dispatch(actions.app.saga.asyncRemoveLoading("loadingParametersStep1"));
        setIsArrayParameters(arrayParameters);
        arrayParameters.map((item) => {
          item.parameters.find((m) => m.address === 69)?.value === 255
            ? null
            : counterError++;
          item.parameters.find((m) => m.address === 70)?.value === 255
            ? null
            : counterError++;
        });
        setCounterErrors(counterError);
        counterError !== 0 && setIsModalOpen(true);
      }
    };

    dispatch(
      actions.parking.saga.asyncGetParkingSpaceModbusStatus({
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
        id: spaceId as string,
        callbackModbusStatus,
      })
    );
  };

  const backButton = () => {
    navigate(
      `/parkings/${spacesId}/${spaceId}/calibrations-ultrasonic-sensor/initial`
    );
  };
  const nextButton = () => {
    if (counterErrors === 0 && isArrayParameters.length !== 0) {
      navigate(
        `/parkings/${spacesId}/${spaceId}/calibrations-ultrasonic-sensor/step-2`
      );
    } else if (isArrayParameters.length === 0) {
      setIsModalNextOpen(true);
    } else {
      // messageApi.open({
      //   type: "error",
      //   content: "Уберите препятствие и повторите попытку",
      // });
      setIsModalNextOpen(true);
    }
  };
  return (
    <Body>
      {contextHolder}
      <Header>
        <span>Проверка датчиков</span>
      </Header>
      <Description>
        <Collapse bordered={false} ghost>
          <Panel header="Описание" key="1">
            {/* <div style={{ maxHeight: "200px", overflow: "auto" }}> */}
            <TitleContent>
              <span>
                После нажатия кнопки "Проверка датчиков" программа несколько раз
                опросит группу ультразвуковых датчиков и проверит их работу.
              </span>
              <span>
                Если датчики исправны и нету никаких препятствий, то программа
                отобразит для каждого значения 255, что значит, что датчики{" "}
                <span style={{ color: "green" }}>работают</span> и можно
                продолжать калибровку.
              </span>
              <span>
                Если датчики показывают не 255, при этом никаких препятствий
                нету, то вероятно, присутствует какая-то{" "}
                <span style={{ color: "red" }}>проблема</span>, попробуйте{" "}
                <span style={{ color: "rgb(255,138,0)" }}>еще раз</span>, либо
                замените датчики.
              </span>
              <span>
                После того, как все замеры прошли успешно, и результаты обведены{" "}
                <span style={{ color: "green" }}>зеленой</span> рамкой нажмите{" "}
                <span style={{ color: "rgb(255,138,0)" }}>Дальше</span> внизу
                экрана
              </span>
            </TitleContent>
            <DescriptionsContent>
              <span>
                Пожалуйста, убедитесь, что парковочное место свободно и нет
                никаких препятствий.
              </span>
            </DescriptionsContent>
            {/* </div> */}
          </Panel>
        </Collapse>
      </Description>
      <StartButton>
        <BodyButtonContent>
          <Button
            type="primary"
            style={{ height: "45px", width: "100%" }}
            onClick={() => preStart()}
          >
            Проверка датчиков
          </Button>
        </BodyButtonContent>
      </StartButton>
      <Content>
        <ResultContent>
          {isArrayParameters &&
            isArrayParameters?.map((item, index) => (
              <ResultItem
                color={
                  item.parameters.find((m) => m.address === 69)?.value ===
                    255 &&
                  item.parameters.find((m) => m.address === 70)?.value === 255
                    ? "green"
                    : "red"
                }
              >
                <IndexResultItem>
                  Результат измерений {index + 1}
                </IndexResultItem>
                <BodyResultItem>
                  <div
                    style={{
                      color:
                        item.parameters.find((m) => m.address === 69)?.value !==
                        255
                          ? "red"
                          : "",
                    }}
                  >
                    <span>Значение датчика 1</span>
                    <span>
                      {
                        item.parameters.find((m) => m.address === 69)
                          ?.stringValue
                      }
                    </span>
                  </div>
                  <div
                    style={{
                      color:
                        item.parameters.find((m) => m.address === 70)?.value !==
                        255
                          ? "red"
                          : "",
                    }}
                  >
                    <span>Значение датчика 2</span>
                    <span>
                      {item.parameters.find((m) => m.address === 70)?.value}
                    </span>
                  </div>
                </BodyResultItem>
              </ResultItem>
            ))}
        </ResultContent>
      </Content>
      <Footer>
        <Button style={{ height: "40px" }} key="back" onClick={backButton}>
          Назад
        </Button>
        <Button
          style={{ height: "40px" }}
       
          onClick={() =>
            navigate(`/parkings/${spacesId}/${spaceId}/modbus-parameters`)
          }
        >
          Домой
        </Button>
        <Button
          // disabled={!isArrayParameters.length}
          style={{ height: "40px" }}
          type="primary"
          onClick={nextButton}
        >
          Дальше
        </Button>
      </Footer>

      <Modal
        title="Внимание!"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={[
          <ModalFooter>
            <Button key="submit" type="primary" onClick={handleOk}>
              ОК
            </Button>
          </ModalFooter>,
        ]}
      >
        <p>Датчики обнаружили препятствие! </p>
        <p>Уберите препятствие и повторите попытку! </p>
      </Modal>
      <Modal
        title="Внимание!"
        open={isModalOpenNext}
        onOk={handleOkNext}
        onCancel={handleCancelNext}
        centered
        footer={[
          <ModalFooter>
            <Button key="submit" type="primary" onClick={handleOkNext}>
              ОК
            </Button>
          </ModalFooter>,
        ]}
      >
        {isArrayParameters.length === 0 ? (
          <>
            <p>Вы не произвели проверку датчиков!</p>
          </>
        ) : (
          <>
            <p>Вы не выполнили все условия для перехода на следующий этап.</p>
            <p>
              Убедитесь, что все замеры прошли успешно и датчики не видят
              препятствий.
            </p>
            <p>Пожалуйста, следуйте инструкции из описания.</p>
          </>
        )}
      </Modal>

      {loading.find((item) => item === "loadingParametersStep1") && (
        <LoadingStep1>
          <Progress showInfo={false} type="circle" percent={percent} />
        </LoadingStep1>
      )}
    </Body>
  );
};

export default FirstStepCalibrationsUltrasonicSensorPage;
