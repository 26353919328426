import styled from "styled-components";

export const Body = styled.div.attrs((props: { color: string }) => props)`
  /* width: 100%; */
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  gap: 14px;
  background-color: ${(props) => (props.color ? props.color : "gray")};
  border-radius: 8px;
  padding: 16px 8px;
`;

export const Content = styled.div`
  /* width: calc(100% - 60px); */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: stretch;

  div:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;
    span:nth-child(1) {
      font-size: 18px;
    }
    span:nth-child(2) {
      font-size: 16px;
    }
    font-size: 17px;
  }
  div:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #d6d6d6;
    font-size: 14px;
  }
  div:nth-child(3) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #d6d6d6;
    font-size: 14px;
  }
`;
