import styled from "styled-components";

export const Body = styled.div`
  color: #fff;
  box-sizing: margin-box;
  padding: 0px 8px;
  overflow: hidden;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;
export const Header = styled.div`
  font-size: 18px;
  color: #fff;
`;
export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  color: #fff;
`;
export const TitleContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;
  color: #fff;
`;
export const DescriptionsContent = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  color: #fff;
`;
export const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  color: #fff;
`;
export const TitleButtonContent = styled.div`
  color: #fff;
`;
export const BodyButtonContent = styled.div`
  flex: 1;
  display: flex;
  color: black;
  align-items: center;
  justify-content: center;

  div {
    height: 200px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    background-color: rgb(255, 138, 0);
    border-radius: 40px;
    font-weight: 120%;
    font-size: 24px;
  }
`;

export const FooterButton = styled.div`
  display: flex;
`;
export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: space-between;
  justify-content: center;
  gap: 8px;
  button {
    margin-inline-start: 0px !important;
  }
`;
