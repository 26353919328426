import styled from "styled-components";

export const Content = styled.div`
  position: relative;
`;
export const MenuItem = styled.div``;

export const NotificationModal = styled.div.attrs(
  (props: { color: string }) => props
)`
  position: fixed;
  /* position:relative; */
  min-width: calc(100vw - 40px);
  width: calc(100vw - 40px);
  max-width: calc(100vw - 40px);
  background-color: #171725;
  z-index: 2000;
  bottom: 3%;
  left: 20px;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 25px;
  box-sizing: border-box;
  border: ${(props) => props.color};
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    /* or 26px */
    color: #ffffff;
  }
  .close-icon-notification-modal {
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
  }
`;
