import { takeEvery } from "redux-saga/effects";
import actions from "../../actions";

import * as parkingWorkers from "./parkingWorkers";
import * as parkingManipulationsWorkers from "./parkingManipulationsWorkers";

export const {
  getParkingsRequest,
  getParkingRequest,
  getParkingStatusRequest,
  getParkingGroupsRequest,
  getParkingsFullStatusesRequest,
  getParkingSpaceStatusRequest,

  setParkingSpaceEngineerModeRequest,
  setParkingSpaceOffModeRequest,
  setParkingSpaceWorkModeRequest,
  testParkingSpaceRequest,
  closeParkingSpaceRequest,
  openParkingSpaceRequest,
  moveStepParkingSpaceRequest,

  getParkingSpaceModbusStatusRequest,
  setParkingSpaceModbusParameterRequest,
} = { ...parkingWorkers, ...parkingManipulationsWorkers };

export default function* authorizeWatcher() {
  const { saga } = actions.parking;
  yield takeEvery(saga.asyncGetParkings.type, getParkingsRequest);
  yield takeEvery(saga.asyncGetParkingDetails.type, getParkingRequest);
  yield takeEvery(saga.asyncGetParkingSpaces.type, getParkingStatusRequest);
  yield takeEvery(saga.asyncGetParkingSpace.type, getParkingSpaceStatusRequest);
  yield takeEvery(saga.asyncGetParkingGroups.type, getParkingGroupsRequest);
  yield takeEvery(
    saga.asyncGetParkingsStatuses.type,
    getParkingsFullStatusesRequest
  );

  yield takeEvery(saga.asyncSetParkingSpaceEngineerMode.type, setParkingSpaceEngineerModeRequest);
  yield takeEvery(saga.asyncSetParkingSpaceOffMode.type, setParkingSpaceOffModeRequest);
  yield takeEvery(saga.asyncSetParkingSpaceWorkMode.type, setParkingSpaceWorkModeRequest);
  yield takeEvery(saga.asyncTestParkingSpace.type, testParkingSpaceRequest);
  yield takeEvery(saga.asyncCloseParkingSpace.type, closeParkingSpaceRequest);
  yield takeEvery(saga.asyncOpenParkingSpace.type, openParkingSpaceRequest);
  yield takeEvery(saga.asyncMoveStepParkingSpace.type, moveStepParkingSpaceRequest);
  yield takeEvery(saga.asyncGetParkingSpaceModbusStatus.type, getParkingSpaceModbusStatusRequest);
  yield takeEvery(saga.asyncSetParkingSpaceModbusParameter.type, setParkingSpaceModbusParameterRequest);
}
