/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import {
  Body,
  BodyButtonContent,
  ButtonContent,
  Content,
  FooterButton,
  Header,
  TitleContent,
} from "./styledComponent";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import selectors from "../../../../../../store/selectors";
import { Customer } from "../../../../../../store/dto/hub.dtos";

const InitialStepCurrentConsumptionPage: React.FC = () => {
  const navigate = useNavigate();
  const { spacesId, spaceId } = useParams();

  React.useEffect(() => {}, []);

  // React.useEffect(() => {
  //   setParkings(parkingData);
  // }, [parkingData]);
  const start = () => {
    navigate(
      `/parkings/${spacesId}/${spaceId}/calibrations-current-consumption/step-1`
    );
  };
  return (
    <Body>
      <Header>
        <span>Подготовка к калибровке</span>
      </Header>
      <Content>
        <TitleContent>
          <span>Добро пожаловать в программу калибровки параметров тока.</span>

          <span>
            Программа включает в себя последовательное прохождение этапов
            калибровки.
          </span>
          {/* <span>
            После нажатия кнопки{" "}
            <span style={{ color: "rgb(255,138,0)" }}>"Начать"</span> программа
            три раза опустит и подымет замок и откалибрует значения тока.
          </span> */}
          <span style={{ color: "rgb(255,138,0)" }}>
            Пожалуйста, следуйте инструкциям на экране!
          </span>

          <span> Нажмите кнопку "Начать", чтоб продолжить.</span>
        </TitleContent>
        {/* <DescriptionsContent>
          <span>
            1. Убедитесь, что парковочное место свободно и на нем никто не
            стоит.
          </span>
        </DescriptionsContent> */}

        <ButtonContent>
          {/* <TitleButtonContent>
            <span>После нажатия кнопки "Начать" замок опустится.</span>
          </TitleButtonContent> */}
          <BodyButtonContent>
            <div onClick={() => start()}>
              <span>Начать</span>
            </div>
          </BodyButtonContent>
        </ButtonContent>

        <FooterButton>
          <Button
            key="back"
            onClick={() =>
              navigate(`/parkings/${spacesId}/${spaceId}/modbus-parameters`)
            }
          >
            Назад
          </Button>
        </FooterButton>
      </Content>
    </Body>
  );
};

export default InitialStepCurrentConsumptionPage;
