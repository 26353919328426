/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import {
  Body,
  Title,
  Content,
  ContentBody,
  TitleContent,
  TitleName,
} from "./styledComponent";
import ListParkingItems from "../../../elements/ListParkingItems";
import { Select, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../../store/actions";
import selectors from "../../../../store/selectors";
import {
  GetEntityGroupsResponse,
  GetParkingsFullStatusesResponse,
  ParkingStatus,
} from "../../../../store/dto/parking.dtos";
import { Customer } from "../../../../store/dto/hub.dtos";
import tokenService from "../../../../service/token.service";

// const { Search } = Input;\
const Option = Select.Option;

// const onSearch = (value: string) => console.log(value);

const ParkingsList: React.FC = () => {
  const dispatch = useDispatch();
  const parkingData: GetParkingsFullStatusesResponse = useSelector(
    selectors.parking.getParkingsStatuses
  );
  const parkingGroupsData: GetEntityGroupsResponse = useSelector(
    selectors.parking.getParkingGroups
  );
  const loading: string[] = useSelector(selectors.app.getCurrentLoading) || [];
  const customer: Customer = useSelector(selectors.auth.getCustomer) || [];

  const [parkings, setParkings] = React.useState<
    GetParkingsFullStatusesResponse
  >(new GetParkingsFullStatusesResponse());
  const [parkingGroups, setParkingGroups] = React.useState<
    GetEntityGroupsResponse
  >(new GetEntityGroupsResponse());
  const [valueSelect, setValueSelect] = React.useState<string>("");

  React.useEffect(() => {
    dispatch(
      actions.parking.saga.asyncGetParkingGroups({
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
      })
    );
  }, []);

  React.useEffect(() => {
    setParkings(parkingData);
  }, [parkingData]);

  React.useEffect(() => {
    setParkingGroups(parkingGroupsData);
  }, [parkingGroupsData]);

  React.useEffect(() => {
    dispatch(
      actions.parking.saga.asyncGetParkingsStatuses({
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
        groupsId: valueSelect,
      })
    );
  }, [valueSelect]);

  const refresh = () => {
    console.log(tokenService.customerId);
    dispatch(
      actions.parking.saga.asyncGetParkingsStatuses({
        // customerId: '12345',
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
        groupsId: valueSelect ? valueSelect : "",
      })
    );
  };

  return (
    <Body>
      <Content>
        <TitleContent></TitleContent>

        <ContentBody>
          {loading.find((item) => item === "loadingParkingsStatuses") ? (
            <Skeleton active loading={true}></Skeleton>
          ) : (
            parkings?.items?.map((item: ParkingStatus) => (
              <ListParkingItems key={item.id} parking={item}></ListParkingItems>
            ))
          )}
        </ContentBody>
      </Content>

      <Title>
        <Select
          placeholder="Выберите группу"
          onChange={(value) => setValueSelect(value)}
        >
          {parkingGroups?.items?.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
        <TitleName>
          <div>
            <h3>Парковки</h3>
          </div>
          <div onClick={() => refresh()}>Обновить</div>
        </TitleName>
      </Title>
    </Body>
  );
};

export default ParkingsList;
