/* eslint-disable import/no-anonymous-default-export */

import IState from "../../interfaces/Store.Interface";

const getRoot = (state: IState) => state.parking;

export default {
  get: (state: IState) => getRoot(state),
  getParkings: (state: IState) => getRoot(state).parking,
  getActiveParking: (state: IState) => getRoot(state).activeParking,
  getActiveParkingDetails: (state: IState) => getRoot(state).activeParkingDetails,
  getParkingSpaces: (state: IState) => getRoot(state).parkingSpaces,
  getParkingGroups: (state: IState) => getRoot(state).parkingGroups,
  getParkingsStatuses: (state: IState) => getRoot(state).parkingsStatuses,
  getActiveParkingSpace: (state: IState) => getRoot(state).activeParkingSpace,

};
