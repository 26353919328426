import { AuthenticateResponse, Customer } from "../../dto/hub.dtos";
import { IAuthState } from "../../interfaces/Auth.Interface";
import { IAction } from "../../utils";

const initialState: IAuthState = {
  auth: new AuthenticateResponse(),
  customer: new Customer(),
};

const reducer = (state = initialState, action: IAction<any>) => {
  const { payload } = action;
  switch (action.type) {
    case "SET_AUTH":
      return { ...state, auth: payload };
    case "SET_CUSTOMER":
      return { ...state, customer: payload };
    default:
      return state;
  }
};
export default reducer;
