
import { call, put, select } from 'redux-saga/effects';
import actions from '../../actions';
import { INotification } from '../../interfaces/App.Interface';
import selectors from '../../selectors';
import { IAction } from '../../utils';
import { v4 as uuid } from 'uuid';

const delay = (ms: number) => new Promise((resolve) => { setTimeout(resolve, ms); });

export function* addNotificationWorker(action: IAction<INotification>) {
	const { payload } = action;
	const currentLoadings: INotification[] = yield select(selectors.app.getNotification);
	yield put(actions.app.setNotification([...currentLoadings, payload]));
}

export function* removeNotificationWorker(action: IAction<string>) {
	const { payload } = action;
	const currentLoadings: INotification[] = yield select(selectors.app.getNotification);
	yield put(actions.app.setNotification(currentLoadings?.filter((value) => value.id !== payload)));
}

export function* initNotificationWorker(action: IAction<{type:string, messages:string}>) {
	const { payload } = action;
	const id = uuid();
	yield call(
		addNotificationWorker,
		actions.app.saga.asyncAddNotification({ id, messages: payload.messages, type: payload.type }),
	);
	yield delay(5000);
	yield call(removeNotificationWorker, actions.app.saga.asyncRemoveNotification(id));
}
