import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuFoldOutlined, LogoutOutlined } from "@ant-design/icons";

import { Button, Drawer } from "antd";
import {
  Header,
  MenuContent,
  MenuContentItem,
  MenuItem,
} from "./styledComponent";
import tokenService from "../../../service/token.service";

const HeaderElement: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const logout = () => {
    setOpen(false);
    navigate("/");
    tokenService.logout();
  };

  return (
    <>
      <Header onClick={showDrawer}>
        <span>Tech Support </span>
        <MenuItem>
          <MenuFoldOutlined height={"40px"} />
          {/* <Button icon={<MenuFoldOutlined size={"large"} />} size={"large"} /> */}
        </MenuItem>
      </Header>

      <Drawer
        title="Меню"
        placement={"top"}
        // closable={false}
        onClose={onClose}
        height={150}
        open={open}
      >
        <MenuContent>
          <MenuContentItem>
            <Button
              style={{ width: "100%" }}
              type="primary"
              icon={<LogoutOutlined />}
              size={"large"}
              onClick={logout}
            >
              Выйти
            </Button>
          </MenuContentItem>
        </MenuContent>
      </Drawer>
    </>
  );
};

export default HeaderElement;
