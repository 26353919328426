import { call, delay, put, race } from "redux-saga/effects";

import errorApiService from "../../../service/error.service";

import actions from "../../actions";
import { IAction } from "../../utils";
import {
  CloseParkingSpaceRequest,
  GetParkingSpaceModbusStatusRequest,
  MoveStepParkingSpaceRequest,
  OpenParkingSpaceRequest,
  ParkingSpaceModbusStatus,
  ParkingSpaceStatus,
  SetParkingSpaceEngineerModeRequest,
  SetParkingSpaceModbusParameterRequest,
  SetParkingSpaceOffModeRequest,
  SetParkingSpaceWorkModeRequest,
  TestParkingSpaceRequest,
} from "../../dto/parking.dtos";
import baseApiService from "../../../service/base-api.service";

const wait = (milliseconds: number) =>
  new Promise((res, rej) =>
    setTimeout(
      () => rej(new Error(`timed out after ${milliseconds} ms`)),
      milliseconds
    )
  );

const setOpenSpaceCall = (opts: {
  customerId: string;
  id: string;
}): Promise<ParkingSpaceStatus> => {
  const { customerId, id } = opts;
  const request = new OpenParkingSpaceRequest();
  request.customerId = customerId;
  request.id = id;
  request.force = true;
  return baseApiService.post(request);
};
const setCloseSpaceCall = (opts: {
  customerId: string;
  id: string;
}): Promise<ParkingSpaceStatus> => {
  const { customerId, id } = opts;
  const request = new CloseParkingSpaceRequest();
  request.customerId = customerId;
  request.id = id;
  request.force = true;
  return baseApiService.post(request);
};
const setMoveStepSpaceCall = (opts: {
  customerId: string;
  id: string;
  open:boolean;
  description:string;
}): Promise<ParkingSpaceStatus> => {
  const { customerId, id, open, description } = opts;
  const request = new MoveStepParkingSpaceRequest();
  request.customerId = customerId;
  request.id = id;
  request.open = open;
  request.description = description;
  return baseApiService.post(request);
};
const setEngineerModeCall = (opts: {
  customerId: string;
  id: string;
}): Promise<ParkingSpaceStatus> => {
  const { customerId, id } = opts;
  const request = new SetParkingSpaceEngineerModeRequest();
  request.customerId = customerId;
  request.id = id;
  return baseApiService.post(request);
};
const setOffModeCall = (opts: {
  customerId: string;
  id: string;
}): Promise<ParkingSpaceStatus> => {
  const { customerId, id } = opts;
  const request = new SetParkingSpaceOffModeRequest();
  request.customerId = customerId;
  request.id = id;
  return baseApiService.post(request);
};
const setWorkModeCall = (opts: {
  customerId: string;
  id: string;
}): Promise<ParkingSpaceStatus> => {
  const { customerId, id } = opts;
  const request = new SetParkingSpaceWorkModeRequest();
  request.customerId = customerId;
  request.id = id;
  return baseApiService.post(request);
};
const getModbusStatusCall = (opts: {
  customerId: string;
  id: string;
}): Promise<ParkingSpaceModbusStatus> => {
  const { customerId, id } = opts;
  const request = new GetParkingSpaceModbusStatusRequest();
  request.customerId = customerId;
  request.id = id;
  return baseApiService.get(request);
};
const setTestModeCall = (opts: {
  customerId: string;
  id: string;
}): Promise<ParkingSpaceStatus> => {
  const { customerId, id } = opts;
  const request = new TestParkingSpaceRequest();
  request.customerId = customerId;
  request.id = id;
  return baseApiService.post(request);
};
const setModbusParameterCall = (opts: {
  address: number;
  value: string;
  description: string;
  customerId: string;
  id: string;
}): Promise<ParkingSpaceModbusStatus> => {
  const { customerId, id, address, value, description } = opts;
  const request = new SetParkingSpaceModbusParameterRequest();
  request.address = address;
  request.value = value;
  request.description = description;
  request.customerId = customerId;
  request.id = id;
  return baseApiService.post(request);
};

export function* setParkingSpaceEngineerModeRequest(
  action: IAction<{
    id: string;
    customerId: string;
    callbackEngineer?: (item: ParkingSpaceStatus) => void;
  }>
) {
  try {
    yield put(actions.app.saga.asyncAddLoading("setParkingSpaceEngineerMode"));
    const {
      payload: { customerId, id, callbackEngineer },
    } = action;
    const result: ParkingSpaceStatus = yield call(setEngineerModeCall, {
      customerId,
      id,
    });
    yield callbackEngineer?.(result);
    yield put(
      actions.app.saga.asyncRemoveLoading("setParkingSpaceEngineerMode")
    );
  } catch (error) {
    yield put(
      actions.app.saga.asyncRemoveLoading("setParkingSpaceEngineerMode")
    );
    yield put(
      actions.app.saga.asyncInitNotification({
        messages: errorApiService.catchRequestError(error),
        type: "warm",
      })
    );
  }
}
export function* setParkingSpaceOffModeRequest(
  action: IAction<{
    id: string;
    customerId: string;
    callbackOff?: (item: ParkingSpaceStatus) => void;
  }>
) {
  try {
    yield put(
      actions.app.saga.asyncAddLoading("setParkingSpaceOffModeRequest")
    );
    const {
      payload: { customerId, id, callbackOff },
    } = action;
    const result: ParkingSpaceStatus = yield call(setOffModeCall, {
      customerId,
      id,
    });
    yield callbackOff?.(result);
    yield put(
      actions.app.saga.asyncRemoveLoading("setParkingSpaceOffModeRequest")
    );
  } catch (error) {
    yield put(
      actions.app.saga.asyncRemoveLoading("setParkingSpaceOffModeRequest")
    );
    yield put(
      actions.app.saga.asyncInitNotification({
        messages: errorApiService.catchRequestError(error),
        type: "warm",
      })
    );
  }
}
export function* setParkingSpaceWorkModeRequest(
  action: IAction<{
    id: string;
    customerId: string;
    callbackWork?: (item: ParkingSpaceStatus) => void;
  }>
) {
  try {
    yield put(
      actions.app.saga.asyncAddLoading("setParkingSpaceWorkModeRequest")
    );
    const {
      payload: { customerId, id, callbackWork },
    } = action;
    const result: ParkingSpaceStatus = yield call(setWorkModeCall, {
      customerId,
      id,
    });
    yield callbackWork?.(result);
    yield put(
      actions.app.saga.asyncRemoveLoading("setParkingSpaceWorkModeRequest")
    );
  } catch (error) {
    yield put(
      actions.app.saga.asyncRemoveLoading("setParkingSpaceWorkModeRequest")
    );
    yield put(
      actions.app.saga.asyncInitNotification({
        messages: errorApiService.catchRequestError(error),
        type: "warm",
      })
    );
  }
}
export function* openParkingSpaceRequest(
  action: IAction<{
    id: string;
    customerId: string;
    callbackOpen?: (item: ParkingSpaceStatus) => void;
  }>
) {
  try {
    yield put(actions.app.saga.asyncAddLoading("openParkingSpaceRequest"));
    const {
      payload: { customerId, id, callbackOpen },
    } = action;

    const { result, timeOut } = yield race({
      result: call(setOpenSpaceCall, {
        customerId,
        id,
      }),
      timeOut: delay(3 * 60 * 1000),
    });

    // const result: ParkingSpaceStatus = yield call(setOpenSpaceCall, {
    //   customerId,
    //   id,
    // });
    if (result) {
      yield callbackOpen?.(result);
      yield put(
        actions.app.saga.asyncInitNotification({
          messages: "Команда выполнена успешно",
          type: "susses",
        })
      );
    } else {
      yield put(
        actions.app.saga.asyncInitNotification({
          messages: "Превышено время ожидания от сервера",
          type: "warm",
        })
      );
    }

    yield put(actions.app.saga.asyncRemoveLoading("openParkingSpaceRequest"));
  } catch (error) {
    yield put(actions.app.saga.asyncRemoveLoading("openParkingSpaceRequest"));
    yield put(
      actions.app.saga.asyncInitNotification({
        messages: errorApiService.catchRequestError(error),
        type: "warm",
      })
    );
  }
}
export function* closeParkingSpaceRequest(
  action: IAction<{
    id: string;
    customerId: string;
    callbackClose?: (item: ParkingSpaceStatus) => void;
  }>
) {
  try {
    yield put(actions.app.saga.asyncAddLoading("closeParkingSpaceRequest"));
    const {
      payload: { customerId, id, callbackClose },
    } = action;

    const { result, timeOut } = yield race({
      result: call(setCloseSpaceCall, {
        customerId,
        id,
      }),
      timeOut: delay(3 * 60 * 1000),
    });

    // const result: ParkingSpaceStatus = yield call(setCloseSpaceCall, {
    //   customerId,
    //   id,
    // });
    if (result) {
      yield callbackClose?.(result);
      yield put(
        actions.app.saga.asyncInitNotification({
          messages: "Команда выполнена успешно",
          type: "susses",
        })
      );
    } else {
      yield put(
        actions.app.saga.asyncInitNotification({
          messages: "Превышено время ожидания от сервера",
          type: "warm",
        })
      );
    }
    yield put(actions.app.saga.asyncRemoveLoading("closeParkingSpaceRequest"));
  } catch (error) {
    yield put(actions.app.saga.asyncRemoveLoading("closeParkingSpaceRequest"));
    yield put(
      actions.app.saga.asyncInitNotification({
        messages: errorApiService.catchRequestError(error),
        type: "warm",
      })
    );
  }
}
export function* moveStepParkingSpaceRequest(
  action: IAction<{
    id: string;
    customerId: string;
    open:boolean;
    description:string;
    callbackMoveStep?: (item: ParkingSpaceStatus) => void;
  }>
) {
  try {
    yield put(actions.app.saga.asyncAddLoading("moveStepParkingSpaceRequest"));
    const {
      payload: { customerId, id, open, description, callbackMoveStep },
    } = action;

    const { result, timeOut } = yield race({
      result: call(setMoveStepSpaceCall, {
        customerId,
        id,
        open,
        description,
      }),
      timeOut: delay(3 * 60 * 1000),
    });

    if (result) {
      yield callbackMoveStep?.(result);
      yield put(
        actions.app.saga.asyncInitNotification({
          messages: "Команда выполнена успешно",
          type: "susses",
        })
      );
    } else {
      yield put(
        actions.app.saga.asyncInitNotification({
          messages: "Превышено время ожидания от сервера",
          type: "warm",
        })
      );
    }
    yield put(actions.app.saga.asyncRemoveLoading("moveStepParkingSpaceRequest"));
  } catch (error) {
    yield put(actions.app.saga.asyncRemoveLoading("moveStepParkingSpaceRequest"));
    yield put(
      actions.app.saga.asyncInitNotification({
        messages: errorApiService.catchRequestError(error),
        type: "warm",
      })
    );
  }
}
export function* getParkingSpaceModbusStatusRequest(
  action: IAction<{
    id: string;
    customerId: string;
    callbackModbusStatus?: (item: ParkingSpaceModbusStatus) => void;
  }>
) {
  try {
    yield put(
      actions.app.saga.asyncAddLoading("getParkingSpaceModbusStatusRequest")
    );
    const {
      payload: { customerId, id, callbackModbusStatus },
    } = action;
    const result: ParkingSpaceModbusStatus = yield call(getModbusStatusCall, {
      customerId,
      id,
    });
    yield callbackModbusStatus?.(result);
    yield put(
      actions.app.saga.asyncRemoveLoading("getParkingSpaceModbusStatusRequest")
    );
  } catch (error) {
    yield put(
      actions.app.saga.asyncRemoveLoading("getParkingSpaceModbusStatusRequest")
    );
    yield put(
      actions.app.saga.asyncInitNotification({
        messages: errorApiService.catchRequestError(error),
        type: "warm",
      })
    );
  }
}
export function* testParkingSpaceRequest(
  action: IAction<{
    id: string;
    customerId: string;
    callback?: (item: ParkingSpaceStatus) => void;
  }>
) {
  try {
    yield put(actions.app.saga.asyncAddLoading("testParkingSpaceRequest"));
    const {
      payload: { customerId, id, callback },
    } = action;
    const result: ParkingSpaceStatus = yield call(setTestModeCall, {
      customerId,
      id,
    });
    yield callback?.(result);
    yield put(actions.app.saga.asyncRemoveLoading("testParkingSpaceRequest"));
  } catch (error) {
    yield put(actions.app.saga.asyncRemoveLoading("testParkingSpaceRequest"));
    yield put(
      actions.app.saga.asyncInitNotification({
        messages: errorApiService.catchRequestError(error),
        type: "warm",
      })
    );
  }
}
export function* setParkingSpaceModbusParameterRequest(
  action: IAction<{
    id: string;
    customerId: string;
    address: number;
    value: string;
    description: string;
    callbackEditModbusStatus?: (item: ParkingSpaceModbusStatus) => void;
  }>
) {
  try {
    yield put(
      actions.app.saga.asyncAddLoading("setParkingSpaceModbusParameterRequest")
    );
    const {
      payload: {
        customerId,
        id,
        address,
        value,
        description,
        callbackEditModbusStatus,
      },
    } = action;
    const result: ParkingSpaceModbusStatus = yield call(
      setModbusParameterCall,
      {
        customerId,
        id,
        address,
        value,
        description,
      }
    );
    yield callbackEditModbusStatus?.(result);
    yield put(
      actions.app.saga.asyncRemoveLoading(
        "setParkingSpaceModbusParameterRequest"
      )
    );
  } catch (error) {
    yield put(
      actions.app.saga.asyncRemoveLoading(
        "setParkingSpaceModbusParameterRequest"
      )
    );
    yield put(
      actions.app.saga.asyncInitNotification({
        messages: errorApiService.catchRequestError(error),
        type: "warm",
      })
    );
  }
}
