import styled from "styled-components";

export const Body = styled.div`
  color: #fff;
  box-sizing: border-box;
  padding: 0px 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 40px);
`;
export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* justify-content: space-around; */
  /* height: 120px; */
  width: 100%;
  margin-top: auto;
  padding-top: 12px;
`;
export const TitleName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* max-height: calc(100vh - 160px); */
  overflow: auto;
`;
export const TitleContent = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
export const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-top: 12px;
  box-sizing: border-box;
`;
