/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from "react";

import {
  ModbusParameter,
  Parking,
  ParkingSpaceModbusStatus,
  ParkingSpaceMode,
  ParkingSpaceStatus,
} from "../../../../../store/dto/parking.dtos";
import { useDispatch, useSelector } from "react-redux";
import selectors from "../../../../../store/selectors";
import { Button, Spin, message, Modal, Form, Input, InputNumber } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import actions from "../../../../../store/actions";
import ListParkingSpaceItem from "../../../../elements/ListParkingSpaceItem";
import { Customer } from "../../../../../store/dto/hub.dtos";
import tokenService from "../../../../../service/token.service";
import { v4 as uuid } from "uuid";

import {
  Body,
  BodyEditParameter,
  BodyModBusParameters,
  Content,
  ContentManipulationMode,
  ContentModal,
  ContentModBusParameters,
  FooterEditParameter,
  FormEditParameter,
  Loading,
  ManipulationMode,
  ParametersModbusBlock,
  Title,
  TitleContent,
  TitleManipulationMode,
  TitleModBusParameters,
  TitleParametersModbusBlock,
} from "./styledComponent";
import ParameterModbusItem from "../../../../elements/ParameterModBus";
import { ModalFooter } from "../../Calibrations/UltrasonicSensor/initial/styledComponent";
interface IParametersData {
  minValue: number;
  maxValue: number;
  address: number;
}

const ParametersData: IParametersData[] = [
  { minValue: 1, maxValue: 60, address: 20 },
  { minValue: 1, maxValue: 60, address: 21 },
];

const ModBusParameters: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { spacesId, spaceId } = useParams();
  const [space, setSpace] = React.useState<ParkingSpaceStatus>(
    {} as ParkingSpaceStatus
  );

  const [parkingDetails, setParkingDetails] = React.useState<Parking>();
  const parkingDetailsActive: Parking = useSelector(
    selectors.parking.getActiveParkingDetails
  );

  const [modbusStatus, setModbusStatus] = React.useState<
    ParkingSpaceModbusStatus
  >({} as ParkingSpaceModbusStatus);

  const [open, setOpen] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [activeParameter, setActiveParameter] = React.useState<
    ModbusParameter
  >();
  const [activeParameterDetails, setActiveParameterDetails] = React.useState<
    IParametersData
  >();

  const loading: string[] = useSelector(selectors.app.getCurrentLoading) || [];
  const customer: Customer = useSelector(selectors.auth.getCustomer) || [];

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const refresh = () => {
    const callbackModbusStatus = (value: ParkingSpaceModbusStatus) =>
      setModbusStatus(value);
    dispatch(
      actions.parking.saga.asyncGetParkingSpaceModbusStatus({
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
        id: spaceId as string,
        callbackModbusStatus,
      })
    );

    const callback = (value: ParkingSpaceStatus) => setSpace(value);
    dispatch(
      actions.parking.saga.asyncGetParkingSpace({
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
        id: spaceId as string,
        callback,
      })
    );

    dispatch(
      actions.parking.saga.asyncGetParkingDetails({
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
        id: spacesId as string,
      })
    );
  };

  const showDrawer = (address: number) => {
    const tempActiveParameter: ModbusParameter =
      modbusStatus.parameters.find((item) => item.address === address) ||
      ({} as ModbusParameter);
    const tempActiveParameterDetails: IParametersData =
      ParametersData.find((item) => item.address === address) ||
      ({} as IParametersData);
    setActiveParameter(tempActiveParameter);
    setActiveParameterDetails(tempActiveParameterDetails);
    setOpen(true);
    form.setFieldValue(
      "currentValues",
      (tempActiveParameter.value / 1000) as number
    );
  };

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    console.log("handleCancel");
    setActiveParameter({} as ModbusParameter);
    setActiveParameterDetails({} as IParametersData);
    setOpen(false);
  };

  const onFinish = (values: any) => {
    const version: ModbusParameter =
      modbusStatus.parameters.find((item) => item.type === "VERSION") ||
      new ModbusParameter();

    let temp = values.currentValues;

    if (activeParameterDetails?.address === 20) {
      if (parseFloat(version.stringValue) < 12.0) {
        temp = temp * 1000;
        console.log(temp);
      }
    } else {
      console.log(temp);
      temp = temp * 1000;
    }
    const callbackEditModbusStatus = (value: ParkingSpaceModbusStatus) => {
      setModbusStatus(value);
      setActiveParameter({} as ModbusParameter);
      setActiveParameterDetails({} as IParametersData);
      setOpen(false);
    };

    dispatch(
      actions.parking.saga.asyncSetParkingSpaceModbusParameter({
        customerId: customer.id
          ? customer.id
          : (tokenService.customerId as string),
        id: spaceId as string,
        address: activeParameterDetails?.address as number,
        description: "",
        value: temp.toString(),
        callbackEditModbusStatus,
      })
    );
  };

  const timeOnEngineering = (address: number) => {
    if (space.mode === ParkingSpaceMode.Engineer) {
      showDrawer(address);
    } else {
      setIsModalOpen(true);
    }
  };
  const ultrasonicTestOnEngineering = () => {
    if (space.mode === ParkingSpaceMode.Engineer) {
      navigate(
        `/parkings/${spacesId}/${spaceId}/calibrations-ultrasonic-sensor/initial`
      );
    } else {
      setIsModalOpen(true);
    }
  };
  const currentTestOnEngineering = () => {
    if (space.mode === ParkingSpaceMode.Engineer) {
      navigate(
        `/parkings/${spacesId}/${spaceId}/calibrations-current-consumption/initial`
      );
    } else {
      setIsModalOpen(true);
    }
  };

  const setEngineerMode = () => {
    const callbackEngineer = (value: ParkingSpaceStatus) => {
      setSpace(value);
      setIsModalOpen(false);
    };
    dispatch(
      actions.parking.saga.asyncSetParkingSpaceEngineerMode({
        customerId: "",
        id: space.id,
        callbackEngineer,
      })
    );
  };

  const getDistance = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ): number => {
    const R = 6371e3; // metres
    const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return Math.round(R * c);
  };

  const initialCheckModeUltrasonicButton = (mode: ParkingSpaceMode) => {
    dispatch(actions.app.saga.asyncAddLoading("getGeolocation"));
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (location) => {
          if (location)
            if (
              getDistance(
                parkingDetails?.lat as number,
                parkingDetails?.lon as number,
                location.coords.latitude,
                location.coords.longitude
              ) <= 300
            ) {
              // setLatLng({
              //   lat: location.coords.latitude,
              //   lon: location.coords.longitude,
              // });
              switch (mode) {
                case ParkingSpaceMode.Engineer:
                  // setEngineerMode();
                  ultrasonicTestOnEngineering();
                  break;
                default:
                  alert("Нет таких значений");
                  break;
              }
            } else {
              messageApi.open({
                type: "error",
                content: "Вы находитесь далеко",
              });
            }
          dispatch(actions.app.saga.asyncRemoveLoading("getGeolocation"));
        },
        (error) => {
          console.log(error.PERMISSION_DENIED);
          switch (error.code) {
            case error.PERMISSION_DENIED:
              messageApi.open({
                type: "error",
                content: "Разрешите определение вашего местоположения!",
              });
              break;
            case error.POSITION_UNAVAILABLE:
              messageApi.open({
                type: "error",
                content: "Информация о местоположении недоступна.",
              });
              break;
            case error.TIMEOUT:
              messageApi.open({
                type: "error",
                content:
                  "Время ожидания запроса на получение местоположения пользователя истекло.",
              });
              break;
            default:
              messageApi.open({
                type: "error",
                content: "Произошла неизвестная ошибка.",
              });
              break;
          }
          dispatch(actions.app.saga.asyncRemoveLoading("getGeolocation"));
        }
      );
    }
  };
  const initialCheckModeCurrentButton = (mode: ParkingSpaceMode) => {
    dispatch(actions.app.saga.asyncAddLoading("getGeolocation"));
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (location) => {
          if (location)
            if (
              getDistance(
                parkingDetails?.lat as number,
                parkingDetails?.lon as number,
                location.coords.latitude,
                location.coords.longitude
              ) <= 300
            ) {
              // setLatLng({
              //   lat: location.coords.latitude,
              //   lon: location.coords.longitude,
              // });
              switch (mode) {
                case ParkingSpaceMode.Engineer:
                  // setEngineerMode();
                  currentTestOnEngineering();
                  break;
                default:
                  alert("Нет таких значений");
                  break;
              }
            } else {
              messageApi.open({
                type: "error",
                content: "Вы находитесь далеко",
              });
            }
          dispatch(actions.app.saga.asyncRemoveLoading("getGeolocation"));
        },
        (error) => {
          console.log(error.PERMISSION_DENIED);
          switch (error.code) {
            case error.PERMISSION_DENIED:
              messageApi.open({
                type: "error",
                content: "Разрешите определение вашего местоположения!",
              });
              break;
            case error.POSITION_UNAVAILABLE:
              messageApi.open({
                type: "error",
                content: "Информация о местоположении недоступна.",
              });
              break;
            case error.TIMEOUT:
              messageApi.open({
                type: "error",
                content:
                  "Время ожидания запроса на получение местоположения пользователя истекло.",
              });
              break;
            default:
              messageApi.open({
                type: "error",
                content: "Произошла неизвестная ошибка.",
              });
              break;
          }
          dispatch(actions.app.saga.asyncRemoveLoading("getGeolocation"));
        }
      );
    }
  };

  const initialCheckManipulationButton = (type: string) => {
    dispatch(actions.app.saga.asyncAddLoading("getGeolocation"));
    if (navigator?.geolocation) {
      console.log();
      navigator.geolocation.getCurrentPosition(
        (location) => {
          console.log(location.coords.latitude);
          if (location)
            if (
              getDistance(
                parkingDetails?.lat as number,
                parkingDetails?.lon as number,
                location.coords.latitude,
                location.coords.longitude
              ) <= 300
            ) {
              // setLatLng({
              //   lat: location.coords.latitude,
              //   lon: location.coords.longitude,
              // });
              switch (type) {
                case "20":
                  timeOnEngineering(20);
                  break;
                case "21":
                  timeOnEngineering(21);
                  break;
                default:
                  alert("Нет таких значений");
                  break;
              }
            } else {
              messageApi.open({
                type: "error",
                content: "Вы находитесь далеко",
              });
            }
          dispatch(actions.app.saga.asyncRemoveLoading("getGeolocation"));
        },
        (error) => {
          console.log(error.PERMISSION_DENIED);
          switch (error.code) {
            case error.PERMISSION_DENIED:
              messageApi.open({
                type: "error",
                content: "Разрешите определение вашего местоположения!",
              });
              break;
            case error.POSITION_UNAVAILABLE:
              messageApi.open({
                type: "error",
                content: "Информация о местоположении недоступна.",
              });
              break;
            case error.TIMEOUT:
              messageApi.open({
                type: "error",
                content:
                  "Время ожидания запроса на получение местоположения пользователя истекло.",
              });
              break;
            default:
              messageApi.open({
                type: "error",
                content: "Произошла неизвестная ошибка.",
              });
              break;
          }
          dispatch(actions.app.saga.asyncRemoveLoading("getGeolocation"));
        }
      );
    }
  };

  const onFinishFailed = (errorInfo: any) => {};

  React.useEffect(() => {
    refresh();
  }, []);

  React.useEffect(() => {
    setParkingDetails(parkingDetailsActive);
  }, [parkingDetailsActive]);

  return (
    <>
      {contextHolder}
      <Body>
        <TitleContent>
          <ListParkingSpaceItem space={space}></ListParkingSpaceItem>
        </TitleContent>
        <Content>
          <ContentModal>
            <ManipulationMode>
              <TitleManipulationMode>
                <span>Установка параметров</span>
              </TitleManipulationMode>
              <ContentManipulationMode>
                <Button
                  style={{ width: "100%", height: "50px" }}
                  type="primary"
                  // onClick={() => currentTestOnEngineering()}
                  onClick={() =>
                    initialCheckModeCurrentButton(ParkingSpaceMode.Engineer)
                  }
                >
                  Калибровка потребления тока
                </Button>
                <Button
                  style={{ width: "100%", height: "50px" }}
                  type="primary"
                  onClick={() =>
                    initialCheckModeUltrasonicButton(ParkingSpaceMode.Engineer)
                  }
                  // onClick={() => ultrasonicTestOnEngineering()}
                >
                  Калибровка ультразвуковых датчиков
                </Button>
                <Button
                  style={{ width: "100%", height: "50px" }}
                  type="primary"
                  onClick={() => initialCheckManipulationButton("20")}
                >
                  Время фиксации присутствие автомобиля
                </Button>
                <Button
                  style={{ width: "100%", height: "50px" }}
                  type="primary"
                  onClick={() => initialCheckManipulationButton("21")}
                >
                  Время фиксации отсутствие автомобиля
                </Button>
              </ContentManipulationMode>
            </ManipulationMode>

            <BodyModBusParameters>
              <TitleModBusParameters>
                <span> Параметры</span>
              </TitleModBusParameters>

              <ContentModBusParameters>
                <ParametersModbusBlock>
                  <TitleParametersModbusBlock>Общее</TitleParametersModbusBlock>
                  {modbusStatus?.parameters?.map((item: ModbusParameter) => {
                    return item.address === 3 || item.address === 79 ? (
                      <ParameterModbusItem
                        key={uuid()}
                        modbusParameter={item}
                      ></ParameterModbusItem>
                    ) : (
                      <></>
                    );
                  })}
                </ParametersModbusBlock>
                <ParametersModbusBlock>
                  <TitleParametersModbusBlock>
                    Мотор токи
                  </TitleParametersModbusBlock>
                  {modbusStatus?.parameters?.map((item: ModbusParameter) => {
                    return item.address === 15 ||
                      item.address === 16 ||
                      item.address === 77 ||
                      item.address === 76 ||
                      item.address === 24 ? (
                      <ParameterModbusItem
                        key={uuid()}
                        modbusParameter={item}
                      ></ParameterModbusItem>
                    ) : (
                      <></>
                    );
                  })}
                </ParametersModbusBlock>
                <ParametersModbusBlock>
                  <TitleParametersModbusBlock>
                    Ультразвук
                  </TitleParametersModbusBlock>
                  {modbusStatus?.parameters?.map((item: ModbusParameter) => {
                    return item.address === 9 || item.address === 10 ? (
                      <ParameterModbusItem
                        key={uuid()}
                        modbusParameter={item}
                      ></ParameterModbusItem>
                    ) : (
                      <></>
                    );
                  })}
                </ParametersModbusBlock>
                <ParametersModbusBlock>
                  <TitleParametersModbusBlock>Время</TitleParametersModbusBlock>
                  {modbusStatus?.parameters?.map((item: ModbusParameter) => {
                    return item.address === 20 || item.address === 21 ? (
                      <ParameterModbusItem
                        key={uuid()}
                        modbusParameter={item}
                        version={
                          modbusStatus.parameters.find(
                            (item) => item.type === "VERSION"
                          )?.stringValue
                        }
                      ></ParameterModbusItem>
                    ) : (
                      <></>
                    );
                  })}
                </ParametersModbusBlock>
              </ContentModBusParameters>
            </BodyModBusParameters>
          </ContentModal>
        </Content>
        <Title>
          <div onClick={() => navigate(`/parkings/${spacesId}/${spaceId}`)}>
            <LeftOutlined />
            <span>Назад</span>
          </div>
          <div onClick={() => refresh()}>Обновить</div>
        </Title>
        {(loading.find((item) => item === "loadingParkingStatusSpace") ||
          loading.find((item) => item === "loadingParkingDetails") ||
          loading.find((item) => item === "setParkingSpaceEngineerMode") ||
          loading.find(
            (item) => item === "setParkingSpaceModbusParameterRequest"
          ) ||
          loading.find(
            (item) => item === "getParkingSpaceModbusStatusRequest"
          )) && (
          <Loading>
            <Spin></Spin>
          </Loading>
        )}

        <Modal
          title={
            activeParameter?.address === 21
              ? "Время постоянного отсутствия автомобиля для фиксации события, в c"
              : "Время постоянного присутствия автомобиля для фиксации события, в с"
          }
          closable={false}
          open={open}
          //   onOk={handleOk}
          onCancel={handleCancel}
          okText="Изменить"
          okButtonProps={{ htmlType: "submit", form: "myForm" }}
        >
          <BodyEditParameter>
            <span>
              Введите значение в диапазоне от {activeParameterDetails?.minValue}{" "}
              до {activeParameterDetails?.maxValue} секунд
            </span>
            <FormEditParameter>
              <Form
                id="myForm"
                layout="vertical"
                form={form}
                onFinish={onFinish}
                requiredMark={false}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  label="Значение, секунды"
                  name="currentValues"
                  rules={[
                    { required: true, message: "Укажите значение" },
                    {
                      pattern: new RegExp(/^[0-9]*$/),
                      message: "Только целые значения",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    max={activeParameterDetails?.maxValue}
                    min={activeParameterDetails?.minValue}
                    autoComplete="false"
                    placeholder="Укажите значение"
                  />
                </Form.Item>
              </Form>
            </FormEditParameter>
            <FooterEditParameter></FooterEditParameter>
          </BodyEditParameter>
        </Modal>

        <Modal
          title="Внимание!"
          open={isModalOpen}
          centered
          footer={[
            <ModalFooter>
              <Button key="back" onClick={() => setIsModalOpen(false)}>
                Отмена
              </Button>
              <Button
                type="primary"
                onClick={() => setEngineerMode()}
                // onClick={() =>
                //   initialCheckModeButton(ParkingSpaceMode.Engineer)
                // }
              >
                Перевести в инженерный
              </Button>
            </ModalFooter>,
          ]}
        >
          <p>Происходить калибровку необходимо из инженерного режима.</p>
        </Modal>
      </Body>
    </>
  );
};

export default ModBusParameters;
