import styled from "styled-components";

export const Body = styled.div`
  /* width: 100%; */
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  gap: 14px;
  background-color: #6363634f;
  border-radius: 8px;
  padding: 8px;
  cursor:pointer;
`;

export const Content = styled.div`
  width: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const Title = styled.div``;
export const Description = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  gap: 4px;
  color: #ffffff9c;
`;
