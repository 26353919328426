import {
  GetEntityGroupsResponse,
  GetParkingsFullStatusesResponse,
  Parking,
  ParkingSpaceStatus,
  ParkingStatus,
} from "../../dto/parking.dtos";
import { IParkingState } from "../../interfaces/Parking.Interface";
import { IAction } from "../../utils";

const initialState: IParkingState = {
  parking: [],
  activeParking: new ParkingStatus(),
  activeParkingDetails: new Parking(),
  parkingSpaces: new ParkingStatus(),
  parkingGroups: new GetEntityGroupsResponse(),
  parkingsStatuses: new GetParkingsFullStatusesResponse(),
  activeParkingSpace: new ParkingSpaceStatus(),
};

const reducer = (state = initialState, action: IAction<any>) => {
  const { payload } = action;
  switch (action.type) {
    case "SET_PARKINGS":
      return { ...state, parking: payload };
    case "SET_ACTIVE_PARKING":
      return { ...state, activeParking: payload };
    case "SET_ACTIVE_PARKING_DETAILS":
      return { ...state, activeParkingDetails: payload };
    case "SET_ACTIVE_PARKING_SPACES":
      return { ...state, parkingSpaces: payload };
    case "SET_PARKING_GROUPS":
      return { ...state, parkingGroups: payload };
    case "SET_PARKINGS_STATUSES":
      return { ...state, parkingsStatuses: payload };
    case "SET_ACTIVE_PARKING_SPACE":
      return { ...state, activeParkingSpace: payload };
    default:
      return state;
  }
};
export default reducer;
