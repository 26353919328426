import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom/client";
import ruRu from "antd/locale/ru_RU";
import * as serviceWorkerRegistration from "./common/serviceWorkerRegistration";
import reportWebVitals from "./common/reportWebVitals";
import LoginPage from "./components/Pages/Login";
import { GlobalStyles } from "./common/GlobalStyle";
import { ConfigProvider } from "antd";
import MainPage from "./components/Pages/Parkings/ParkingsList";
import ParkingSpacesList from "./components/Pages/Parkings/ParkingSpacesList";
import rootStore from "./store/store";
import { Provider } from "react-redux";
import tokenService from "./service/token.service";
import ParkingPage from "./components/Pages/Parkings";
import App from "./App";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Provider store={rootStore}>
      <ConfigProvider
        locale={ruRu}
        theme={{
          token: {
            colorPrimary: "#ff8a00",
            colorBgContainer: "#ffffff00",
            colorText: "#fff",
            colorTextSecondary: "#fff",
            colorTextPlaceholder: "#818181",
            colorBgElevated: "#2e2e2e",
            colorBgLayout: "red",
            controlItemBgActive: "#818181",
            colorIcon: "rgb(255, 255, 255)",
            colorFillContent: "rgb(255, 255, 255)",
            colorFill: "rgb(255, 255, 255)",
            colorFillContentHover: "rgb(255, 255, 255)",
            colorFillAlter: "rgb(255, 255, 255)",

            // color: '#fff',
          },
          components: {
            Select: {
              colorBgLayout: "red",
            },
            Modal: {
              colorBgElevated: "#1d2335",
              // button:0,
            },
            List: {
              borderRadius: 14,
              colorText: "#fff",
              colorTextDescription: "#ffffff9c",
            },
            Result: {
              colorTextDescription: "#fff",
            },
            Collapse: {
              padding: 0,
            },
            Button: {
              colorTextDisabled: "gray",
              controlItemBgActiveDisabled: "gray",
            },
          },
        }}
      >
        <GlobalStyles />

        {/* <BrowserRouter>
          <Routes>
            <Route path="" element={<LoginPage />} />

            <Route path="parkings/*" element={<ParkingPage />}>
            </Route>
          </Routes>
        </BrowserRouter> */}
        {/* <BrowserRouter>
          <App></App>
        </BrowserRouter> */}
        <App></App>
        {/* <RouterProvider router={router} /> */}
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
);

// ReactDOM.render(
//   // <BrowserRouter>
//   <App />,
//   // </BrowserRouter>
//   document.getElementById("root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
