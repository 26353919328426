import IAppState from "../../interfaces/App.Interface";
import { IAction } from "../../utils";


export const initialState: IAppState = {
	globalLoading: undefined,
	currentLoadings: [],
	notification: [],

};
const reducer = (state = initialState, action: IAction<any>): IAppState => {
	const { payload } = action;

	switch (action.type) {
	case 'GLOBAL_LOADING':
		return { ...state, globalLoading: payload };
	case 'SET_CURRENT_LOADINGS':
		return { ...state, currentLoadings: payload };
	case 'SET_NOTIFICATION':
		return { ...state, notification: payload };
	default:
		return state;
	}
};

export default reducer;
