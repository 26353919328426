import { takeEvery } from "redux-saga/effects";
import actions from "../../actions";

import * as authWorkers from "./authWorkers";

export const { login, getCurrentCustomerRequest } = { ...authWorkers };

export default function* authorizeWatcher() {
  const { saga } = actions.auth;
  yield takeEvery(saga.asyncLogIn.type, login);
  yield takeEvery(saga.asyncGetCustomer.type, getCurrentCustomerRequest);
}
