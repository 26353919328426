import { takeEvery } from 'redux-saga/effects';
import actions from '../../actions';

import * as loadingWorkers from './loadingWorkers';

import * as notificationWorkers from './notificationWorkers';

export const {
	addLoadingWorker, removeLoadingWorker, addNotificationWorker, removeNotificationWorker, initNotificationWorker,
} = { ...loadingWorkers, ...notificationWorkers };

export default function* appWatcher() {
	const { saga } = actions.app;
	yield takeEvery(saga.asyncAddLoading.type, addLoadingWorker);
	yield takeEvery(saga.asyncRemoveLoading.type, removeLoadingWorker);

	yield takeEvery(saga.asyncAddNotification.type, addNotificationWorker);
	yield takeEvery(saga.asyncInitNotification.type, initNotificationWorker);
	yield takeEvery(saga.asyncRemoveNotification.type, removeNotificationWorker);
}
